import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { AppUser } from "../../@types/app_user"
import { Collection } from "../../@types/collection"
import { Property } from "../../@types/property";
import { ToMoney } from "../../utils/generic";
import { GetPropertyAddress } from "../properties/propertyUtils";

export interface ICollectionHeaderProps {
    collection: Collection,
    currentUser?: AppUser,
    property: Property,
}

export default function CollectionSubHeader({
    collection,
    property,
}: ICollectionHeaderProps) {
    return (
        <div>
            <div>
                <div style={{
                    display: 'inline-block',
                    width: '85px',
                }}>
                    <UnlockStatusIcon collection={collection} />
                </div>
                <div style={{
                    display: 'inline-block',
                }}>
                    <Typography variant="h1" sx={{
                        fontWeight: 'lighter',
                        fontSize: '2rem',
                    }}>
                        {collection.name}
                    </Typography>

                    <Typography variant="subtitle1" sx={{
                        fontWeight: 'lighter',
                        fontSize: '1rem',
                        textTransform: 'capitalize',
                    }}>
                        {GetPropertyAddress(property)}
                    </Typography>
                </div>
            </div>

            <Typography variant="body2" sx={{
                fontWeight: 'lighter',
                mt: 4,
            }}>
                {collection.description || (<i>No description provided.</i>)}
            </Typography>

            <Divider sx={{ my: 4 }} />

            <Grid container spacing={3}>
                <FieldDisplay
                    label="Unlock Price"
                    value={ToMoney(collection.unlock_fee)}
                />

                <FieldDisplay
                    label="Documents"
                    value={collection.collection_attachments_count?.toString()}
                />

                {collection.unlocked_by_user_id > 0 && (
                    <>
                        <FieldDisplay
                            label="Member Price"
                            value={ToMoney(collection.unlock_fee)}
                        />
                        <FieldDisplay
                            label="Unlocked At"
                            value={new Date(String(collection.unlocked_at))?.toDateString()}
                        />
                    </>
                )}

                <FieldDisplay
                    label="Created"
                    value={new Date(String(collection.created_at))?.toDateString()}
                />

            </Grid>

            <Divider sx={{ my: 4 }} />            
        </div>
    );
}


const FieldDisplay = ({ value, label }: {
    label: string,
    value: string,
}) => {
    return (
        <Grid item xs={6} sm={6} md={4} lg={3}>
            <Typography variant="overline" sx={{
                fontWeight: 'lighter',
            }}>
                {label || (<i>-</i>)}
            </Typography>
            <Typography variant="body2" sx={{
                fontWeight: 'lighter',
            }}>
                {value || (<i>-</i>)}
            </Typography>
        </Grid>
    );
}


const UnlockStatusIcon = ({ collection }: { collection: Collection }) => {
    if (collection.unlocked_by_user_id) {
        return (
            <Avatar
                sx={{
                    backgroundColor: green[400],
                    height: 65,
                    width: 65,
                    border: green[900],
                }}
            >
                <LockOpenOutlined sx={{
                    color: 'white',
                    fontSize: 48,
                }}
                />
            </Avatar>
        )
    }

    return (
        <Avatar
            sx={{
                backgroundColor: '#CECECE',
                height: 65,
                width: 65,
            }}
        >
            <LockOutlined sx={{
                color: 'white',
                fontSize: 48,
            }}
            />
        </Avatar>
    )
}