import { Box, Typography } from "@mui/material";

export default function StepHeader({ title, description }: { title: string, description: string }) {
    return (
        <Box sx={{
            marginBottom: 6,
        }}>
            <Typography
                variant="subtitle1"
                fontWeight="lighter"
                fontSize="30px"
                gutterBottom={false}
            >
                {title}
            </Typography>
            <Typography
                variant="caption"
                fontWeight="lighter"
            >
                {description}
            </Typography>
        </Box>
    )
}