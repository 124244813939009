import { CalendarMonth, CloudUpload, FileOpen, LockOpen } from '@mui/icons-material';
import { Badge, Chip } from '@mui/material';
import { PROPERTY_DEFAULT_COVER_IMAGE } from '../../components/properties/propertyConstants';
import { Box } from '@mui/system';
import ParticlesBackground from '../../views/layouts/ParticlesBackground';
import AttachmentCreateCoverImageForEntityForm from '../attachments/AttachmentCreateCoverImageForEntityForm';
import { EntityType } from '../../@types/generic';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/root';
import { selectAttachmentById } from '../attachments/attachmentsSlice';
import { selectCurrentUser } from '../global/globalsSlice';
import { selectPropertyById } from './propertiesSlice';

interface IPropertyDisplayHeaderProps {
    propertyId: number;
}

export default function PropertyDisplayHeader({
    propertyId,
}: IPropertyDisplayHeaderProps) {
    const currentUser = useSelector(selectCurrentUser);
    const property = useSelector((store: RootState) => selectPropertyById(store, propertyId));
    const coverImage = useSelector((store: RootState) => selectAttachmentById(store, property?.cover_attachment_id));
    const [uploadCoverImageOpen, setUploadCoverImageOpen] = useState(false);

    return (
        <div style={{
            position: 'relative',
        }}>
            <Box style={{
                textAlign: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'red',
                borderBottom: '1px solid #AAA',
            }}>
                <ParticlesBackground id="view-property-header" />
            </Box>

            <Box
                sx={{
                    minHeight: 250,
                    position: 'relative',
                    maxWidth: 800,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderRadius: 1,
                    boxShadow: '0 2px 4px rgba(0,0,0,1)',
                    top: 10,
                    right: 0,
                    bottom: -12,
                    left: 0,
                    overflow: 'hidden',
                }}
            >
                <Box sx={{
                    backgroundImage: `url('${coverImage?.url || PROPERTY_DEFAULT_COVER_IMAGE}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    borderColor: 'rgba(0,0,0,0.1)',
                    borderTop: 'none',
                }}
                />
                <div style={{
                    position: 'absolute',
                    left: 12,
                    bottom: 12,
                    right: 12,
                    overflowX: 'scroll',
                }}>
                    <Chip
                        icon={<CloudUpload />}
                        label={
                            <>
                                Change Image
                            </>
                        }
                        onClick={() => setUploadCoverImageOpen(true)}
                        color="primary"
                        variant="outlined"
                        sx={{
                            borderColor: 'white',
                            color: 'white',
                            marginRight: 1,
                            ...(currentUser?.id !== property?.created_by ?
                                { display: 'none' } : {}
                            ),
                        }}
                    />

                    <Chip
                        icon={<CalendarMonth />}
                        label={
                            <>
                                Collections <Badge

                                >{property?.collections_count}</Badge>
                            </>
                        }
                        color="primary"
                        variant="outlined"
                        sx={{
                            borderColor: 'white',
                            color: 'white',
                        }}
                    />

                    <Chip
                        icon={<LockOpen />}
                        label={
                            <>
                                Unlocked <Badge
                                >{property?.unlocked_collections_count}</Badge>
                            </>
                        }
                        color="primary"
                        variant="outlined"
                        sx={{
                            borderColor: 'white',
                            color: 'white',
                            marginLeft: 1,
                        }}
                    />

                    <Chip
                        icon={<FileOpen />}
                        label={
                            <>
                                Free <Badge
                                >{property?.free_collections_count}</Badge>
                            </>
                        }
                        color="primary"
                        variant="outlined"
                        sx={{
                            borderColor: 'white',
                            color: 'white',
                            marginLeft: 1,
                        }}
                    />
                </div>
            </Box>
            <AttachmentCreateCoverImageForEntityForm
                open={uploadCoverImageOpen}
                entityId={property?.id}
                entityType={EntityType.Property}
                onSave={() => setUploadCoverImageOpen(false)}
                onCancel={() => setUploadCoverImageOpen(false)}
            />
        </div>
    )
}