import { useEffect, useState } from "react";
import { CollectionPostcode, IListCollectionPostcodes } from "../../@types/collection_postcode";
import { useAppDispatch } from "../../store/hooks";
import { listCollectionPostcodesAsync } from "./collectionPostcodesSlice";

export interface IUseFetchCollectionPostcodesPageHookProps extends IListCollectionPostcodes {
    min_page_number_to_fetch: number;
}

export const useFetchCollectionPostcodesPageHook = ({ page_number, min_page_number_to_fetch, page_size, filter }: IUseFetchCollectionPostcodesPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<CollectionPostcode[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<CollectionPostcode[]>([]);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(listCollectionPostcodesAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var collectionPostcodes = resp.payload as CollectionPostcode[];
                if (collectionPostcodes?.length) {
                    setMorePages(collectionPostcodes.length >= Number(page_size))
                    setLastResultSet(collectionPostcodes);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.id === x.id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}