import { AppDispatch } from "./root";

var dispatch: AppDispatch;

export const SetExposedDispatch = (_dispatch: AppDispatch) => {
    dispatch = _dispatch;
}

export const GetExposedDispatch = () => {
    return dispatch;
}
