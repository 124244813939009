import { Typography } from "@mui/material";



const GetBalanceFromPointsRemaining = (pointsRemaining?: number) => {
    if (!pointsRemaining || pointsRemaining < 0) {
        pointsRemaining = 0
    }

    return "$" + (pointsRemaining / 100).toFixed(2);
}

interface IPointsRemainingIconProps {
    pointsRemaining?: number | undefined,
}

export default function PointsRemainingIcon({ pointsRemaining }: IPointsRemainingIconProps) {
    return (
        <div>
            <Typography
                variant="body2"
                fontWeight="bold"
                sx={{
                    padding: 0,
                    margin: 0,
                    cursor: 'pointer',
                }}
                paragraph={false}
            >
                <p
                    style={{
                        fontSize: '8px',
                        marginTop: '-12px',
                        lineHeight: '12px',
                        marginBottom: 0,
                        paddingBottom: 0,
                        textAlign: 'right'
                    }}
                >
                    BALANCE
                </p>
                {GetBalanceFromPointsRemaining(pointsRemaining)}
            </Typography>
        </div>
    )
}