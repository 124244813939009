import { FilePresentOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Collection } from "../../@types/collection";

interface ICollectionUnlockFeeChipProps {
    collection: Collection;
}

export default function CollectionAttachmentsCountChip({ collection }: ICollectionUnlockFeeChipProps) {
    return (
        <Chip
            icon={<FilePresentOutlined />}
            label={collection.collection_attachments_count}
            color="primary"
            variant="outlined"
            sx={{
                borderColor: blue[500],
                color: blue[500],
            }}
        />
    )
}