import { Card, CardActionArea, CardContent, Typography, CardActions, Chip } from "@mui/material";
import {
    Link as RouterLink,
} from 'react-router-dom';
import { GetCollectionPageLinkWithDetails } from "../../routes/RouteLinkHelpers";
import { PurchasedCollection } from "../../@types/purchased_collection";
import { CalendarMonth, LockOpenOutlined } from "@mui/icons-material";
import { blueGrey, green } from "@mui/material/colors";
import { DateTime } from "luxon";
import { ToMoney } from "../../utils/generic";

export function PurchasedCollectionListItem({
    purchasedCollection: { name, description, collection_id, amount, created_at },
}: {
    purchasedCollection: PurchasedCollection;
}) {
    return (
        <Card style={{ height: '100%' }}>
            <CardActionArea
                component={RouterLink}
                to={GetCollectionPageLinkWithDetails(name, collection_id)}
            >
                <CardContent sx={{
                    height: 125,
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                    position: 'relative',
                    '&:after': {
                        content: '""',
                        textAlign: 'right',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        left: 0,
                        width: '100%',
                        height: '50px',
                        background: 'linear-gradient(175deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1) 50%)',
                    }
                }}>
                    <Typography gutterBottom variant="body1" component="div">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description || <i>No description</i>}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Typography variant="caption">

                    </Typography>
                    <Chip
                        icon={<CalendarMonth />}
                        label={DateTime.fromISO(created_at.toString()).toRelative()}
                        color="primary"
                        variant="outlined"
                        sx={{
                            borderColor: blueGrey[500],
                            color: blueGrey[500],
                        }}
                    />

                    <Chip
                        icon={<LockOpenOutlined />}
                        label={ToMoney(amount)}
                        color="primary"
                        variant="outlined"
                        sx={{
                            borderColor: green[500],
                            color: green[500],
                        }}
                    />
                </CardActions>
            </CardActionArea>
        </Card >
    )
}