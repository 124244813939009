import { Card, CardActionArea, CardContent, Typography, CardActions } from "@mui/material";
import { Collection } from "../../@types/collection";
import CollectionUnlockFeeChip from "./CollectionUnlockFeeChip";
import CollectionAttachmentsCountChip from "./CollectionAttachmentsCountChip";
import CollectionCreatedChip from "./CollectionCreatedChip";
import {
    Link as RouterLink,
} from 'react-router-dom';
import { GetCollectionPageLink } from "../../routes/RouteLinkHelpers";
import CollectionCurrentUserChip from "./CollectionCurrentUserChip";

export function CollectionListItem({
    collection,
}: {
    collection: Collection,
}) {
    return (
        <Card style={{ height: '100%' }}>
            <CardActionArea component={RouterLink} to={GetCollectionPageLink(collection)}>
                <CardContent sx={{
                    height: 250,
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                    '&:after': {
                        content: '""',
                        textAlign: 'right',
                        position: 'absolute',
                        bottom: 40,
                        right: 0,
                        left: 0,
                        width: '100%',
                        height: '50px',
                        background: 'linear-gradient(175deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1) 50%)',
                    }
                }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {collection.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {collection.description || <i>No description</i>}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Typography variant="caption">

                    </Typography>
                    <CollectionCreatedChip collection={collection} />
                    <CollectionAttachmentsCountChip collection={collection} />
                    <CollectionUnlockFeeChip collection={collection} />
                    <CollectionCurrentUserChip collection={collection} />
                </CardActions>
            </CardActionArea>
        </Card >
    )
}