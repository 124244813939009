import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CollectionPostcode, IListCollectionPostcodes } from '../../@types/collection_postcode';
import { RootState } from '../../store/root';
import CollectionPostcodesApi from './collectionPostcodesApi';

export interface CollectionPostcodesState {
    byId: {
        [id: string]: CollectionPostcode;
    }
}

const initialState: CollectionPostcodesState = {
    byId: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const listCollectionPostcodesAsync = createAsyncThunk(
    'collectionPostcodesGet',
    async (request: IListCollectionPostcodes) => {
        return await CollectionPostcodesApi.List(request);
    }
);

export const deleteCollectionPostcodeAsync = createAsyncThunk(
    'collectionPostcodeDelete',
    async (request: { collection_postcode_id: number}) => {
        return await CollectionPostcodesApi.Delete(request.collection_postcode_id);
    }
);

export const createCollectionPostcodeAsync = createAsyncThunk(
    'collectionPostcodeCreate',
    async (request: { collection_id: number, postcodes: string[]}) => {
        return await CollectionPostcodesApi.Create(request);
    }
);

export const collectionPostcodesSlice = createSlice({
    name: 'collectionPostcodes',
    initialState,
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(listCollectionPostcodesAsync.fulfilled, (state: CollectionPostcodesState, action) => {
                state.byId = {
                    ...state.byId,
                    ...action?.payload?.reduce((acc: {
                        [index: string]: CollectionPostcode,
                    }, item: CollectionPostcode) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(listCollectionPostcodesAsync.rejected, (state) => {
                throw new Error("Need to handle this get collection postcodes rejected")
            })
            .addCase(deleteCollectionPostcodeAsync.fulfilled, (state: CollectionPostcodesState, action) => {
                if (action.payload?.id) {
                    delete state.byId[action.payload.id]
                }
            })
            .addCase(deleteCollectionPostcodeAsync.rejected, (state) => {
                throw new Error("error deleting collection postcodes")
            })
            .addCase(createCollectionPostcodeAsync.fulfilled, (state: CollectionPostcodesState, action) => {
                if(action?.payload?.[0]?.id) {
                    for(var collectionPostcode of action.payload) {
                        state.byId = {
                            ...state.byId,
                            [collectionPostcode.id]: collectionPostcode,
                        };
                    }
                }
            })
            .addCase(createCollectionPostcodeAsync.rejected, (state) => {
                throw new Error("error creating collection postcode")
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectCollectionPostcodeById = (state: RootState, collectionPostcodeId: number) => {
    return (Object.values(state.collectionPostcodes.byId) as Array<CollectionPostcode>)
        .filter((item: CollectionPostcode) => item.id === collectionPostcodeId)?.[0];
}

export const selectCollectionPostcodesByPostcode = (state: RootState, postcode: string) => {
    return (Object.values(state.collectionPostcodes.byId) as Array<CollectionPostcode>)
        .filter((item: CollectionPostcode) => item.postcode === postcode);
}

export const selectCollectionPostcodesByCollectionId = (state: RootState, collectionId: number) => {
    return (Object.values(state.collectionPostcodes.byId) as Array<CollectionPostcode>)
        .filter((item: CollectionPostcode) => item.collection_id === collectionId);
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = collectionPostcodesSlice;

// Extract and export each action creator by name
// export const { getCollectionsAsync }, = actions;

export default reducer;