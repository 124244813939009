import { Attachment } from "../@types/attachment";
import { Collection } from "../@types/collection";
import { Property } from "../@types/property";
import { Slugify } from "../utils/generic";

// Account links.
export const GetAccountPageLink = () => `/account`;

// Attachment links.
export const GetAttachmentPageLink = (attachment: Attachment) => `/attachments/${attachment.id}`;

// Collection links.
export const GetCollectionCreatePageLink = (property: Property) => `/collections/${GetPropertySlug(property)}/${property.id}/create`
export const GetCollectionPageLink = (collection: Collection) => GetCollectionPageLinkWithDetails(collection.name, collection.id);
export const GetCollectionPageLinkWithDetails = (name: string, id: number) => `/collections/${Slugify(name, 25)}/${id}`;

// Generic links.
export const GetContactPageLink = () => '/contact';

// Get pricing links.
export const GetPricingPageLink = () => '/pricing';

// Property links.
export const GetPropertyCreatePageLink = () => '/properties/create';
export const GetPropertiesPageLink = () => '/properties';
export const GetPropertyPageLink = (property: Property) => `${GetPropertiesPageLink()}/${GetPropertySlug(property)}/${property.id}`;

export const GetPropertySlug = (property: Property) => `${Slugify(property.state, 3)}-${Slugify(property.suburb, 15)}-${Slugify(property.street, 25)}-${Slugify(property.street_number, 5)}`;