import { AddShoppingCartOutlined, CreditCardOutlined, LockOpenOutlined, PaidOutlined, ReceiptLongOutlined, SellOutlined } from "@mui/icons-material";
import { blueGrey, green, purple, teal } from "@mui/material/colors";
import { TransactionType } from "../../@types/points_transaction";

export function GetTransactionTypeColor(transactionType: TransactionType) {
    return TransactionTypeColor[transactionType]?.[500] || TransactionTypeColor.default[500];
}

export function GetTransactionTypeBackgroundColor(transactionType: TransactionType) {
    return TransactionTypeColor[transactionType]?.[100] || TransactionTypeColor.default[50];
}

export function GetTransactionTypeIcon(transactionType: TransactionType) {
    return TransactionTypeIcon[transactionType] || TransactionTypeIcon.default;
}

export function GetTransactionTypeText(transactionType: TransactionType) {
    return TransactionTypeText[transactionType] || TransactionTypeText.default;
}


export const TransactionTypeColor = {
    default: teal,
    [TransactionType.DEPOSIT]: blueGrey,
    [TransactionType.WITHDRAWAL]: green,
    [TransactionType.COLLECTION_PURCHASE]: blueGrey,
    [TransactionType.COLLECTION_UNLOCK]: purple,
    [TransactionType.COMMISSION]: green,
}

export const TransactionTypeIcon = {
    default: ReceiptLongOutlined,
    [TransactionType.DEPOSIT]: CreditCardOutlined,
    [TransactionType.WITHDRAWAL]: PaidOutlined,
    [TransactionType.COLLECTION_PURCHASE]: AddShoppingCartOutlined,
    [TransactionType.COLLECTION_UNLOCK]: LockOpenOutlined,
    [TransactionType.COMMISSION]: SellOutlined,
}

export const TransactionTypeText = {
    default: "Transaction",
    [TransactionType.DEPOSIT]: "Credit",
    [TransactionType.WITHDRAWAL]: "Withdrawal",
    [TransactionType.COLLECTION_PURCHASE]: "Collection purchase",
    [TransactionType.COLLECTION_UNLOCK]: "Collection unlock",
    [TransactionType.COMMISSION]: "Commission",
}