import { useEffect, useState } from "react";
import { IListCollections, Collection } from "../../@types/collection";
import { useAppDispatch } from "../../store/hooks";
import { getCollectionsByIdIfNeededAsync, listCollectionsAsync } from "./collectionsSlice";

export interface IUseFetchCollectionsPageHookProps extends IListCollections {
    min_page_number_to_fetch: number;
}

export const useFetchCollectionsPageHook = ({ page_number, min_page_number_to_fetch, page_size, filter }: IUseFetchCollectionsPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<Collection[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<Collection[]>([]);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(listCollectionsAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var collections = resp.payload as Collection[];
                if (collections?.length) {
                    setMorePages(collections.length >= Number(page_size))
                    setLastResultSet(collections);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.id === x.id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}



// useFetchMissingCollectionsByIdIfNeededHook collections by id if they are not already in the store.
export const useFetchMissingCollectionsByIdIfNeededHook = (collectionIds: number[]) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch any missing collections.
    const collectionIdsJSON = JSON.stringify(collectionIds);
    useEffect(() => {
        const collectionIdsParsed = JSON.parse(collectionIdsJSON);

        if (loading || !collectionIdsParsed?.length) {
            return;
        }

        setLoading(true);

        (async () => {
            try {
                await dispatch(getCollectionsByIdIfNeededAsync(collectionIdsParsed));
            } finally {
                setLoading(false);
            }
        })();
    }, [collectionIdsJSON, loading, dispatch, setLoading]);

    return { loading };
}