import { Divider, Grid, Typography } from "@mui/material";
import AddCredit from "./AddCredit";
import BasicMembership from './BasicMembership';
import StandardMembership from './StandardMembership';
import AddCreditCustom from './AddCreditCustom';
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';
import ParticlesHeader from "../layouts/sections/ParticlesHeader";

export default function ViewPricing() {
    return (
        <LayoutMaxWidthContent loading={false}>
            <ParticlesHeader
                title="Memberships and Credit"
                description="Purchase credit in order to unlock collections. Use a membership to get discounts."
            />    
            
            {/* Pricing for download credit starts. */}
            <Grid container paddingTop={6}>
                <Grid item xs={12} sm={6} md={4} lg={3} padding={4} style={{ textAlign: 'center' }}>
                    <Typography
                        variant="h2"
                        fontSize={18}
                        fontWeight="bold"
                        gutterBottom={true}
                    >
                        Purchase download credit
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight="lighter"
                        fontSize="14px"
                    >
                        Download credit is used to unlock collections. If you have an active paid membership you will also be able to
                        access any collections unlocked by another user for $1.00 each.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} padding={2}>
                    <AddCredit price="5" buttonText='$5.00 Credit' />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} padding={2}>
                    <AddCredit price="20" buttonText="$20.00 Credit" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} padding={2}>
                    <AddCreditCustom />
                </Grid>
                <Grid item xs={12} padding={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} padding={4} style={{ textAlign: 'center' }}>
                    <Typography
                        variant="h2"
                        fontSize={18}
                        fontWeight="bold"
                        gutterBottom={true}
                    >
                        Start a premium membership
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight="lighter"
                        fontSize="14px"
                    >
                        An active premium membership will allow you to
                        access collections unlocked by our members for $1.00 each.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} padding={2}>
                    <BasicMembership />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} padding={2}>
                    <StandardMembership />
                </Grid>
            </Grid>
        </LayoutMaxWidthContent>
    )
}
