import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";

export interface IPricingWidgetProps {
    price: string,
    abovePriceText: string,
    mainTextHeader: string,
    mainText: string,
    buttonLink: string,
    buttonText: string,
    footerText: string,
    hideDollar?: boolean,
}

// INFO: using this as a rough guideline: https://medium.com/design-code-repository/re-pricing-table-83565fad5f85
export default function PricingWidget(
    {
        price,
        abovePriceText,
        mainTextHeader,
        mainText,
        buttonLink,
        buttonText,
        footerText,
        hideDollar,
    } : IPricingWidgetProps
) {
    return (
        <Paper sx={{
            overflow: 'hidden',
            height: '100%',
        }}>
            <Box sx={{
                position: 'relative',
                zIndex: 1,
                backgroundColor: "primary.main",
                overflow: 'hidden',
                color: "#FFF",
                padding: 2,
                paddingLeft: '15%',
                borderRadius: '0 0 75% 75% / 20px',
                marginLeft: '-10%',
                marginRight: '-10%',
                width: '120%',
                textAlign: 'center',
                minHeight: '100px',
                verticalAlign: 'middle',
                lineHeight: '0',
            }}>
                <Grid item xs={12} sx={{
                    textAlign: 'center',
                }}>
                    <Typography>{abovePriceText}</Typography>
                    <Typography display="inline-block" visibility={hideDollar ? "hidden" : "visible"}>
                        <small style={{
                            opacity: 0.8,
                            fontSize: 16,
                            lineHeight: '100%',
                            verticalAlign: 'top',
                        }}>$</small>
                    </Typography>
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        display="inline-block"
                        lineHeight="100%"
                    >
                        {price}
                    </Typography>

                </Grid>

            </Box>
            <Box sx={{
                padding: 2,
            }}>
                <Grid container spacing={3} style={{
                    textAlign: 'center',
                }}>
                    <Grid item xs={12}>
                        <Typography variant="body1">{mainTextHeader}</Typography>
                        <Typography variant="body2" fontWeight="lighter">
                           {mainText}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Link
                            href={buttonLink}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                            >
                                {buttonText}
                            </Button>
                        </Link>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" fontWeight="lighter">
                            {footerText}
                        </Typography>
                    </Grid>
                </Grid>

            </Box>
        </Paper >
    )
}