import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { CollectionListItem } from "../collections/CollectionListItem";
import { IUseFetchCollectionsPageHookProps, useFetchCollectionsPageHook } from "../collections/hooks";
import LoaderSkeletonGrid from "../loaders/LoaderSkeletonGrid";

export interface ICollectionsByUserDisplayProps {
    appUserId: number;
}

export default function CollectionsByUserDisplay({
    appUserId,
}: ICollectionsByUserDisplayProps) {
    const [currentSearchValues, setCurrentSearchValues] = useState<IUseFetchCollectionsPageHookProps>({
        page_number: 1,
        page_size: 12,
        min_page_number_to_fetch: 1,
        filter: {
            created_by: appUserId,
        },
    });

    const { fetching: fetchingCollections, allResultsSet: collections, morePages } = useFetchCollectionsPageHook({
        ...currentSearchValues
    });

    const getNextPage = () => {
        setCurrentSearchValues({
            ...currentSearchValues,
            page_number: (currentSearchValues.page_number || 0) + 1,
        })
    }

    return (
        <Grid container spacing={3}>
            {collections.map((x => {
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <CollectionListItem collection={x} />
                    </Grid>
                )
            }))}

            {
                fetchingCollections && (
                    <LoaderSkeletonGrid
                        gridSizes={{
                            xs: 12,
                            sm: 6,
                            md: 4,
                            lg: 4,
                            xl: 4
                        }}
                        numberOfElements={12}
                        height={250}
                    />
                )
            }

            {
                morePages && !fetchingCollections ? (
                    <Grid item xs={12}
                        sx={{
                            textAlign: 'center',
                            marginTop: 3,
                        }}
                    >
                        <Button
                            onClick={getNextPage}
                            variant="contained"
                            fullWidth
                            sx={{
                                maxWidth: 'sm',
                            }}
                        >More</Button>
                    </Grid>
                ) : null
            }
        </Grid>
    )
}
