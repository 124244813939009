import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { TextField } from "@mui/material";
import { useRef, useEffect, ReactElement } from "react";
import getAddressObject from "./google-maps-parser";

export type PropertyAddressPickerAddress = {
    subpremise: string;
    street_number: string;
    street: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    lat: string;
    lng: string;
}


interface IPropertyAddressPickerProps {
    defaultAddress?: PropertyAddressPickerAddress;
    updateCallback: (address: PropertyAddressPickerAddress) => void;
}


export default function PropertyAddressPicker({ defaultAddress, updateCallback }: IPropertyAddressPickerProps) {
    return (
        <Wrapper apiKey={process.env.REACT_APP_MAPS_KEY || ""} render={render} libraries={[
            "places"
        ]}>
            <AutoComplete defaultAddress={defaultAddress} updateCallback={updateCallback} />
        </Wrapper>
    )

}

const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;

    return <></>;
};

const AutoComplete = ({ defaultAddress, updateCallback }: {
    defaultAddress?: PropertyAddressPickerAddress,
    updateCallback: (address: PropertyAddressPickerAddress) => void
}) => {
    const autoCompleteRef = useRef<any>();
    const inputRef = useRef<any>();

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            {
                componentRestrictions: { country: "aus" },
                fields: ["address_components", "geometry", "icon", "name"],
                types: ["address"]
            }
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            const coords = place.geometry.location.toJSON();

            console.log(place);

            updateCallback({
                ...getAddressObject(place.address_components),
                lat: coords.lat.toString(),
                lng: coords.lng.toString(),
            });
        });
    }, [updateCallback]);

    return (
        <div>
            <TextField
                id="txt-address-lookup"
                label="Address Lookup"
                variant="outlined"
                placeholder="21 Bucknall Court, Regency Downs, QLD, 4341 AU"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                inputRef={inputRef}
            />
        </div>
    );
};