import { Box, Button, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { COUNTRIES, Property, STATES } from "../../@types/property";
import { GetPropertyPageLink } from "../../routes/RouteLinkHelpers";
import { useAppDispatch } from "../../store/hooks";
import { SentenceCaseEnum } from "../../utils/generic";
import { createPropertyAsync } from "./propertiesSlice";


export interface IPropertyCreateFormProps {
    property: Property;
    onSave: (success: boolean) => void;
    onCancel: () => void;
}

export default function PropertyCreateForm({ property, onSave, onCancel }: IPropertyCreateFormProps) {
    const [formData, setFormData] = useState<Property>({ ...property });
    const dispatch = useAppDispatch();
    const [submitting, setSubmitting] = useState(false);
    let navigate = useNavigate();

    const submit = async () => {
        setSubmitting(true);

        try {
            var resp = (await dispatch(createPropertyAsync(formData))) as any;
            if (resp?.payload) {
                onSave(true);
                navigate(GetPropertyPageLink(resp?.payload));
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div>
            <TextField
                id="txt-street-number"
                label="Street Number"
                variant="outlined"
                onChange={(e: any) => setFormData({ ...formData, street_number: e?.target?.value })}
            />

            <TextField
                id="txt-street"
                label="Street"
                variant="outlined"
                placeholder="21 Bucknall"
                onChange={(e: any) => setFormData({ ...formData, street: e?.target?.value })}
            />

            <TextField
                id="txt-suburb"
                label="Suburb"
                variant="outlined"
                placeholder="Regency Downs"
                onChange={(e: any) => setFormData({ ...formData, suburb: e?.target?.value })}
            />

            <TextField
                id="txt-postcode"
                label="Postcode"
                variant="outlined"
                placeholder="4341"
                onChange={(e: any) => setFormData({ ...formData, postcode: e?.target?.value })}
            />

            <TextField
                id="txt-state"
                select
                label="State"
                onChange={(e: any) => setFormData({ ...formData, state: e?.target?.value })}
                defaultValue=""
            >
                {
                    Object.entries(STATES).map(([key, value]) => (
                        <MenuItem key={value} value={value}>{SentenceCaseEnum(key)}</MenuItem>
                    ))
                }
            </TextField>

            <TextField
                id="txt-country"
                select
                label="Country"
                onChange={(e: any) => setFormData({ ...formData, country: e?.target?.value })}
                defaultValue=""
            >
                <MenuItem key="" value="">
                    Select a country
                </MenuItem>
                {
                    Object.entries(COUNTRIES).map(([key, value]) => (
                        <MenuItem key={value} value={value}>{SentenceCaseEnum(key)}</MenuItem>
                    ))
                }
            </TextField>

            <Box
                textAlign="right"
                marginTop={2}
            >
                <Button
                    className="mr-3"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                >
                    {submitting ? "Creating..." : "Create"}
                </Button>
            </Box>
        </div>
    );
}
