import { IListCollections, Collection } from "../../@types/collection";
import { GetApiUrl, GetIdToken, HandleApiResponse } from "../../utils/api_utils";


export async function List(request: IListCollections): Promise<Collection[]> {
    try {
        const resp = await fetch(`${GetApiUrl()}/collections`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Update(request: Collection): Promise<Collection> {
    try {
        const resp = await fetch(`${GetApiUrl()}/collections`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ collection: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Create(request: Collection): Promise<Collection> {
    try {
        const resp = await fetch(`${GetApiUrl()}/collections`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ collection: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const exports = {
    List,
    Update,
    Create,
}

export default exports;