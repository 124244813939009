import { Collection } from '../../@types/collection';
import { AppUser } from '../../@types/app_user';
import { CollectionAttachment } from '../../@types/collection_attachment';
import CollectionManagementDisplay from './CollectionManagement/CollectionManagementDisplay';
import { Property } from '../../@types/property';
import CollectionDisplayDefault from './CollectionDisplayDefault';

export interface ICollectionDisplayProps {
    collection: Collection,
    currentUser?: AppUser,
    collectionAttachments: CollectionAttachment[],
    property: Property,
    showDownloadLink: boolean,
}

export default function CollectionDisplay(props: ICollectionDisplayProps) {
    if (props.collection.created_by === props.currentUser?.id) {
        return <CollectionManagementDisplay {...props} />
    }

    return <CollectionDisplayDefault {...props} />
}
