import { Auth } from "aws-amplify";
import { ShowError } from "./generic";

/* Retrieve the access token to be used for api requests. */
export const GetIdToken = async () => {
    try {
        var currentSession = (await Auth.currentSession());
        var idToken = currentSession.getIdToken();

        // @ts-ignore: This is the property that we want.
        return idToken.jwtToken;
    } catch (e) {
        console.error(e);
    }

    return "";
}

/* HandleApiResponse helps to process generic errors from the API. */
export const HandleApiResponse = async (response: Response) => {
    try {
        if (response.ok) {
            return await response.json();
        }

        if (response.status === 401) {
            ShowError("You are not authorized to perform this action.");
        } else if (response.status === 404) {
            ShowError("The requested resource was not found.");
        } else if (response.status === 500) {
            ShowError("The server encountered an error.");
        } else if (response.status === 400) {
            try {
                const json = await response.json();
                if(json?.message) {
                    ShowError(json.message);
                    return;
                }
            } catch (e) {
                ShowError("The server encountered an error.");
                return;
            }
        }

        ShowError("An error has occurred.");
    } catch (e) {
        console.error(e);
    }
}

/* GetApiUrl returns the API URL. */
export const GetApiUrl = (useLocalApiIfLocal?: boolean) => {
    // If production environment, use the production API URL.
    if (process.env.NODE_ENV === "production") {
        return process.env.REACT_APP_BASE_API_URL;
    }

    // If local environment, use the local API URL.
    if (useLocalApiIfLocal) {
        return process.env.REACT_APP_BASE_API_URL_LOCAL;
    }

    // Otherwise, use the primary URL.
    return process.env.REACT_APP_BASE_API_URL;
}

