import { PaginationProps } from "./generic";

export type Property = {
    id: number;
    status: string,
    subpremise: string,
    street_number: string,
    street: string,
    lat: string,
    lng: string,
    suburb: string,
    state: string,
    postcode: string,
    country: string,
    cover_attachment_id: number,
    list_attachment_id: number,
    collections_count: number,
    unlocked_collections_count: number,
    free_collections_count: number,
    formatted_address: string,
    created_by: number,
    created_at: Date,
    updated_at: Date,
    deleted_at?: Date,
}

export interface IListPropertiesFilter {
    property_ids?: Array<number>,
    order_by?: number,
    created_by?: number,
    state?: string,
    country?: string,
    suburb?: string,
    postcode?: string,
    street?: string,
    street_number?: string,
    subpremise?: string,
}

export interface IListProperties extends PaginationProps {
    filter: IListPropertiesFilter,
}

export enum STATES {
    Other = "",
    Queensland = "QLD",
    NewSouthWales = "NSW",
    Victoria = "VIC",
    SouthAustralia = "SA",
    Tasmania = "TAS",
    WesternAustralia = "WA",
    NorthernTerritory = "NT",
    AustralianCapitalTerritory = "ACT",
}

export enum STREET_TYPES {
    Other = "",
    Avenue = "ave",
    Boulevard = "blvd",
    Circuit = "cct",
    Close = "cl",
    Court = "ct",
    Crescent = "cres",
    Drive = "dr",
    Esplanade = "esp",
    Grove = "gr",
    Heights = "hts",
    Highway = "hwy",
    Lane = "ln",
    Parade = "pde",
    Place = "pl",
    Road = "rd",
    Square = "sq",
    Street = "st",
    Terrace = "tce",
    Way = "way",
}

export enum COUNTRIES {
    Australia = "AUS",
    NewZealand = "NZ",
}

export enum PropertyStatus {
    UNSPECIFIED = 'unspecified',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}