import { Container, } from "@mui/material";
import BreadcrumbsWrappers from "../../components/breadcrumbs/BreadcrumbsWrapper";
import { GetAccountBreadcrumb } from "../../components/breadcrumbs/BreadcrumbUtils";
import { useDocTitle } from "../../routes/Hooks";
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';
import ParticlesHeader from "../layouts/sections/ParticlesHeader";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from "react";
import PurchasedCollectionsDisplay from "../../components/purchased_collections/PurchasedCollectionsDisplay";
import PointsTransactionsDisplay from "../../components/points_transactions/PointsTransactionsDisplay";
import MembershipsDisplay from "../../components/memberships/MembershipsDisplay";
import CollectionsByUserDisplay from "../../components/collections/CollectionsByUserDisplay";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../components/global/globalsSlice";

export default function ViewAccount() {
    useDocTitle("Account");

    return (
        <LayoutMaxWidthContent loading={false}>
            <ParticlesHeader
                title="Manage your Account"
                description="View your purchases and account settings."
            />

            <Container
                maxWidth="lg"
                sx={{
                    marginTop: 3,
                }}
            >
                <BreadcrumbsWrappers breadcrumbs={[GetAccountBreadcrumb()]} />
                <Box
                    sx={{
                        mt: 3,
                    }}
                >
                    <VerticalTabs />
                </Box>
            </Container>
        </LayoutMaxWidthContent>
    )
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export function VerticalTabs() {
    const [currentTab, setCurrentTab] = useState(0);
    const currentUser = useSelector(selectCurrentUser);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    if (!currentUser) return null;

    return (
        <Box
            sx={{
                flexGrow: 1,
                bgcolor: 'background.paper',
                display: 'flex',
            }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={currentTab}
                onChange={handleTabChange}
                aria-label="Account tabs"
                sx={{ borderRight: 1, borderColor: 'divider', }}
            >
                <Tab label="Uploads" {...a11yProps(0)} />
                <Tab label="Purchases" {...a11yProps(0)} />
                <Tab label="Transactions" {...a11yProps(1)} />
                <Tab label="Membership" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
                <Box
                    sx={{
                        mb: 3,
                    }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 'lighter',
                            fontSize: 24,
                        }}
                    >
                        Created Collections
                    </Typography>
                    <Typography variant="body2">This shows the collections that you have created.</Typography>
                </Box>
                <CollectionsByUserDisplay appUserId={currentUser.id} />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <PurchasedCollectionsDisplay />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <PointsTransactionsDisplay />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
                <MembershipsDisplay />
            </TabPanel>
        </Box>
    );
}