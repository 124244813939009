import { Box } from "@mui/material";
import { Children, ReactElement, useEffect, useRef, useState } from "react";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual } from "fast-equals";
import React from "react";

// Not really sure why Google would have such a crap example for this, will need to switch to a better implementation later.
// https://github.com/googlemaps/js-samples/blob/191d8fe589613c4c69003bed6a4064487d816272/samples/react-map/index.tsx#L133-L140
// https://developers.google.com/maps/documentation/javascript/react-map#typescript_4

interface IPropertyLocationPickerProps {
    lat: number;
    lng: number;
    zoom: number;
    onClick: (lat: number, lng: number, zoom: number) => void;
}

export default function PropertyLocationPicker({
    lat,
    lng,
    zoom,
    onClick,
}: IPropertyLocationPickerProps) {
    return (
        <LocationPicker lat={lat || 0} lng={lng || 0} zoom={zoom} onClick={onClick} />
    )

}

interface ILocationPickerProps {
    lat: number;
    lng: number;
    zoom: number;
    onClick: (lat: number, lng: number, zoom: number) => void;
}

function LocationPicker({ lat, lng, zoom, onClick }: ILocationPickerProps): ReactElement {
    const [marker, setMarker] = React.useState<google.maps.LatLng>();
    const [defaultZoom] = useState<number>(zoom);

    const internalOnClick = (e: google.maps.MapMouseEvent) => {
        onClick(e.latLng?.lat() ?? 0, e.latLng?.lng() || 0, zoom);
    };

    useEffect(() => {
        setMarker(new google.maps.LatLng(lat, lng))
    }, [lat, lng])

    return (
        <Box
            sx={{
                borderRadius: 1,
                overflow: 'hidden',
            }}
        >
            <Map
                onClick={internalOnClick}
                style={{
                    height: '500px',
                    width: '100%',
                }}
                center={{
                    lat: lat,
                    lng: lng,
                }}
                zoom={defaultZoom}
                zoomControl={true}
            >
                <Marker key="marker-current-coords" position={marker} />
            </Map>
        </Box>
    );
}


interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode;
}

const Map: React.FC<MapProps> = ({
    onClick,
    onIdle,
    children,
    style,
    ...options
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

function useDeepCompareEffectForMaps(
    callback: React.EffectCallback,
    dependencies: any[]
) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

function useDeepCompareMemoize(value: any) {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

const deepCompareEqualsForMaps = createCustomEqual(
    // @ts-ignore:next-line
    (deepEqual) => (a: any, b: any) => {
        if (
            isLatLngLiteral(a) ||
            a instanceof google.maps.LatLng ||
            isLatLngLiteral(b) ||
            b instanceof google.maps.LatLng
        ) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
        }

        // use fast-equals for other objects
        // @ts-ignore:next-line
        return deepEqual(a, b);
    }
);

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};