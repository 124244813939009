import { Box } from "@mui/material";

export interface IContainedBodySectionProps {
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

export default function ContainedBodySection(props: IContainedBodySectionProps) {
    return (
        <Box sx={{
            width: '100vw',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ...props.style,
        }}>
            <Box sx={{
                width: '100vw',
                maxWidth: {
                    xs: '100vw',
                    md: '750px',
                },
            }}>
                {props.children}
            </Box>
        </Box>
    )
}