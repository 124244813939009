import { GetApiUrl } from "../../utils/api_utils";

interface IContactProps {
    email: string;
    message: string;
    subscribe: boolean;
}


export async function Contact(request: IContactProps): Promise<boolean> {
    try {
        const resp = await fetch(`${GetApiUrl()}/contacts`, {
            method: 'POST',
            // headers: headers,
            body: JSON.stringify(request),
        });

        if (resp.ok) {
            return true
        } else {
            throw new Error(resp.statusText);
        }
    } catch (e) {
        console.error(e);
        throw e;
    }
}