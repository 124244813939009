import { IListAttachments, Attachment } from "../../@types/attachment";
import { GetApiUrl, GetIdToken, HandleApiResponse } from "../../utils/api_utils";

export async function GetDownloadUrl(id: number): Promise<string> {
    try {
        const resp = await fetch(`${GetApiUrl()}/attachments/${id}/download_url`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function List(request: IListAttachments): Promise<Attachment[]> {
    try {
        const resp = await fetch(`${GetApiUrl()}/attachments`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Update(request: Attachment): Promise<Attachment> {
    try {
        const resp = await fetch(`${GetApiUrl()}/attachments`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ attachment: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Create(request: Attachment): Promise<Attachment> {
    try {
        const resp = await fetch(`${GetApiUrl()}/attachments`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ attachment: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const exports = {
    List,
    Update,
    Create,
    GetDownloadUrl,
}

export default exports;