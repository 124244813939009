import { useEffect, useState } from "react";
import { IListPointsTransactions, PointsTransaction } from "../../@types/points_transaction";
import { useAppDispatch } from "../../store/hooks";
import { listPointsTransactionsAsync } from "./pointsTransactionsSlice";

export interface IUseFetchPointsTransactionsPageHookProps extends IListPointsTransactions {
    min_page_number_to_fetch: number;
}

export const useFetchPointsTransactionsPageHook = ({ page_number, min_page_number_to_fetch, page_size, filter }: IUseFetchPointsTransactionsPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<PointsTransaction[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<PointsTransaction[]>([]);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(listPointsTransactionsAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var pointsTransactions = resp.payload as PointsTransaction[];
                if (pointsTransactions?.length) {
                    setMorePages(pointsTransactions.length >= Number(page_size))
                    setLastResultSet(pointsTransactions);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.id === x.id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}