export type PaginationProps = {
    page_number?: number,
    page_size?: number,
    order_by?: number,
}

export enum EntityType {
    User = "user",
    Property = "property",
    Collection = "collection",
}

export type ValidationError = {
    value: string,
    path: string, // Path is the name of the property.
    errors: string[],
}