import { Lock } from "@mui/icons-material";
import { Chip } from "@mui/material";

interface IAttachmentLockedChipProps {
}

export default function AttachmentLockedChip(props: IAttachmentLockedChipProps) {
    return (
        <Chip
            icon={<Lock />}
            label="Locked"
            color="primary"
            variant="outlined"
            sx={{
                borderColor: "rgba(0,0,0,0.35)",
                color: "rgba(0,0,0,0.35)",
            }}
        />
    )
}