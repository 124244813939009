import { Search, SvgIconComponent } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"


interface INoSearchResultsFoundProps {
    icon?: SvgIconComponent,
    title?: string,
    description?: string,
}

export default function NoSearchResultsFound({ icon = Search, title, description }: INoSearchResultsFoundProps) {
    const Icon = icon;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: {
                    xs: '100%',
                    sm: 'sm',
                },
                padding: 3,
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: 3,
            }}
        >
            <Icon
                sx={{
                    color: 'rgba(0,0,0,0.25)',
                    height: 75,
                    width: 75,
                }}
            />

            <Typography variant="subtitle1" fontWeight="medium" color="textSecondary" sx={{
                marginTop: 1,
            }}>
                {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{
                maxWidth: {
                    xs: '100%',
                    sm: 'sm',
                    textAlign: 'center',
                },
            }}
            >
                {description}
            </Typography>
        </Box>
    )
}