import './App.css';
import { useSelector } from 'react-redux';
import { RootState } from './store/root';
import { selectLoading } from './components/global/globalsSlice';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './routes/RoutesScrollToTop';
import RouteTree from './routes/RouteTree';

function App() {
  const loading = useSelector((store: RootState) => selectLoading(store));
    
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RouteTree loading={loading}/>
    </BrowserRouter>
  );
}

export default App;
