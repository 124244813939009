import { useEffect, useState } from "react";
import { IGetAppUsers, AppUser } from "../../@types/app_user";
import { useAppDispatch } from "../../store/hooks";
import { getAppUsersAsync, getAppUsersByIdIfNeededAsync } from "./appUsersSlice";

export interface IUseFetchAppUsersPageHookProps extends IGetAppUsers {
    min_page_number_to_fetch: number;
}

export const useFetchAppUsersPageHook = ({ page_number, min_page_number_to_fetch, page_size, filter }: IUseFetchAppUsersPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<AppUser[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<AppUser[]>([]);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(getAppUsersAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var appUsers = (resp.payload as any)?.app_users;
                if (appUsers?.length) {
                    setMorePages(appUsers.length >= Number(page_size))
                    setLastResultSet(appUsers);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.id === x.id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}


// useFetchAppUsersByIdIfNeededHook fetches app users by id if they are not already in the store.
export const useFetchMissingAppUsersByIdIfNeededHook = (appUserIds: number[]) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch any missing users.
    useEffect(() => {
        if (loading || !appUserIds?.length) {
            return;
        }

        const getMissingAppUsers = async () => {
            setLoading(true);
            try {
                await dispatch(getAppUsersByIdIfNeededAsync(appUserIds));
            } finally {
                setLoading(false);
            }
        };

        getMissingAppUsers();
    }, [appUserIds, loading, dispatch]);

    return { loading };
}