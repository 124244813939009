import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import { selectCurrentUser } from '../../global/globalsSlice';
import { Collection } from '../../../@types/collection';
import CollectionManagementAttachmentTab from './CollectionManagementAttachmentsTab';
import CollectionManagementPostcodesTab from './CollectionManagementPostcodesTab';


interface ICollectionManagementTabsProps {
    collection: Collection;
}


export default function CollectionManagementTabs({
    collection,
}: ICollectionManagementTabsProps) {
    return (
        <VerticalTabs collection={collection} />
    )
}

interface IVerticalTabsProps extends ICollectionManagementTabsProps {
    
}

export function VerticalTabs({ collection } : IVerticalTabsProps) {
    const [currentTab, setCurrentTab] = useState(0);
    const currentUser = useSelector(selectCurrentUser);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    if (!currentUser) return null;

    return (
        <Box
            sx={{
                flexGrow: 1,
                bgcolor: 'background.paper',
                display: 'flex',
            }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={currentTab}
                onChange={handleTabChange}
                aria-label="Collection tabs"
                sx={{ borderRight: 1, borderColor: 'divider', }}
            >
                <Tab label="Attachments" {...a11yProps(0)} />
                <Tab label="Postcodes" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={currentTab} index={0}>                
                <CollectionManagementAttachmentTab collectionId={collection.id} currentUser={currentUser} />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <CollectionManagementPostcodesTab collectionId={collection.id} />
            </TabPanel>
        </Box>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}