import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUser, IGetAppUsers } from '../../@types/app_user';
import { RootState } from '../../store/root';
import { ShowError } from '../../utils/generic';
import { GetAppUsersApi } from './appUsersApi';

export interface AppUsersState {
    byId: {
        [id: string]: AppUser;
    },
    fetchedById: {
        [id: string]: null,
    },
}

const initialState: AppUsersState = {
    byId: {},
    fetchedById: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getAppUsersAsync = createAsyncThunk(
    'getAppUsers',
    async (request: IGetAppUsers) => {
        return await GetAppUsersApi(request);
    }
);

export const getAppUsersByIdIfNeededAsync = createAsyncThunk(
    'getAppUsersByIdIfNeeded',
    async (appUserIds: number[], { getState, dispatch }) => {
        const state = getState() as RootState;

        // Ensure that we won't try to double fetch.
        dispatch(appUsersSlice.actions.fetched(appUserIds));

        // Ensure they aren't already there.
        let missingAppUserIds = appUserIds.filter(id => !state.appUsers.byId[id]);
        
        // Ensure we haven't already tried to fetch them.
        missingAppUserIds = missingAppUserIds.filter(id => {
            return !Object.hasOwn(state.appUsers.fetchedById, id)
        });

        if(!missingAppUserIds.length) return;

        return await GetAppUsersApi({
            filter: {
                app_user_id: missingAppUserIds,
            },
        });
    }
);

export const appUsersSlice = createSlice({
    name: 'appUsers',
    initialState,
    reducers: {
        fetched: (state, action: PayloadAction<Array<number>>) => {
            if(!action?.payload) return;
            
            state.fetchedById = action.payload.reduce((acc, id) => {
                acc[id] = null;
                return acc;
            }, state.fetchedById);
        }
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getAppUsersAsync.fulfilled, (state: AppUsersState, action) => {
                if(!action?.payload) return;

                state.byId = {
                    ...state.byId, ...action.payload.reduce((acc: {
                        [index: string]: AppUser,
                    }, item: AppUser) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(getAppUsersAsync.rejected, (state) => {
                ShowError("Failed to retrieve app users.");
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectAppUserById = (state: RootState, appUserId: Number) => {
    return (Object.values(state.appUsers.byId) as Array<AppUser>)
        .find((item: AppUser) => item.id === appUserId);
}

export const selectAppUsersById = (state: RootState, appUserIds: Number[]) => {
    return (Object.values(state.appUsers.byId) as Array<AppUser>)
        .filter((item: AppUser) => appUserIds.includes(item.id));
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = appUsersSlice;

// Extract and export each action creator by name
// export const { getAppUsersAsync }, = actions;

export default reducer;