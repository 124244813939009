import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { green } from "@mui/material/colors";
import { Collection } from "../../@types/collection";
import { DateIsSet, ToMoney } from "../../utils/generic";

interface ICollectionUnlockFeeChipProps {
    collection: Collection;
}

export default function CollectionUnlockFeeChip({ collection }: ICollectionUnlockFeeChipProps) {
    const unlocked = !collection.unlock_fee || DateIsSet(collection.unlocked_at);
    const icon = unlocked ? <LockOpenOutlined /> : <LockOutlined />;
    const label = unlocked ? "Unlocked" : `${ToMoney(collection?.unlock_fee)}`;

    return (
        <Chip
            icon={icon}
            label={label}
            color="primary"
            variant="outlined"
            sx={{
                borderColor: green[500],
                color: green[500],
            }}
        />
    )
}