import { CheckCircleOutlined, ReceiptLong } from "@mui/icons-material";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Membership } from "../../@types/membership";
import { StripeAppUserMapping } from "../../@types/stripe_app_user_mapping";
import { GetPricingPageLink } from "../../routes/RouteLinkHelpers";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/root";
import { DateIsSet, TimeDifferenceAsString } from "../../utils/generic";
import NoSearchResultsFound from "../../views/layouts/placeholders/NoSearchResultsFound";
import AnimationWrapper from "../animations/AnimationWrapper";
import { selectCurrentUser } from "../global/globalsSlice";
import LoaderSkeletonGrid from "../loaders/LoaderSkeletonGrid";
import { IUseFetchMembershipsPageHookProps, useFetchMembershipsPageHook } from "./hooks";
import { getMembershipBillingPortalLink } from "./membershipsSlice";


export default function MembershipsDisplay() {
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const [currentSearchValues, setCurrentSearchValues] = useState<IUseFetchMembershipsPageHookProps>({
        page_number: 1,
        page_size: 10,
        min_page_number_to_fetch: 1,
        filter: {
            app_user_ids: [currentUser?.id || 0],
        },
    });

    const {
        fetching: fetchingMemberships,
        allResultsSet: memberships,
        morePages,
    } = useFetchMembershipsPageHook({
        ...currentSearchValues
    });

    const getNextPage = () => {
        setCurrentSearchValues({ ...currentSearchValues, page_number: (currentSearchValues.page_number || 0) + 1 })
    }


    const loading = fetchingMemberships;

    return (
        <Container
            maxWidth="sm"
            sx={{
                marginTop: 0,
                width: '100%',
            }}
        >
            <Box
                sx={{
                    mb: 3,
                }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: 'lighter',
                        fontSize: 24,
                    }}
                >
                    Manage Memberships
                </Typography>
                <Typography variant="body2">Your memberships and stripe accounts will appear here.</Typography>
            </Box>

            {memberships.length === 0 && !memberships && (
                <NoSearchResultsFound
                    title="No membership yet."
                    description="Membership will appear here."
                    icon={ReceiptLong}
                />
            )}

            <Grid container spacing={3} maxWidth="md">
                {memberships.map(x => {
                    return (
                        <Grid item xs={12}>
                            <AnimationWrapper>
                                <div>
                                    <ShowMembership membership={x} />
                                </div>
                            </AnimationWrapper>
                        </Grid>
                    )
                })}

                {(loading) && (
                    <LoaderSkeletonGrid gridSizes={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    }} numberOfElements={1} height={300}
                    />
                )}

                {
                    morePages && !loading ? (
                        <Grid item xs={12}
                            sx={{
                                textAlign: 'center',
                                marginTop: 3,
                            }}
                        >
                            <Button
                                onClick={getNextPage}
                                variant="contained"
                                fullWidth
                                sx={{
                                    maxWidth: 'sm',
                                }}
                            >More</Button>
                        </Grid>
                    ) : null
                }
            </Grid>
        </Container>
    )
}

export interface IShowMembershipProps {
    membership: Membership,
}

// INFO: using this as a rough guideline: https://medium.com/design-code-repository/re-pricing-table-83565fad5f85
export function ShowMembership(
    {
        membership
    }: IShowMembershipProps
) {
    const dispatch = useAppDispatch();
    const [loadingAppUserMappings, setLoadingAppUserMappings] = useState(false);
    const [stripeAppUserMappings, setStripeAppUserMappings] = useState<StripeAppUserMapping[]>([])

    useEffect(() => {
        setLoadingAppUserMappings(true);

        (async function() {
            try {
                var resp = (await dispatch(getMembershipBillingPortalLink({
                    return_url: window.location.toString(),
                }))) as any;
                if (resp?.payload?.mappings?.length) {
                    setStripeAppUserMappings(resp.payload.mappings);
                    // window.location = resp?.payload?.billing_portal_url;
                }
            } catch (e: unknown) {
                console.log(e);
            } finally {
                setLoadingAppUserMappings(false);
            }
        })();
    }, [setStripeAppUserMappings, dispatch]);

    if (!DateIsSet(membership?.expires_at) || new Date(membership?.expires_at) < new Date()) {
        return (
            <Paper sx={{
                overflow: 'hidden',
                height: '100%',
                padding: 2,
            }}>
                <Box sx={{
                    textAlign: 'center',
                    padding: 3,
                    mb: 1,
                }}>
                    <Typography variant="body1">No active membership</Typography>
                    <Typography variant="body2" fontWeight="lighter">
                        You do not currently have an active membership.
                    </Typography>
                </Box>
                <Link
                    to={GetPricingPageLink()}
                    style={{
                        textDecoration: 'none',
                    }}
                >

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                    >
                        View Pricing
                    </Button>
                </Link>
                <Typography variant="body2" fontWeight="lighter" sx={{
                    mt: 3,
                }}>
                    An active membership provides a significant discount on unlocked documents.
                    You will still need download credit (either earned or purchased) to download
                    documents.
                </Typography>
            </Paper>
        )
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper sx={{
                    overflow: 'hidden',
                    height: '100%',
                    padding: 2,
                }}>
                    <Grid container spacing={3} style={{
                        textAlign: 'center',
                    }}>
                        <Grid item xs={12}>
                            <Typography variant="body1">You have an active membership</Typography>
                            <Typography variant="body2" fontWeight="lighter">
                                Renews on {new Date(membership.expires_at).toDateString()}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <CheckCircleOutlined sx={{
                                color: green[400],
                                fontSize: 50,
                            }} />
                            {/* TODO: Show a tick here */}
                            {/* <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        disabled={loading}
                        onClick={redirectToBillingPortal}
                    >
                        {loading ? "Redirecting" : "Manage"}
                    </Button> */}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body2" fontWeight="lighter">
                                An active membership provides a significant discount on unlocked documents.
                                You will still need download credit (either earned or purchased) to download
                                documents.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            {
                loadingAppUserMappings && !stripeAppUserMappings?.length &&
                (
                    <LoaderSkeletonGrid gridSizes={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12
                    }} numberOfElements={3} height={150}
                    />
                )
            }

            {
                stripeAppUserMappings?.map(x => {
                    return (
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    border: '1px solid #EEE',
                                    padding: 3,
                                    borderRadius: 1,
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">Stripe Account</Typography>
                                        <Typography variant="body2" fontWeight="lighter">
                                            Updated {TimeDifferenceAsString(new Date(x.updated_at))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <a href={x.billing_portal_url} style={{
                                            textDecoration: 'none',
                                        }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    textDecoration: 'none',
                                                }}
                                                fullWidth
                                            >
                                                Manage Stripe Account
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}
