import React, {  } from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { RootState } from '../../store/root';
import { MessageType } from '../../@types/message';
import { MessageDelete } from '../../utils/generic';

const GetSeverityColor = (messageType: MessageType) => {
  switch (messageType) {
    case MessageType.ERROR:
      return "error";
    case MessageType.WARNING:
      return "warning";
    case MessageType.SUCCESS:
      return "success";
    default:
      return "info";
  }
}

export default function MessagesDisplay() {
  const messages = useSelector((store: RootState) => Object.values(store.messages.byId));

  const handleClose = (id: number) => {
    MessageDelete(id);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {messages.map((message) => (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => handleClose(message.id)}>
          <Alert onClose={() => handleClose(message.id)} severity={GetSeverityColor(message.type)} sx={{ width: '100%' }}>
            {message?.message}
          </Alert>
        </Snackbar>
      ))}
    </Stack>
  );
}


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});