import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IListCollectionAttachments, CollectionAttachment } from '../../@types/collection_attachment';
import { RootState } from '../../store/root';
import CollectionAttachmentsApi from './collectionAttachmentsApi';

export interface CollectionAttachmentsState {
    byId: {
        [id: string]: CollectionAttachment;
    }
}

const initialState: CollectionAttachmentsState = {
    byId: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const listCollectionAttachmentsAsync = createAsyncThunk(
    'collectionAttachmentsGet',
    async (request: IListCollectionAttachments) => {
        return await CollectionAttachmentsApi.List(request);
    }
);

export const updateCollectionAttachmentAsync = createAsyncThunk(
    'collectionAttachmentUpdate',
    async (request: CollectionAttachment) => {
        return await CollectionAttachmentsApi.Update(request);
    }
);

export const createCollectionAttachmentAsync = createAsyncThunk(
    'collectionAttachmentCreate',
    async (request: CollectionAttachment) => {
        return await CollectionAttachmentsApi.Create(request);
    }
);

export const collectionAttachmentsSlice = createSlice({
    name: 'collectionAttachments',
    initialState,
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(listCollectionAttachmentsAsync.fulfilled, (state: CollectionAttachmentsState, action) => {
                state.byId = {
                    ...state.byId,
                    ...action.payload.reduce((acc: {
                        [index: string]: CollectionAttachment,
                    }, item: CollectionAttachment) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(listCollectionAttachmentsAsync.rejected, (state) => {
                throw new Error("Need to handle this get collection attachment rejected")
            })
            .addCase(updateCollectionAttachmentAsync.fulfilled, (state: CollectionAttachmentsState, action) => {
                if(action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(updateCollectionAttachmentAsync.rejected, (state) => {
                throw new Error("error updating collection attachment")
            })
            .addCase(createCollectionAttachmentAsync.fulfilled, (state: CollectionAttachmentsState, action) => {
                if(action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(createCollectionAttachmentAsync.rejected, (state) => {
                throw new Error("error creating collection attachment")
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectCollectionAttachmentById = (state: RootState, collectionAttachmentId: number) => {
    return (Object.values(state.collectionAttachments.byId) as Array<CollectionAttachment>)
        .filter((item: CollectionAttachment) => item.id === collectionAttachmentId)?.[0];
}

export const selectCollectionAttachmentsByCollectionId = (state: RootState, collectionId: number) => {
    return (Object.values(state.collectionAttachments.byId) as Array<CollectionAttachment>)
        .filter((item: CollectionAttachment) => item.collection_id === collectionId);
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = collectionAttachmentsSlice;

// Extract and export each action creator by name
// export const { getCollectionAttachmentsAsync }, = actions;

export default reducer;