import { IStepProps, StepLocate, StepMapPlacement } from "./PropertyCreateWizardSteps";

export default function PropertyCreateWizardContentWrapper(props: IStepProps) {
    switch (props.activeStep) {
        case 0:
            return <StepLocate {...props} />
        case 1:
            return <StepMapPlacement {...props} />
        default:
            return null;
    }
}