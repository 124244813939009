import { ChevronRight, Home, SvgIconComponent } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import {
    Link as RouterLink,
} from 'react-router-dom';


export interface IBreadcrumbsWrapperProps {
    breadcrumbs: (Breadcrumb | null)[],
    excludeHome?: boolean,
}

export type Breadcrumb = {
    label: string;
    link: string;
    icon?: SvgIconComponent;
}

export default function BreadcrumbsWrappers({ breadcrumbs, excludeHome }: IBreadcrumbsWrapperProps) {
    return (
        <div aria-label="breadcrumb">
            {excludeHome ? null : <RenderBreadcrumb breadcrumb={{ label: 'Home', link: '/', icon: Home }} />}
            {breadcrumbs.map((breadcrumb, index) => {
                if (breadcrumb == null) {
                    return null;
                }

                return (
                    <>
                        {index < breadcrumbs.length ? <ChevronRight sx={{
                            color: 'text.secondary',
                            mb: '-4px',
                            fontSize: '1rem',
                        }} /> : null}
                        <RenderBreadcrumb breadcrumb={breadcrumb} key={index} />
                    </>
                )
            })}
        </div>
    );
}

const RenderBreadcrumb = ({ breadcrumb: { icon, label, link } }: { breadcrumb: Breadcrumb }) => {
    return (
        <Box
            sx={{
                display: 'inline-block',
            }}
        >
            <RouterLink color="inherit" to={link} style={{
                textDecoration: 'none',                
            }}>
                <RenderBreadcrumbIcon icon={icon} />
                <Typography
                    variant="overline"
                    sx={{
                        color: 'text.secondary',
                        '&:hover': {
                            textDecoration: 'underline',
                            color: 'rgba(0,0,0,0.87)',
                        },
                    }}
                >
                    {label}
                </Typography>
            </RouterLink>
        </Box>
    );
}

const RenderBreadcrumbIcon = ({ icon }: { icon?: SvgIconComponent }) => {
    if (!icon) return null;

    const BreadcrumbIcon = icon;

    return (
        <Box
            sx={{
                display: 'inline-block',
                mr: 1,
                color: '#BBB',
                mb: -1,
            }}
        >
            <BreadcrumbIcon sx={{ fontSize: 16, mb: '-3px', }} />
        </Box>
    );
}