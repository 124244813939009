import { configureStore } from '@reduxjs/toolkit'
import { messagesSlice } from '../components/messages/messagesSlice'
// import { answersSlice } from '../components/answers/answersSlice'
// import widgetExecutionsSlice from '../components/widgetExecutions/widgetExecutionsSlice'
import appUsersSlice from '../components/app_users/appUsersSlice'
import { propertiesSlice } from '../components/properties/propertiesSlice'
// import { commentsSlice } from '../components/comments/commentsSlice'
import { globalsSlice } from '../components/global/globalsSlice'
import { SetExposedDispatch } from './injector'
import { attachmentsSlice } from '../components/attachments/attachmentsSlice'
import { collectionsSlice } from '../components/collections/collectionsSlice'
import { collectionAttachmentsSlice } from '../components/collection_attachments/collectionAttachmentsSlice'
import { collectionPostcodesSlice } from '../components/collection_postcodes/collectionPostcodesSlice'
import { membershipsSlice } from '../components/memberships/membershipsSlice'
import { pointsTransactionsSlice } from '../components/points_transactions/pointsTransactionsSlice'
import { purchasedCollectionsSlice } from '../components/purchased_collections/purchasedCollectionsSlice'
// import { sitesSlice } from '../components/sites/sitesSlice'

export const store = configureStore({
  reducer: {
    // answers: answersSlice.reducer,
    appUsers: appUsersSlice,
    attachments: attachmentsSlice.reducer,
    collections: collectionsSlice.reducer,
    collectionAttachments: collectionAttachmentsSlice.reducer,
    collectionPostcodes: collectionPostcodesSlice.reducer,
    globals: globalsSlice.reducer,
    memberships: membershipsSlice.reducer,
    messages: messagesSlice.reducer,
    pointsTransactions: pointsTransactionsSlice.reducer,
    properties: propertiesSlice.reducer,
    purchasedCollections: purchasedCollectionsSlice.reducer,
    // sites: sitesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Expose dispatch to the outside world via an injector to prevent circular dependencies.
SetExposedDispatch(store.dispatch);
