import { Person } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { purple } from "@mui/material/colors";
import { Collection } from "../../@types/collection";
import { selectCurrentUser } from "../global/globalsSlice";
import { useSelector } from 'react-redux';

interface ICollectionCurrentUserChipProps {
    collection: Collection;
}

export default function CollectionCurrentUserChip({ collection }: ICollectionCurrentUserChipProps) {
    const currentUser = useSelector(selectCurrentUser);
    
    if(collection.created_by !== currentUser?.id) return null;

    return (
        <Chip
            icon={<Person />}
            label={"Yours"}
            color="primary"
            variant="outlined"
            sx={{
                borderColor: purple[500],
                color: purple[500],
            }}
        />
    )
}