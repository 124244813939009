import { IListPurchasedCollections, PurchasedCollection } from "../../@types/purchased_collection";
import { GetApiUrl, GetIdToken, HandleApiResponse } from "../../utils/api_utils";


export async function List(request: IListPurchasedCollections): Promise<PurchasedCollection[]> {
    try {
        const resp = await fetch(`${GetApiUrl()}/purchased_collections`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Update(request: PurchasedCollection): Promise<PurchasedCollection> {
    try {
        const resp = await fetch(`${GetApiUrl()}/purchased_collections`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ purchased_collection: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export interface ICreatePurchasedCollectionRequest {
    purchased_collection: {
        collection_id: number,
        app_user_id: number,
        amount: number,
    }
}

export async function Create(request: ICreatePurchasedCollectionRequest): Promise<PurchasedCollection> {
    try {
        const resp = await fetch(`${GetApiUrl()}/purchased_collections`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const exports = {
    List,
    Update,
    Create,
}

export default exports;
