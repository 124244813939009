import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { RootState } from '../../store/root';
import { selectPropertyById } from './propertiesSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GetPropertyPageLink } from '../../routes/RouteLinkHelpers';
import { PROPERTY_DEFAULT_COVER_IMAGE } from './propertyConstants';
import { selectAttachmentById } from '../attachments/attachmentsSlice';
import { Badge, CardActionArea, Chip } from '@mui/material';
import { CalendarMonth, FileOpen, LockOpen } from '@mui/icons-material';

export interface IPropertyListItemProps {
    propertyId: number,
}

export default function PropertyListItem({ propertyId }: IPropertyListItemProps) {
    const property = useSelector((store: RootState) => selectPropertyById(store, propertyId));
    const coverImage = useSelector((store: RootState) => selectAttachmentById(store, property.cover_attachment_id));

    if (!property) {
        return null;
    }

    return (
        <Card>
            <CardActionArea
                component={Link}
                to={GetPropertyPageLink(property)}
            >
                <CardMedia
                    component="img"
                    height="140"
                    image={coverImage?.url || PROPERTY_DEFAULT_COVER_IMAGE}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="body1"
                        textOverflow="ellipsis"
                        noWrap={true}
                    >
                        {`${property.street_number} ${property.street}, ${property.suburb} ${property.state} ${property.postcode}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Lizards are a widespread group of squamate reptiles, with over 6,000
                        species, ranging across all continents except Antarctica
                    </Typography>
                </CardContent>
                <CardActions>
                    <Chip
                        icon={<CalendarMonth />}
                        label={
                            <>
                                Collections <Badge

                                >{property.collections_count}</Badge>
                            </>
                        }
                        size="small"
                        color="primary"
                        variant="outlined"
                        hidden={!!property.collections_count}
                    />

                    {!!property.unlocked_collections_count && (
                        <Chip
                            icon={<LockOpen />}
                            label={
                                <>
                                    Unlocked <Badge
                                    >{property.unlocked_collections_count}</Badge>
                                </>
                            }
                            color="primary"
                            size="small"
                            variant="outlined"
                            sx={{
                                marginLeft: 1,
                                marginRight: 1,
                            }}
                            hidden={!!property.unlocked_collections_count}
                        />
                    )}

                    {!!property.free_collections_count && (
                        <Chip
                            icon={<FileOpen />}
                            label={
                                <>
                                    Free <Badge
                                    >{property.free_collections_count || 0}</Badge>
                                </>
                            }
                            color="primary"
                            size="small"
                            variant="outlined"
                            sx={{
                                marginLeft: 1,
                                marginRight: 1,
                            }}
                        />
                    )}

                </CardActions>
            </CardActionArea>
        </Card>
    );
}