import { Button } from '@mui/material';
import React from 'react';

interface IPrimaryButtonProps {
    label: string;
    onClick: (e?: any) => void;
    additionalClasses?: string;
    loading?: boolean;
}


export default function PrimaryButton({
    label,
    onClick,
    additionalClasses,
    loading,
}: IPrimaryButtonProps) {
    return (
        <Button
            onClick={onClick}
            disabled={loading}
            variant="contained"
            color="primary"
        >
            {loading ? "Sending..." : label}
        </Button>
    )
}


// TODO: Fix about link