import { createTheme } from '@mui/material';
import cyan from '@mui/material/colors/blue';

// Module augmentation required when adding new properties to theme (if using typescript): https://mui.com/material-ui/customization/palette/
declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }

    interface Palette {
        darkAugment: Palette['primary'];
    }

    interface PaletteOptions {
        darkAugment: PaletteOptions['primary'];
    }

    interface PaletteColor {
        darker?: string;
    }

    interface SimplePaletteColorOptions {
        darker?: string;
    }

    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: cyan[700],
        },
        darkAugment: {
            main: '#1f2937',
            dark: '#131921',
        },
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    },
});

export default theme;