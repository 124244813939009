import { Box, Button } from "@mui/material";
import { Attachment, AttachmentType } from "../../@types/attachment";
import { EntityType } from "../../@types/generic";
import FormDialog from "../form/FormDialog";
import FormFileDropper from "../form/FormFileDropper";


export interface IAttachmentCreateDialogProps extends IAttachmentCreateFormProps {
    open: boolean;
}

export default function AttachmentCreateDialog(props: IAttachmentCreateDialogProps) {
    return (
        <FormDialog
            onClose={props.onCancel}
            open={props.open}
        >
            <AttachmentCreateForm {...props} />
        </FormDialog>
    )
}


export interface IAttachmentCreateFormProps {
    entityType: EntityType;
    entityId: number;
    attachmentType?: AttachmentType,
    onSave: (success: boolean) => void;
    onCancel: () => void;
    postAttachmentCreatedFunc?: (attachment: Attachment) => Promise<void>;
}

export function AttachmentCreateForm({ attachmentType, entityType, entityId, onSave, onCancel, postAttachmentCreatedFunc }: IAttachmentCreateFormProps) {
    return (
        <div>
            <FormFileDropper
                postAttachmentCreatedFunc={postAttachmentCreatedFunc}
                entityType={entityType}
                entityId={entityId}
                attachmentType={attachmentType}
            />            

            <Box
                textAlign="right"
                marginTop={2}
            >
                <Button
                    className="mr-3"
                    onClick={onCancel}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    Close
                </Button>
            </Box>
        </div>
    );
}