import { useState } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Contact } from "../../components/contact/contactApi";
import { CheckCircle } from '@mui/icons-material';
import { green } from "@mui/material/colors";

export default function ContactSubscribeForm() {
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState("");
    const [subscribed, setSubscribed] = useState(false);

    // Send the contact request to the api.
    const sendContactRequest = async () => {
        setSubmitting(true);

        try {
            if (await Contact({
                email,
                message: "this was a newsletter subscription request",
                subscribe: true,
            })) {
                setSubscribed(true);
            } else {
                // An error occured
                console.error("An error occurred submitting the contact request.")
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: subscribed ? 'none' : '#FFF',
                padding: 1,
                borderRadius: 1,
            }}
        >
            {subscribed ? (
                <div>
                    <CheckCircle
                        sx={{
                            color: green[500],
                            marginBottom: "-5px"
                        }}
                    /> <Typography
                        variant="body1"
                        sx={{
                            color: '#FFF',
                            display: 'inline-block',
                        }}
                    >
                        Subscribed using
                    </Typography> <span style={{color: 'rgba(255,255,255,0.5)'}}>{email}</span>
                </div>
            ) : (
                <div>
                    <div>
                        <TextField
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            v-model="email"
                            placeholder="Enter your email"
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            sx={{
                                marginBottom: 1,
                                input: {
                                    textAlign: 'center',
                                    fontSize: '0.8rem',
                                },
                            }}
                        />
                        <Button
                            onClick={sendContactRequest}
                            disabled={submitting}
                            fullWidth={true}
                            sx={{
                                backgroundColor: "darkAugment.main",
                                fontWeight: 'bold',
                                color: '#FFF',
                                '&:hover': {
                                    backgroundColor: "darkAugment.dark",
                                    cursor: 'pointer',
                                }
                            }}
                        >
                            {submitting ? (
                                <CircularProgress
                                    color="inherit"
                                    size={24}
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            ) : "Subscribe"}
                        </Button>
                    </div>
                </div>
            )}
        </Box>
    );
}