import { ExitToApp, Person } from '@mui/icons-material';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { SignOut, SignIn } from '../../utils/auth';
import { selectSignedIn } from '../global/globalsSlice';

export default function LoginButton() {
    const signedIn = useSelector(selectSignedIn);
    const text = signedIn ? 'Sign Out' : 'Sign In';
    const onClick = () => {
        if (signedIn) {
            SignOut();
        } else {
            SignIn();
        }
    }

    // https://gist.github.com/mrichman/45389684d4c9ea40240f362cea99302f
    return (
        <Box
            onClick={onClick}
        >
            <Typography
                variant="body2"
                fontWeight="bold"
                sx={{
                    cursor: 'pointer',
                }}
            >
                <SignInOutButton
                    signedIn={signedIn}
                    sx={{
                        marginBottom: '-3px',
                        marginRight: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                    }}
                />
                {text}
            </Typography>
        </Box>
    );
}

const SignInOutButton = ({ signedIn, sx }: { signedIn: boolean, sx?: SxProps<Theme> | undefined }) => {
    if (!signedIn) {
        return <Person
            sx={{ ...sx }}
        />
    }

    return <ExitToApp
        sx={{ ...sx }}
    />
}
