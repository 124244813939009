import { Box, Stepper, Step, StepLabel, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ValidationError } from "../../../@types/generic";
import { GetPropertyPageLink } from "../../../routes/RouteLinkHelpers";
import { useAppDispatch } from "../../../store/hooks";
import SectionContainedBody from "../../../views/layouts/sections/SectionContainedBody";
import { selectCurrentUser } from "../../global/globalsSlice";
import { createPropertyAsync } from "../propertiesSlice";
import PropertyCreateWizardContent from "./PropertyCreateWizardContentWrapper";
import { green } from "@mui/material/colors";
import * as Yup from 'yup';
import { Property, PropertyStatus } from "../../../@types/property";

const steps = ['Address', 'Map Location'];

const propertyCreateSchema = Yup.object().shape({
    subpremise: Yup.string()        
        .max(50, 'maximum of 50 characters')
        .optional(),
    street: Yup.string()
        .min(3, 'minimum of 3 characters')
        .max(50, 'maximum of 50 characters'),
    suburb: Yup.string()
        .min(3, 'minimum of 3 characters')
        .max(50, 'maximum of 50 characters'),
    state: Yup.string()
        .min(3, 'minimum of 3 characters')
        .max(3, 'maximum of 3 characters')
        .uppercase('should be uppercase')
        .required(),
    postcode: Yup.number()
        .test('len', 'maximum of 5 numbers', (val: any) => val.toString().length <= 5)
        .test('len', 'minimum of 4 numbers', (val: any) => val.toString().length >= 4)
        .required(),
})

export default function PropertyCreateWizard() {
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<Property>({
        status: PropertyStatus.ACTIVE,
    } as Property);
    const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [submitting, setSubmitting] = useState(false);
    const currentUser = useSelector(selectCurrentUser);
    let navigate = useNavigate();

    const submit = async () => {
        setSubmitting(true);

        try {
            // Ensure that the form values are valid before trying to submit.
            if (await validateForm(formData)) {
                var resp = (await dispatch(createPropertyAsync(formData))) as any;
                if (resp?.payload) {
                    navigate(GetPropertyPageLink(resp?.payload));
                }
            }
        } finally {
            setSubmitting(false);
        }
    }

    const validateForm = async (data: Property) => {
        try {
            await propertyCreateSchema.validate(data, { abortEarly: false });
        } catch (e: any) {
            if (e.name === "ValidationError") {
                // If we have any validation errors we abort early.
                if (e.inner?.length) {
                    setValidationErrors(e.inner);
                    return false;
                }
            }
        }

        // We've made it this far, should be fine to reset any validation
        // errors.s
        setValidationErrors([]);

        return true;
    }


    const formValueChanged = async (data: Property) => {
        setFormData(data);
        await validateForm(data);
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{
                maxWidth: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginY: 3,
            }}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <SectionContainedBody>
                <Box sx={{
                    padding: 1,
                    position: 'relative',
                }}>
                    <PropertyCreateWizardContent
                        activeStep={activeStep}
                        setFormData={formValueChanged}
                        property={formData}
                        submitting={submitting}
                        submit={submit}
                        currentUser={currentUser}
                        validationErrors={validationErrors}
                    />
                    <BackAndNextButtonsFragment
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        submitting={submitting}
                        submit={submit}
                        submitDisabled={!!validationErrors?.length || submitting}
                    />
                </Box>
            </SectionContainedBody>
        </Box>
    );
}

interface IBackAndNextButtonProps {
    activeStep: number,
    handleBack: () => void,
    handleNext: () => void,
    submit: () => void,
    submitting: boolean,
    submitDisabled: boolean,
}

function BackAndNextButtonsFragment({ activeStep, handleBack, handleNext, submitting, submit, submitDisabled }: IBackAndNextButtonProps) {
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginTop: 3 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant="contained"
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {
                    (activeStep === steps.length - 1) ? (
                        <Button onClick={submit} variant="contained" disabled={submitDisabled}>
                            Create
                        </Button>
                    ) : (
                        <Button onClick={handleNext} variant="contained">
                            Next
                        </Button>
                    )
                }
                {submitting && (
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                            position: 'absolute',
                            borderRadius: 1,
                        }}>
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    </Box>
                )}
            </Box>
        </React.Fragment>
    )
}
