// import { useState } from "react";
// import { Contact } from "../../components/contact/contactApi";

import { Box, Grid, Typography } from "@mui/material";
import {
    Link as RouterLink,
} from "react-router-dom";
import ContactSubscribeForm from "../../components/contact/ContactSubscribeForm";

export default function Footer() {
    return (
        <Box
            sx={{
                backgroundColor: 'darkAugment.main',
                width: '100%',
            }}
            padding={4}
            flexDirection="column"
            alignItems="center"
            display="flex"
        >
            <Box
                maxWidth="md"
                display="flex"
                marginBottom={4}
            >
                <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    color="#FFF"
                    maxWidth="250px"
                    textAlign="center"
                >
                    Join the feed to get the latest news and updates.
                </Typography>
            </Box>

            <ContactSubscribeForm />

            <Grid
                container
                maxWidth="md"
                color="#FFF"
                paddingTop={6}
            >
                <Grid item xs={6}>
                    <Typography
                        fontFamily="Lobster, cursive"
                        color="white"
                        fontSize="24px"
                    >
                        RealtyLogs
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Typography
                        component={RouterLink}
                        to="/properties"
                        textAlign="center"
                        sx={{
                            textDecoration: 'none',
                            color: 'white'
                        }}
                    >
                        Properties
                    </Typography>
                    <Typography
                        component={RouterLink}
                        to="/pricing"
                        textAlign="center"
                        sx={{
                            textDecoration: 'none',
                            color: 'white',
                            marginLeft: 2,
                        }}
                    >
                        Pricing
                    </Typography>
                    <Typography
                        component={RouterLink}
                        to="/contact"
                        textAlign="center"
                        sx={{
                            textDecoration: 'none',
                            color: 'white',
                            marginLeft: 2,
                        }}
                    >
                        Contact
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}


