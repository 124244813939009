import { useState } from 'react';
import { useDocTitle } from '../../routes/Hooks';
import { useParams } from 'react-router-dom';
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';
import { IUseFetchPropertiesPageHookProps, useFetchPropertiesPageHook } from '../../components/properties/hooks';
import { Property } from '../../@types/property';
import { EntityType } from '../../@types/generic';
import PropertyCollections from '../../components/properties/PropertyCollections';
import { CloudUpload } from '@mui/icons-material';
import { Avatar, Button, Container, Divider, Grid, Typography, useTheme } from '@mui/material';
import { RootState } from '../../store/root';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { selectCurrentUser } from '../../components/global/globalsSlice';
import { selectAttachmentById } from '../../components/attachments/attachmentsSlice';
import AttachmentCreateListItemImageForEntityForm from '../../components/attachments/AttachmentCreateListItemImageForEntityForm';
import { PROPERTY_DEFAULT_LIST_ITEM_IMAGE } from '../../components/properties/propertyConstants';
import LoaderSkeletonGrid from '../../components/loaders/LoaderSkeletonGrid';
import {
    Link as RouterLink,
} from 'react-router-dom';
import { GetCollectionCreatePageLink } from '../../routes/RouteLinkHelpers';
import PropertyDisplayHeader from '../../components/properties/PropertyDisplayHeader';
import { GetPropertyAddress } from '../../components/properties/propertyUtils';
import BreadcrumbsWrappers from '../../components/breadcrumbs/BreadcrumbsWrapper';
import { GetPropertiesBreadcrumb } from '../../components/breadcrumbs/BreadcrumbUtils';
import { DateTime } from "luxon";
import { SignIn } from '../../utils/auth';
import PropertyPostcodeCollections from '../../components/properties/PropertyPostcodeCollections';


export default function RoutedViewProperty() {
    const { id } = useParams();

    return <ViewProperty id={Number(id)} />
}


export function ViewProperty({
    id
}: {
    id: number,
}) {
    useDocTitle("Property");

    const [currentSearchValues] = useState<IUseFetchPropertiesPageHookProps>({
        page_number: 1,
        page_size: 1,
        min_page_number_to_fetch: 1,
        filter: {
            property_ids: [id],
        },
        include_images: true,
    });

    const { fetching: fetchingProperties, allResultsSet: properties } = useFetchPropertiesPageHook({
        ...currentSearchValues
    });

    const loading = fetchingProperties;

    return (
        <LayoutMaxWidthContent loading={false}>
            <div>
                <>
                    {loading ? <MainSkeletonLoader /> : (
                        properties?.length ?
                            <PropertyDisplay property={properties[0]} /> :
                            <div>Property not found</div>
                    )}
                </>
            </div>
        </LayoutMaxWidthContent>
    )
}

const MainSkeletonLoader = () => {
    return (
        <div>
            <Grid container>
                <LoaderSkeletonGrid
                    height={300}
                    gridSizes={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    }}
                    numberOfElements={1}
                />
            </Grid>

            <Box sx={{ mt: 3 }} />

            <Grid container spacing={3}>
                <LoaderSkeletonGrid
                    height={120}
                    gridSizes={{
                        xs: 12,
                    }}
                    numberOfElements={1}
                />
            </Grid>

            <Box sx={{ mt: 3 }} />

            <Grid container spacing={3}>
                <LoaderSkeletonGrid
                    height={120}
                    gridSizes={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                        lg: 3,
                    }}
                    numberOfElements={8}
                />
            </Grid>
        </div>
    )
}

const collectionWrapperStyles = {
    marginTop: 3,
    backgroundColor: '#EFEFEF',
    padding: 4,
    borderRadius: 1,
}

const PropertyDisplay = ({ property }: { property: Property }) => {
    const theme = useTheme();
    const currentUser = useSelector(selectCurrentUser);
    const [listItemImageOpen, setListItemImageOpen] = useState(false);
    const listItemImage = useSelector((store: RootState) => selectAttachmentById(store, property.list_attachment_id));

    return (
        <div>
            {/* Header image etc */}
            <PropertyDisplayHeader propertyId={property.id} />

            <Container
                maxWidth="lg"
                sx={{
                    marginTop: 3,
                }}
            >
                <Grid container>

                    <Grid item xs={12}>
                        <Box sx={{
                            padding: 3,
                            paddingBottom: 0,
                        }}>
                            <BreadcrumbsWrappers breadcrumbs={[GetPropertiesBreadcrumb()]} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8} sx={{
                        paddingY: 3,
                    }}>
                        <div
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                            }}
                        >
                            <Avatar
                                alt={property?.street || "List item image"}
                                src={listItemImage?.url || PROPERTY_DEFAULT_LIST_ITEM_IMAGE}
                                variant="rounded"
                                sx={{
                                    width: 75,
                                    height: 75,
                                    marginRight: 3,
                                }}
                            />

                            <CloudUpload
                                sx={{
                                    borderColor: 'white',
                                    color: 'white',
                                    marginRight: 1,
                                    ...(currentUser?.id !== property?.created_by ?
                                        { display: 'none' } : {}
                                    ),
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 25,
                                }}
                                onClick={() => setListItemImageOpen(true)}
                            />
                        </div>

                        <div style={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}>
                            <Typography
                                variant="h1"
                                fontSize="18px"
                                textOverflow="ellipsis"
                                noWrap={true}
                            >
                                {GetPropertyAddress(property)}
                            </Typography>
                            <Typography
                                variant="caption"
                                display="inline-block"
                                paragraph={true}
                            >
                                Created {DateTime.fromISO(property.created_at.toString()).toRelative()}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box hidden={!property.lng} sx={{
                            borderRadius: 1,
                            overflow: 'hidden',
                            border: 1,
                            borderColor: '#CCC',
                            textAlign: 'center',
                            backgroundColor: '#CCC',
                            verticalAlign: 'middle',
                        }}>
                            {/* https://developers.google.com/maps/documentation/maps-static/start */}
                            <a
                                href={`https://maps.google.com/?ll=${property.lat},${property.lng}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    alt="Map location for property"
                                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${property.lat},${property.lng}&zoom=13&size=2000x150&markers=color:red|${property.lat},${property.lng}&key=${process.env.REACT_APP_MAPS_KEY}`}
                                />
                            </a>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{
                            marginY: 2,
                        }} />
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ marginTop: 3, }}>
                        <Typography
                            variant="h2"
                            fontWeight="fontWeightLight"
                            fontSize="h5.fontSize"
                        >
                            Document collections
                        </Typography>
                        <Typography
                            variant="body2"
                        >
                            Download collections of files and links that have been uploaded by our community members.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <div style={{ textAlign: 'right', margin: theme.spacing(3) }}>
                            {currentUser?.id ? (
                                <Button
                                    component={RouterLink}
                                    to={GetCollectionCreatePageLink(property)}
                                    variant="contained"
                                    target="_self"
                                >
                                    Add a Collection
                                </Button>
                            ) : (
                                <Button
                                    onClick={SignIn}
                                    variant="contained"
                                >
                                    Login to Add a Collection
                                </Button>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={12} sx={collectionWrapperStyles}>
                        <PropertyCollections propertyId={property.id} />
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ marginTop: 6, }}>
                        <Typography
                            variant="h2"
                            fontWeight="fontWeightLight"
                            fontSize="h5.fontSize"
                        >
                            Postcode collections
                        </Typography>
                        <Typography
                            variant="body2"
                        >
                            View a list of uploaded collections for this property's postcode ({property.postcode}).
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={collectionWrapperStyles}>
                        <PropertyPostcodeCollections postcode={property.postcode} />
                    </Grid>
                </Grid>

                <AttachmentCreateListItemImageForEntityForm
                    open={listItemImageOpen}
                    entityId={property.id}
                    entityType={EntityType.Property}
                    onSave={() => setListItemImageOpen(false)}
                    onCancel={() => setListItemImageOpen(false)}
                />
            </Container >
        </div >
    )
}