import { Button, Divider, FormControl, Grid, IconButton, InputBase, MenuItem, Paper, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { IListPropertiesFilter, STATES } from '../../@types/property';
import FormDialog from '../form/FormDialog';
import { SentenceCaseEnum } from '../../utils/generic';

interface IPropertySearchFormProps {
    onSubmit: (values: IListPropertiesFilter) => void;
    defaultValues: IListPropertiesFilter,
}

export default function PropertySearchForm({
    onSubmit,
    defaultValues
}: IPropertySearchFormProps) {
    const [currentSearchValues, setCurrentSearchValues] = useState<IListPropertiesFilter>(defaultValues);
    const [searchMenuOpen, setSearchMenuOpen] = useState(false);

    const setSuburbOrPostcode = (e: any) => {
        const val = e?.target?.value || "";

        // Is nothing useful, so probably just a form reset.
        if (!val?.length) {
            setCurrentSearchValues({ ...currentSearchValues, suburb: "", postcode: "" });
            return;
        }

        // Not a number, so we assume it has to be a suburb.
        if (isNaN(val.trim())) {
            setCurrentSearchValues({ ...currentSearchValues, suburb: val.trim(), postcode: '' });
            return;
        }

        // Is a number, so we'll assume it's a postcode.
        setCurrentSearchValues({ ...currentSearchValues, postcode: val.trim(), suburb: '' });
    }

    return (
        <>
            <Paper
                component="form"
                sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: {
                        xs: 300,
                        sm: 500,
                    },
                    height: 50,
                }}
            >
                <IconButton
                    sx={{ p: '10px' }}
                    aria-label="menu"
                    onClick={() => setSearchMenuOpen(true)}
                >
                    <MoreIcon />
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search suburb or postcode"
                    inputProps={{
                        'aria-label': 'Search suburbs',
                    }}
                    onChange={setSuburbOrPostcode}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                    sx={{ p: '10px' }}
                    aria-label="directions"
                    onClick={() => onSubmit(currentSearchValues)}
                >
                    <SearchIcon />
                </IconButton>
                <SearchMenu
                    open={searchMenuOpen}
                    onClose={() => setSearchMenuOpen(false)}
                    initialValues={currentSearchValues}
                    onChange={(values) => {
                        setCurrentSearchValues(values);
                        onSubmit(values);
                    }}
                />
            </Paper>
        </>
    )
}

interface ISearchMenuProps {
    onChange: (values: IListPropertiesFilter) => void;
    open: boolean;
    onClose: () => void;
    initialValues: IListPropertiesFilter;
}

function SearchMenu({ onChange, open, onClose, initialValues }: ISearchMenuProps) {
    const [currentSearchValues, setCurrentSearchValues] = useState<IListPropertiesFilter>(initialValues);

    return (
        <FormDialog
            title="Search Filters"
            onClose={onClose}
            open={open}
        >
            <Grid
                container
                spacing={4}
                sx={{
                    maxWidth: 300,
                }}
            >
                <Grid item xs={12}>
                    <TextField
                        label="Street"
                        defaultValue={currentSearchValues.street}
                        fullWidth
                        onChange={(e) => setCurrentSearchValues({ ...currentSearchValues, street: e.target.value })}
                    // InputProps={{
                    //     startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    // }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Street Number"
                        defaultValue={currentSearchValues.street_number}
                        fullWidth
                        onChange={(e) => setCurrentSearchValues({ ...currentSearchValues, street_number: e.target.value })}
                    // InputProps={{
                    //     startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    // }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Subpremise"
                        defaultValue={currentSearchValues.subpremise}
                        fullWidth
                        onChange={(e) => setCurrentSearchValues({ ...currentSearchValues, subpremise: e.target.value })}
                    // InputProps={{
                    //     startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    // }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            id="txt-state"
                            select
                            label="State"
                            onChange={(e) => setCurrentSearchValues({ ...currentSearchValues, state: e.target.value })}
                            defaultValue=""
                        >
                            {
                                Object.entries(STATES).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>{SentenceCaseEnum(key)}</MenuItem>
                                ))
                            }
                        </TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Country"
                        id="outlined-start-adornment"
                        defaultValue={currentSearchValues.country}
                        onChange={(e) => setCurrentSearchValues({ ...currentSearchValues, country: e.target.value })}
                        fullWidth
                    // InputProps={{
                    //     startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    // }}
                    />

                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            onChange(currentSearchValues);
                            onClose();
                        }}
                    >
                        Apply
                    </Button>
                    <Button
                        fullWidth
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            setCurrentSearchValues({});
                            onChange({});
                            onClose();
                        }}
                        sx={{ my: 1 }}
                    >
                        Clear Filters
                    </Button>
                    <Button
                        fullWidth
                        color="primary"
                        onClick={() => {
                            onChange({});
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>

        </FormDialog>
    )
}
