import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { selectCurrentUser } from '../components/global/globalsSlice';
import { useFetchMembershipsByAppUserIdIfNeeded } from '../components/memberships/hooks';
import { RootState } from '../store/root';
import ViewAccount from '../views/account/ViewAccount';
import RoutedViewCollection from '../views/collections/ViewCollection';
import RoutedViewCollectionCreate from '../views/collections/ViewCollectionCreate';
import ViewContact from "../views/contact/ViewContact";
import ViewHome from "../views/home/ViewHome";
import ViewPricing from '../views/pricing/ViewPricing';
import ViewProperties from "../views/properties/ViewProperties";
import RoutedViewProperty from "../views/properties/ViewProperty";
import RoutedViewPropertyCreate from '../views/properties/ViewPropertyCreate';


export default function RouteTree({ loading }: { loading: boolean }) {
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const currentUserId = currentUser?.id;
    useFetchMembershipsByAppUserIdIfNeeded([currentUserId || 0]);

    return (
        <Routes>
            <Route path="/">
                <Route index element={<ViewHome />} />
                <Route path="contact" element={<ViewContact />} />
                <Route path="properties">
                    <Route path={`create`} element={<RoutedViewPropertyCreate />} />
                    <Route index element={<ViewProperties />} />
                    <Route path={`:addressSlug/:id`} element={<RoutedViewProperty />} />
                </Route>
                <Route path="pricing">
                    <Route index element={<ViewPricing />} />
                </Route>
                <Route path="collections">
                    <Route path={`:propertySlug/:propertyId/create`} element={<RoutedViewCollectionCreate />} />
                    <Route path={`:collectionSlug/:id`} element={<RoutedViewCollection />} />
                </Route>
                <Route path="account">
                    <Route index element={<ViewAccount />} />
                </Route>
                {/* <Route path="topics">
                    <Route path={`:siteName`} element={<RoutedViewSite />} />
                    <Route path={`:siteName/:questionId/:questionTitle`} element={<RoutedViewQuestion />} />
                    <Route path="" element={<ViewSitesList />} />
                </Route> */}
                {/* <Route path="widgets">
              <Route path={`${EnumWidgetId.AUSTRALIAN_COMPANY_NUMBER_GENERATOR}/*`} element={<ViewAustralianCompanyNumberGenerator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_COMPANY_NUMBER_VALIDATOR}/*`} element={<ViewAustralianCompanyNumberValidator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_TAX_FILE_NUMBER_GENERATOR}/*`} element={<ViewAustralianTaxFileNumberGenerator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_TAX_FILE_NUMBER_VALIDATOR}/*`} element={<ViewAustralianTaxFileNumberValidator />} />
              <Route path={`${EnumWidgetId.NEW_ZEALAND_IRD_GENERATOR}/*`} element={<ViewNewZealandIRDGenerator />} />
              <Route path={`${EnumWidgetId.NEW_ZEALAND_IRD_VALIDATOR}/*`} element={<ViewNewZealandIRDValidator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_BUSINESS_NUMBER_GENERATOR}/*`} element={<ViewAustralianBusinessNumberGenerator />} />
              <Route path={`${EnumWidgetId.AUSTRALIAN_BUSINESS_NUMBER_VALIDATOR}/*`} element={<ViewAustralianBusinessNumberValidator />} />
              <Route path={``} element={<ViewWidgetsList />} />
              {/* <Route path="new" element={<NewTeamForm />} />
              <Route index element={<LeagueStandings />} /> */}
                {/* </Route> */}
            </Route>
        </Routes>
    )
}