import { IListCollectionPostcodes, CollectionPostcode } from "../../@types/collection_postcode";
import { GetApiUrl, GetIdToken, HandleApiResponse } from "../../utils/api_utils";


export async function List(request: IListCollectionPostcodes): Promise<CollectionPostcode[]> {
    try {
        const resp = await fetch(`${GetApiUrl()}/collection_postcodes`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Delete(collectionPostcodeID: number): Promise<CollectionPostcode> {
    try {
        const resp = await fetch(`${GetApiUrl()}/collection_postcodes?collection_postcode_id=${collectionPostcodeID}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Create(request: { collection_id: number, postcodes: string[] }): Promise<Array<CollectionPostcode>> {
    try {
        const resp = await fetch(`${GetApiUrl()}/collection_postcodes`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const exports = {
    List,
    Delete,
    Create,
}

export default exports;