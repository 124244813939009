import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Collection, CollectionStatus } from "../../@types/collection";
import { GetCollectionPageLink } from "../../routes/RouteLinkHelpers";
import { useAppDispatch } from "../../store/hooks";
import { createCollectionAsync } from "./collectionsSlice";


export interface ICollectionEditFormProps {
    collection: Collection;
    onSave: (success: boolean) => void;
    onCancel: () => void;
}

export default function CollectionEditForm({ collection, onSave, onCancel }: ICollectionEditFormProps) {
    const [formData, setFormData] = useState<Collection>({ ...collection, status: CollectionStatus.INACTIVE });
    const dispatch = useAppDispatch();
    const [submitting, setSubmitting] = useState(false);
    let navigate = useNavigate();

    const submit = async () => {
        setSubmitting(true);

        try {
            var resp = (await dispatch(createCollectionAsync(formData))) as any;
            if (resp?.payload) {
                onSave(true);
                navigate(GetCollectionPageLink(resp?.payload));
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div>
            <TextField
                id="txt-name"
                label="Name"
                variant="outlined"
                defaultValue={collection.name}
                placeholder="e.g. Property Inspection Report July 2022"
                onChange={(e: any) => setFormData({ ...formData, name: e?.target?.value })}
            />

            <TextField
                id="txt-description"
                label="Description"
                variant="outlined"
                defaultValue={collection.description}
                placeholder="e.g. This is a 25 page report which includes detailed images of the property..."
                onChange={(e: any) => setFormData({ ...formData, description: e?.target?.value })}
            />

            <TextField
                id="txt-unlock-fee"
                label="Unlock Price"
                variant="outlined"
                defaultValue={collection.unlock_fee}
                placeholder="20.00"
                onChange={(e: any) => setFormData({ ...formData, unlock_fee: Number(e?.target?.value) })}
            />

            <Box
                textAlign="right"
                marginTop={2}
            >
                <Button
                    className="mr-3"
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                >
                    {submitting ? "Updating..." : "Update"}
                </Button>
            </Box>
        </div>
    );
}