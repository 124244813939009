import { Grid, GridSize } from "@mui/material";
import LoaderSkeletonCustom from "./LoaderSkeletonCustom";

export default function LoaderSkeletonGrid({
    gridSizes,
    numberOfElements,
    height,
}: {
    gridSizes: {
        xs?: boolean | GridSize,
        sm?: boolean | GridSize,
        md?: boolean | GridSize,
        lg?: boolean | GridSize,
        xl?: boolean | GridSize,
    },
    numberOfElements: number,
    height: string | number | undefined,
}) {
    // We need to return items only so that container can set appropriate spacing.
    return (
        <>
            {
                Array(numberOfElements).fill(0).map((_, rowIndex) => (
                    <Grid
                        item
                        key={`skel-${rowIndex}`}
                        {...gridSizes}
                        height={{ height: height }}
                    >
                        <LoaderSkeletonCustom />
                    </Grid>
                ))
            }
        </>
    );
}
