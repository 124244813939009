import { FolderCopy } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Typography, CardActions, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppUser } from "../../@types/app_user";
import { CollectionAttachment } from "../../@types/collection_attachment";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/root";
import NoSearchResultsFound from "../../views/layouts/placeholders/NoSearchResultsFound";
import AttachmentFileSizeChip from "../attachments/AttachmentFileSizeChip";
import { getAttachmentDownloadUrlAsync, selectAttachmentById } from "../attachments/attachmentsSlice";
import AttachmentLockedChip from "../attachments/AttachmentLockedChip";

export interface ICollectionAttachmentsDisplayProps {
    collectionAttachments: Array<CollectionAttachment>;
    showDownloadLink: boolean;
    currentUser?: AppUser;
}

export default function CollectionAttachmentsDisplay({ collectionAttachments, currentUser, showDownloadLink }: ICollectionAttachmentsDisplayProps) {
    return (
        <Grid container spacing={3}>
            {collectionAttachments.map(collectionAttachment => {
                return (
                    <Grid item key={collectionAttachment.id} xs={12} sm={6} md={4} xl={3}>
                        <CollectionAttachmentListItem collectionAttachment={collectionAttachment} showDownloadLink={showDownloadLink} />
                    </Grid>
                );
            })}
            {collectionAttachments.length === 0 && (
                <Grid item xs={12}>
                    <NoSearchResultsFound
                        icon={FolderCopy}
                        title="Empty Collection"
                        description="No documents have been uploaded yet."
                    />
                </Grid>
            )}
        </Grid>
    )
}


const CollectionAttachmentListItem = ({
    collectionAttachment,
    showDownloadLink,
}: {
    collectionAttachment: CollectionAttachment,
    showDownloadLink: boolean,
}) => {
    const attachment = useSelector((store: RootState) => selectAttachmentById(store, collectionAttachment.attachment_id));
    const [loadingDownload, setLoadingDownload] = useState(false);
    const dispatch = useAppDispatch();

    const downloadAttachment = async () => {
        setLoadingDownload(true);

        try {
            let resp = await dispatch(getAttachmentDownloadUrlAsync(collectionAttachment.attachment_id)) as any;
            if (resp?.payload?.download_url) {
                window.open(resp?.payload?.download_url as string, '_blank', 'noopener,noreferrer');
            }
        } finally {
            setLoadingDownload(false);
        }
    }

    if (collectionAttachment == null || attachment == null) {
        return null
    }

    return (
        <div>
            <Card>
                <CardActionArea>
                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            textOverflow="ellipsis"
                            noWrap={true}
                        >
                            {attachment.name || (<i>No name</i>)}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            textOverflow="ellipsis"
                            noWrap={true}
                        >
                            {attachment.caption || (<i>No description</i>)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Box
                            sx={{
                                display: 'inline-block',
                                mr: 'auto',
                            }}
                        >
                            <AttachmentFileSizeChip attachment={attachment} />
                        </Box>
                        <Box
                            sx={{
                                display: 'inline-block',
                                ml: 'auto',
                            }}
                        >
                            {showDownloadLink ? (
                                <Button
                                    onClick={downloadAttachment}
                                    disabled={loadingDownload}
                                    variant="outlined"
                                >
                                    Download
                                </Button>
                            ) : (
                                <AttachmentLockedChip />
                            )}
                        </Box>
                    </CardActions>
                </CardActionArea>
            </Card>
        </div>
    )
}
