import { ValidationError } from "../../@types/generic"

export function FieldHelperTextAndValidationErrorState(
    validationErrors: ValidationError[],
    defaultHelperText: string,
    fieldName: string,
) {

    const validationError = validationErrors?.find(x => x.path === fieldName)?.errors?.[0];

    return{
        helperText: validationError || defaultHelperText,
        error: !!validationError,
    };
}