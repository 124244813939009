import ContactForm from '../../components/contact/ContactForm';
import { useDocTitle } from '../../routes/Hooks';
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';
import ParticlesHeader from '../layouts/sections/ParticlesHeader';
import { Box, Container, Grid, Typography } from '@mui/material';


export default function ViewAustralianCompanyNumberGenerator() {
    useDocTitle("Contact Us - Send a message to TesterWidgets.com");

    return (
        <LayoutMaxWidthContent loading={false}>
            <ParticlesHeader
                title="Contact Us"
                description="Reach out to us if you have any questions, requests or issues."
            />

            <Container maxWidth="sm">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{
                            textAlign: 'center',
                            paddingTop: 3,
                        }}>
                            <Typography variant="h2" fontWeight="fontWeightMedium" fontSize="h4.fontSize">
                                Send us a message
                            </Typography>
                            <Typography
                                variant="body1"
                                fontWeight="fontWeightLight"
                                sx={{
                                    paddingX: 3,
                                    maxWidth: {
                                        xs: '100%',
                                        xl: '750px',
                                    },
                                }}
                            >
                                You can reach us at any time using the form below. We will get back to you as soon as possible.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <ContactForm />
                    </Grid>
                </Grid>
            </Container>
        </LayoutMaxWidthContent>
    )
}