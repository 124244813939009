import { useEffect, useState } from "react";
import { IListProperties, Property } from "../../@types/property";
import { useAppDispatch } from "../../store/hooks";
import { getAttachmentsByIdIfNeededAsync } from "../attachments/attachmentsSlice";
import { getPropertiesByIdIfNeededAsync, listPropertiesAsync } from "./propertiesSlice";

export interface IUseFetchPropertiesPageHookProps extends IListProperties {
    min_page_number_to_fetch: number;
    include_images: boolean,
}

export const useFetchPropertiesPageHook = ({ page_number, include_images, min_page_number_to_fetch, page_size, filter }: IUseFetchPropertiesPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<Property[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<Property[]>([]);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(listPropertiesAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var properties = resp.payload as Property[];
                setLastResultSet(properties || []);

                if (properties?.length) {
                    setMorePages(properties.length >= Number(page_size))

                    if (include_images) {
                        await dispatch(getAttachmentsByIdIfNeededAsync(properties.reduce<number[]>((acc, prop) => {
                            return [...acc, prop.cover_attachment_id, prop.list_attachment_id];
                        }, [])))
                    }
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter, include_images]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.id === x.id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        resetResultSets: () => {
            setAllResultsSet([]);
            setLastResultSet([]);
        },
        allResultsSet,
    }
}


// useFetchPropertyByIdIfNeededHook fetches properties by id if they are not already in the store.
export const useFetchPropertyByIdIfNeededHook = (propertyId: number) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (loading || !propertyId) {
            return;
        }

        const getMissingProperties = async () => {
            setLoading(true);
            try {
                await dispatch(getPropertiesByIdIfNeededAsync([propertyId]));
            } finally {
                setLoading(false);
            }
        };

        getMissingProperties();
    }, [propertyId, loading, dispatch]);

    return { loading };
}