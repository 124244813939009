import { Box, CircularProgress } from "@mui/material";

export default function LoaderGeneric({ loading, backgroundColor }: { loading: boolean, backgroundColor?: string }) {
    if(!loading) return null;
    
    return (
        <Box style={{
            backgroundColor: backgroundColor || "rgba(0, 0, 0, 0.2)",
            position: 'absolute',
            left: 0,
            right: 0,
            top: -10,
            bottom: -10,
            borderRadius: 4,
            textAlign: 'center',
            paddingTop: 10,
        }}>
            <CircularProgress hidden={!loading} />
        </Box>
    );
}
