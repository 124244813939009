import { useState } from 'react';
import CollectionSubHeader from '../CollectionSubHeader';
import CollectionEditForm from '../CollectionEditForm';
import PrimaryButton from '../../form/PrimaryButton';
import { Box, Grid } from '@mui/material';
import { ICollectionDisplayProps } from '../CollectionDisplay';
import CollectionManagementTabs from './CollectionManagementTabs';

export default function CollectionManagementDisplay({
    collection,
    currentUser,
    collectionAttachments,
    property,
}: ICollectionDisplayProps) {
    const [showEditForm, setShowEditForm] = useState(false);

    return (
        <Box sx={{
            padding: 3,
        }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            textAlign: 'right',
                        }}
                    >
                        {showEditForm ? (
                            <CollectionEditForm
                                collection={collection}
                                onSave={() => setShowEditForm(false)}
                                onCancel={() => setShowEditForm(false)}
                            />
                        ) : (
                            <PrimaryButton
                                onClick={() => setShowEditForm(true)}
                                label="Edit"
                            />
                        )}
                        &nbsp;
                        
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <CollectionSubHeader
                        collection={collection}
                        currentUser={currentUser}
                        property={property}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CollectionManagementTabs collection={collection} />
                </Grid>
            </Grid>
        </Box>
    );
}