import { MenuItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { SignOut, SignIn } from '../../utils/auth';
import { selectSignedIn } from '../global/globalsSlice';

export default function LoginMenuItem() {
    const signedIn = useSelector(selectSignedIn);
    const text = signedIn ? 'Sign Out' : 'Sign In';
    const onClick = () => {
        if (signedIn) {
            SignOut();
        } else {
            SignIn();
        }
    }

    // https://gist.github.com/mrichman/45389684d4c9ea40240f362cea99302f
    return (
        <MenuItem
            key="about"
            onClick={onClick}
            sx={{
                cursor: 'pointer',
            }}
        >
            <Typography textAlign="center">{text}</Typography>
        </MenuItem>
    );
}