import { Box } from '@mui/material';
import React from 'react';
import LoaderGeneric from '../../components/loaders/LoaderGeneric';
import MessagesDisplay from '../../components/messages/MessagesDisplay';
import Footer from './Footer';
import Header from './Header';
import './logo.css';

const LayoutDefault = ({ children, loading }: { children: React.ReactNode, loading: boolean }) => {
    return (
        <>
            <Header />
            <main style={{ minHeight: '100vh' }}>
                {!loading ? (
                    children
                ) : <div><LoaderGeneric loading={true} /></div>}
            </main>
            <MessagesDisplay />

            <Box sx={{ margin: 4 }} />

            <Footer />
        </>
    )
}

export default LayoutDefault;