import { Cloud } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { Attachment } from "../../@types/attachment";

interface IAttachmentFileSizeChipProps {
    attachment: Attachment;
}

export default function AttachmentFileSizeChip({ attachment }: IAttachmentFileSizeChipProps) {
    return (
        <Chip
            icon={<Cloud />}
            label={attachment.size > 0 ? (attachment.size / 1024 / 1024).toFixed(1) + " MB" : "0 MB"}
            color="primary"
            variant="outlined"
            sx={{
                borderColor: blueGrey[500],
                color: blueGrey[500],
            }}
        />
    )
}