import { Info, LockOpen } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { Collection } from "../../@types/collection";
import AnimationWrapper from "../animations/AnimationWrapper";
import CollectionAttachmentsDisplay from "../collection_attachments/CollectionAttachmentsDisplay";
import { ICollectionDisplayProps } from "./CollectionDisplay";
import CollectionSubHeader from "./CollectionSubHeader";
import CollectionUnlockDialog from "./CollectionUnlockDialog";

export default function CollectionDisplayDefault({
    collection,
    currentUser,
    collectionAttachments,
    property,
    showDownloadLink,
}: ICollectionDisplayProps) {
    return (
        <Box sx={{
            padding: 3,
        }}>
            <Grid container>
                <Grid item xs={12}>
                    <CollectionSubHeader collection={collection} currentUser={currentUser} property={property} />
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{
                        position: 'relative',
                    }}>
                        <UnlockOverlay
                            collection={collection}
                            showDownloadLink={showDownloadLink}
                        />
                        <CollectionAttachmentsDisplay
                            collectionAttachments={collectionAttachments}
                            showDownloadLink={showDownloadLink}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}


function UnlockOverlay({ collection, showDownloadLink }: { collection: Collection, showDownloadLink: Boolean }) {
    const [showUnlockModal, setShowUnlockModal] = useState(false);

    // TODO: This needs to say download for when current user hasn't unlocked it,
    // but should always show unless they actually have a purchased download.
    // if (collection.unlocked_by_user_id !== 0) return null;

    if (showDownloadLink) return null;

    return (
        <AnimationWrapper>
            <div>
                <CollectionUnlockDialog
                    collectionId={collection.id}
                    onClose={() => setShowUnlockModal(false)}
                    onSave={() => setShowUnlockModal(false)}
                    open={showUnlockModal}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        borderRadius: 1,
                        padding: 5,
                        zIndex: 1,
                        textAlign: 'center',
                        minHeight: 200,
                        border: '1px solid rgba(0,0,0,0.1)',
                    }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        sx={{
                            maxWidth: 'sm',
                            paddingY: 2,
                        }}
                        onClick={() => setShowUnlockModal(true)}
                    >
                        <LockOpen />&nbsp;&nbsp;Download this Collection
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        color="info"
                        fullWidth={true}
                        sx={{
                            maxWidth: 'sm',
                            paddingY: 2,
                            mt: 2,
                            backgroundColor: '#CCC',
                            borderColor: 'rgba(0,0,0,0.3)',
                            color: 'rgba(0,0,0,0.6)',
                            '&:hover': {
                                backgroundColor: '#BBB',
                                borderColor: 'rgba(0,0,0,0.2)',
                            },
                        }}
                    >
                        <Info />&nbsp;&nbsp;About
                    </Button>
                </Box>
            </div>
        </AnimationWrapper >
    )
}