import { useState } from 'react';
import { useDocTitle } from '../../routes/Hooks';
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';
import { IUseFetchPropertiesPageHookProps, useFetchPropertiesPageHook } from '../../components/properties/hooks';
import FormDialog from '../../components/form/FormDialog';
import PropertyCreateForm from '../../components/properties/PropertyCreateForm';
import { IListPropertiesFilter, Property } from '../../@types/property';
import PropertyListItem from '../../components/properties/PropertyListItem';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import PrimaryButton from '../../components/form/PrimaryButton';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../components/global/globalsSlice';
import ParticlesBackground from '../layouts/ParticlesBackground';
import PropertySearchForm from '../../components/properties/PropertySearchForm';
import PropertySearchFormChips from '../../components/properties/PropertySearchChips';
import LoaderSkeletonGrid from '../../components/loaders/LoaderSkeletonGrid';
import { AppUser } from '../../@types/app_user';
import NoSearchResultsFound from '../layouts/placeholders/NoSearchResultsFound';
import BreadcrumbsWrappers from '../../components/breadcrumbs/BreadcrumbsWrapper';
import {
    Link as RouterLink,
} from 'react-router-dom';
import { GetPropertyCreatePageLink } from '../../routes/RouteLinkHelpers';

const DEFAULT_PAGE_SIZE = 12;
const INITIAL_SEARCH_VALUES = {
    page_number: 1,
    page_size: DEFAULT_PAGE_SIZE,
    min_page_number_to_fetch: 1,
    filter: {
        state: '',
        suburb: '',
        postcode: '',
        street: '',
    },
    include_images: true,
};

export default function ViewProperties() {
    useDocTitle("Properties");

    const currentUser = useSelector(selectCurrentUser);
    const [createFormOpen, setCreateFormOpen] = useState(false);
    const [currentSearchValues, setCurrentSearchValues] = useState<IUseFetchPropertiesPageHookProps>(INITIAL_SEARCH_VALUES);

    const { fetching: fetchingProperties, allResultsSet: properties, morePages, resetResultSets } = useFetchPropertiesPageHook({
        ...currentSearchValues
    });

    const getNextPage = () => {
        setCurrentSearchValues({
            ...currentSearchValues,
            page_number: Number(currentSearchValues?.page_number) + 1,
        });
    }

    const searchWithNewValues = (newValues: IListPropertiesFilter) => {
        // Clear all existing values.
        resetResultSets();

        // Reset search criteria and then apply new values.
        setCurrentSearchValues({
            ...INITIAL_SEARCH_VALUES,
            filter: {
                ...newValues,
            },
            page_number: 1,
        });
    }

    return (
        <LayoutMaxWidthContent loading={false}>
            <Box
                sx={{
                    display: 'grid',
                    position: 'relative',
                    height: {
                        xs: 285,
                    },
                    width: '100vw',
                    pt: 10,
                }}
            >

                <ParticlesBackground />

                <Box
                    sx={{
                        zIndex: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        color: 'white',
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        fontSize="35px"
                        lineHeight="35px"
                        fontWeight="lighter"
                        color="#FFF"
                        gutterBottom={false}
                        textAlign="center"
                    >
                        Find a property
                    </Typography>
                    <Typography
                        variant="caption"
                        fontWeight="lighter"
                        textAlign="center"
                        marginTop={1}
                    >
                        Click the magnifying glass or press enter to search.
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "center",
                        marginTop: 3,
                    }}>
                        <PropertySearchForm
                            onSubmit={searchWithNewValues}
                            defaultValues={currentSearchValues.filter}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: "center",
                            marginTop: 2,
                        }}
                    >

                        <PropertySearchFormChips
                            searchValues={currentSearchValues.filter}
                            setSearchValues={searchWithNewValues}
                        />
                    </Box>
                </Box>
                <FormDialog
                    title="New Property"
                    onClose={() => setCreateFormOpen(false)}
                    open={createFormOpen}
                >
                    <PropertyCreateForm
                        onSave={() => { }}
                        onCancel={() => { }}
                        property={{} as Property}
                    />
                </FormDialog>
            </Box>
            <BodySection
                loading={fetchingProperties}
                morePages={morePages}
                getNextPage={getNextPage}
                properties={properties}
                currentUser={currentUser}
                pageNumber={Number(currentSearchValues.page_number)}
            />
        </LayoutMaxWidthContent>
    )
}

interface IBodySectionProps {
    currentUser?: AppUser,
    properties: Property[],
    morePages: boolean,
    loading: boolean,
    pageNumber: number,
    getNextPage: () => void,
}

const BodySection = ({
    currentUser,
    properties,
    morePages,
    loading,
    getNextPage,
    pageNumber,
}: IBodySectionProps) => {
    return (
        <Container
            maxWidth="lg"
            sx={{
                marginTop: 3,
            }}
        >
            <Grid container>
                <Grid
                    item
                    xs={6}
                >
                    <BreadcrumbsWrappers breadcrumbs={[]} />
                </Grid>
                <Grid
                    item
                    xs={6}
                    textAlign="right"
                    paddingBottom={3}
                    sx={{
                        ...(currentUser ? {} : { display: 'none' })
                    }}
                >
                    <RouterLink
                        to={GetPropertyCreatePageLink()}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <PrimaryButton
                            label="Add Property"
                            onClick={() => { }
                            }
                        />
                    </RouterLink>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={3}
            >
                {properties.map(property => (
                    <Grid
                        item
                        key={property.id}
                        className="border p-10"
                        style={{ marginBottom: '-1px' }}
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <PropertyListItem propertyId={property.id} />
                    </Grid>
                ))}

                {loading && <SkeletonLoader />}
                {!loading && !properties.length && (
                    <Grid item xs={12} textAlign="center">
                        <NoSearchResultsFound
                            title="No properties found"
                            description="You can search for a different property or create a new one."
                        />
                    </Grid>
                )}
            </Grid>

            {
                morePages && !loading ? (
                    <Box
                        sx={{
                            textAlign: 'center',
                            marginTop: 3,
                        }}
                    >
                        <Button
                            onClick={getNextPage}
                            variant="contained"
                            fullWidth
                            sx={{
                                maxWidth: 'sm',
                            }}
                        >More</Button>
                    </Box>
                ) : null
            }
        </Container >
    )
}


const SkeletonLoader = () => {
    return (
        <>
            <LoaderSkeletonGrid
                height={300}
                gridSizes={{
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 4,
                    xl: 4,
                }}
                numberOfElements={Math.round(DEFAULT_PAGE_SIZE / 2)}
            />
        </>
    )
}