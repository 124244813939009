import { Box, styled, Container, Grid, TextField, Switch, alpha, FormControl, FormControlLabel, Typography, Paper, InputLabel, OutlinedInput, InputAdornment } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { AppUser } from "../../../@types/app_user";
import { Collection } from "../../../@types/collection";
import { ValidationError } from "../../../@types/generic";
import { FieldHelperTextAndValidationErrorState } from "../../form/FormUtils";
import { FormValidationErrorsDisplay } from "../../form/FormValidationErrorsDisplay";
import { selectCurrentUser } from "../../global/globalsSlice";
import StepHeader from "./CollectionCreateStepHeader";

export interface IStepProps {
    collection: Collection;
    setFormData: (collection: Collection) => void;
    submitting: boolean;
    currentUser?: AppUser;
    submit: () => void;
    activeStep: number,
    validationErrors: ValidationError[],
}

export function StepDescribe({ collection, setFormData, validationErrors }: IStepProps) {
    return (
        <Box>
            <Container>
                <StepHeader
                    title="Add description"
                    description="Describe the collection that you're creating for other users."
                />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            id="txt-name"
                            label="Name"
                            variant="outlined"
                            placeholder="e.g. Property Inspection Report July 2022"
                            defaultValue={collection.name}
                            onChange={(e: any) => setFormData({ ...collection, name: e?.target?.value })}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...FieldHelperTextAndValidationErrorState(
                                validationErrors,
                                "A name or title for the collection of files you're uploading.",
                                "name"
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="txt-description"
                            label="Description"
                            variant="outlined"
                            multiline={true}
                            rows={5}
                            defaultValue={collection.description}
                            placeholder="e.g. This is a 25 page report which includes detailed images of the property..."
                            onChange={(e: any) => setFormData({ ...collection, description: e?.target?.value })}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...FieldHelperTextAndValidationErrorState(
                                validationErrors,
                                "Detailed information that may be relevant to a potential purchaser.",
                                "description"
                            )}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}


const getLockedInfoCardStyles = (useUnlockedStyles: boolean) => {
    const sharedStyles = {
        padding: 3,
        height: '100%',
    }

    if (useUnlockedStyles) {
        return {
            ...sharedStyles,
            backgroundColor: 'white',
            boxShadow: `${blue[500]} 0px 0px 6px`,
        }
    }

    return {
        ...sharedStyles,
        backgroundColor: 'rgba(0,0,0,0.05)',
        boxShadow: 'none !important',
        opacity: 0.6,
    }
}

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));

export function StepType({ collection, setFormData, }: IStepProps) {
    const currentUser = useSelector(selectCurrentUser);
    const unlocked = collection.unlocked_by_user_id > 0;

    return (
        <Box>
            <Container>
                <StepHeader
                    title="Set the sale type"
                    description="Do you want to sell the collection locked or unlocked?"
                />

                <Grid container spacing={3}>
                    <Grid item xs={12} textAlign="center">
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        onChange={(e: any) => setFormData({ ...collection, unlocked_by_user_id: unlocked ? 0 : (currentUser?.id || 0) })}
                                        checked={unlocked}
                                    />
                                }
                                labelPlacement="top"
                                label={
                                    <Typography variant="subtitle1" fontWeight="light">
                                        Unlocked
                                    </Typography>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper sx={getLockedInfoCardStyles(!unlocked)}>
                            <Typography variant="body1" fontWeight="light">
                                Locked Collection
                            </Typography>
                            <Typography variant="body2" fontWeight="light">
                                Ensures that you get full price for your document before it is downloaded but you miss out on future sales.
                            </Typography>
                            <Typography variant="body2" fontWeight="light">
                                <ul>
                                    <li>Must be unlocked (at full price) before any users can access it.</li>
                                    <li>The first user to pay the unlock price becomes the <i>unlocker</i>.</li>
                                    <li>The creator (you) will receive the payment for the initial unlock fee but future paid downloads will go to the unlocker.</li>
                                </ul>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper sx={getLockedInfoCardStyles(unlocked)}>
                            <Typography variant="body1" fontWeight="light">
                                Unlocked Collection
                            </Typography>
                            <Typography variant="body2" fontWeight="light">
                                Discounted for premium members but you will be the unlocker and potentially receive funds from multiple sales.
                            </Typography>
                            <Typography variant="body2" fontWeight="light">
                                <ul>
                                    <li>Premium members can download at a steep discount.</li>
                                    <li>Non-premium members will still need to pay full price.</li>
                                    <li>You will be the <i>unlocker</i> and receive payments from future downloads.</li>
                                    <li>Cannot be re-locked if set to unlocked.</li>
                                </ul>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}


export function StepFreeOrPaid({ collection, setFormData, validationErrors }: IStepProps) {

    const updateFormData = (e: any) => {
        setFormData({
            ...collection,
            free: !collection.free,

            // We also need to reset values that may no longer be valid.
            unlocked_by_user_id: 0,
            unlock_fee: 0,
        });
    }

    return (
        <Box>
            <Container>
                <StepHeader
                    title="Commission settings"
                    description="Do you want to sell your documents and receive a commission or share them freely?"
                />

                <Grid container spacing={3}>
                    <Grid item xs={12} textAlign="center">
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        onChange={updateFormData}
                                        checked={!collection.free}
                                    />
                                }
                                labelPlacement="top"
                                label={
                                    <Typography variant="subtitle1" fontWeight="light">
                                        Sell for a commission
                                    </Typography>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper sx={getLockedInfoCardStyles(collection.free)}>
                            <Typography variant="body1" fontWeight="light">
                                Free collection
                            </Typography>
                            <Typography variant="body2" fontWeight="light">
                                Members that have signed in will be able to access your documents for free
                            </Typography>
                            <Typography variant="body2" fontWeight="light">
                                <ul>
                                    <li>You will not receive a commission.</li>
                                </ul>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper sx={getLockedInfoCardStyles(!collection.free)}>
                            <Typography variant="body1" fontWeight="light">
                                Sell for a commission
                            </Typography>
                            <Typography variant="body2" fontWeight="light">
                                Users will pay a fee to download your documents.
                            </Typography>
                            <Typography variant="body2" fontWeight="light">
                                <ul>
                                    <li>You'll receive a commission when a user purchases your download.</li>
                                </ul>
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} hidden={!collection.free}>
                        <FormValidationErrorsDisplay validationErrors={validationErrors} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export function StepPrice({ collection, setFormData, validationErrors }: IStepProps) {
    return (
        <Box>
            <Container>
                <StepHeader
                    title="Set the download price"
                    description="How much should the full price of the collection be?"
                />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
                            <OutlinedInput
                                id="filled-adornment-amount"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Amount"
                                onChange={(e: any) => setFormData({ ...collection, unlock_fee: Number(e?.target?.value || 0) })}
                                fullWidth
                                type="number"
                                defaultValue={collection.unlock_fee || 0}
                                {...FieldHelperTextAndValidationErrorState(
                                    validationErrors,
                                    "Amount to be paid when unlocking a document.",
                                    "unlock_fee"
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        {collection.unlocked_by_user_id > 0 ?
                            (
                                <Typography variant="body2" fontWeight="light">
                                    You're creating an <i>unlocked</i> collection. Premium members will be able to download this
                                    collection for $1.00. Non-premium members will need to pay the full amount of ${Number(collection.unlock_fee || 0)?.toFixed(2)}.
                                    You will be listed as the unlocker and will receive a percentage of the fee whenever this
                                    document is purchased.
                                </Typography>
                            ) : (
                                <Typography variant="body2" fontWeight="light">
                                    You're creating a <i>locked</i> collection. It will need to be unlocked for ${Number(collection.unlock_fee || 0)?.toFixed(2)} before
                                    anyone can download it. You will receive a percentage of the full unlock fee but the unlocker will
                                    receive the commission for future sales. Premium members will not be able to access the discounted
                                    document until it is unlocked.
                                </Typography>
                            )}
                    </Grid>

                    <Grid item xs={12}>
                        <FormValidationErrorsDisplay validationErrors={validationErrors} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
