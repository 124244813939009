import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBillingPortalRequest, IListMemberships, Membership } from '../../@types/membership';
import { RootState } from '../../store/root';
import MembershipsApi from './membershipsApi';

export interface MembershipsState {
    byId: {
        [id: string]: Membership;
    },
    fetchedByAppUserId: {
        [app_user_id: string]: null,
    },
}

const initialState: MembershipsState = {
    byId: {},
    fetchedByAppUserId: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const listMembershipsAsync = createAsyncThunk(
    'membershipsGet',
    async (request: IListMemberships) => {
        return await MembershipsApi.List(request);
    }
);

export const getMembershipsByAppUserIdIfNeededAsync = createAsyncThunk(
    'getMembershipsByAppUserIdIfNeeded',
    async (appUserIds: number[], { getState, dispatch }) => {
        const state = getState() as RootState;

        // Ensure that we won't try to double fetch.
        dispatch(membershipsSlice.actions.fetched(appUserIds));

        // Ensure they aren't already there.
        let missingAppUserIds = appUserIds.filter(app_user_id => !state.memberships.byId[app_user_id] && app_user_id);

        // Ensure we haven't already tried to fetch them.
        missingAppUserIds = missingAppUserIds.filter(app_user_id => {
            return !Object.hasOwn(state.memberships.fetchedByAppUserId, app_user_id)
        });

        if (!missingAppUserIds.length) return;

        return await MembershipsApi.List({
            filter: {
                app_user_ids: missingAppUserIds,
            },
        });
    }
);

export const updateMembershipAsync = createAsyncThunk(
    'membershipUpdate',
    async (request: Membership) => {
        return await MembershipsApi.Update(request);
    }
);

export const createMembershipAsync = createAsyncThunk(
    'membershipCreate',
    async (request: Membership) => {
        return await MembershipsApi.Create(request);
    }
);

export const getMembershipBillingPortalLink = createAsyncThunk(
    'membershipBillingportal',
    async (request: IBillingPortalRequest) => {
        return await MembershipsApi.BillingPortal(request);
    }
);

export const membershipsSlice = createSlice({
    name: 'memberships',
    initialState,
    reducers: {
        fetched: (state, action: PayloadAction<Array<number>>) => {
            state.fetchedByAppUserId = action.payload.reduce((acc, app_user_id) => {
                acc[app_user_id] = null;
                return acc;
            }, state.fetchedByAppUserId);
        },
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(listMembershipsAsync.fulfilled, (state: MembershipsState, action) => {
                if(!action?.payload) return;

                state.byId = {
                    ...state.byId,
                    ...action?.payload?.reduce((acc: {
                        [index: string]: Membership,
                    }, item: Membership) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(listMembershipsAsync.rejected, (state) => {
                throw new Error("Need to handle this get membership rejected")
            })
            .addCase(getMembershipsByAppUserIdIfNeededAsync.fulfilled, (state: MembershipsState, action) => {
                if(!action?.payload) return;
                
                state.byId = {
                    ...state.byId,
                    ...action?.payload?.reduce((acc: {
                        [index: string]: Membership,
                    }, item: Membership) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(updateMembershipAsync.fulfilled, (state: MembershipsState, action) => {
                if (action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(updateMembershipAsync.rejected, (state) => {
                throw new Error("error updating membership")
            })
            .addCase(createMembershipAsync.fulfilled, (state: MembershipsState, action) => {
                if (action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(createMembershipAsync.rejected, (state) => {
                throw new Error("error creating question")
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectMembershipById = (state: RootState, membershipId: number) => {
    return (Object.values(state.memberships.byId) as Array<Membership>)
        .filter((item: Membership) => item.id === membershipId)?.[0];
}

export const selectCurrentUserMembership = (state: RootState) => {
    let currentUserId = state.globals.currentUser?.id;
    if (!currentUserId) return null;

    return (Object.values(state.memberships.byId) as Array<Membership>)
        .find((item: Membership) => item.app_user_id === currentUserId);
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = membershipsSlice;

// Extract and export each action creator by name
// export const { getMembershipsAsync }, = actions;

export default reducer;