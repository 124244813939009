// Style this based on: https://dribbble.com/shots/3762116-Create-Channel-Wizard/attachments/3762116?mode=media

import { Grid } from "@mui/material";
import { useDocTitle } from "../../routes/Hooks"
import LayoutMaxWidthContent from "../layouts/LayoutMaxWidthContent";
import ParticlesHeader from "../layouts/sections/ParticlesHeader";
import CollectionCreateWizard from "../../components/collections/CollectionCreateWizard/CollectionCreateWizard";
import { useParams } from "react-router-dom";

export default function RoutedViewCollectionCreate() {
    const { propertyId } = useParams();

    return <ViewCollectionCreate propertyId={Number(propertyId)}/>
}

export function ViewCollectionCreate({
    propertyId
}: { propertyId: number}) {
    useDocTitle("Create a new Collection");

    return (
        <LayoutMaxWidthContent loading={false}>
            <ParticlesHeader
                title="Create a Collection"
                description="Upload and sell a collection of documents that can be purchased by users."
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CollectionCreateWizard propertyId={propertyId}/>
                </Grid>
            </Grid>
        </LayoutMaxWidthContent>
    )
}

