// Style this based on: https://dribbble.com/shots/3762116-Create-Channel-Wizard/attachments/3762116?mode=media

import { Grid } from "@mui/material";
import { useDocTitle } from "../../routes/Hooks"
import LayoutMaxWidthContent from "../layouts/LayoutMaxWidthContent";
import ParticlesHeader from "../layouts/sections/ParticlesHeader";
import PropertyCreateWizard from "../../components/properties/PropertyCreateWizard/PropertyCreateWizard";

export default function RoutedViewPropertyCreate() {
    // const { propertyId } = useParams();

    return <ViewPropertyCreate />
}

export function ViewPropertyCreate() {
    useDocTitle("Create a new property");

    return (
        <LayoutMaxWidthContent loading={false}>
            <ParticlesHeader
                title="Create a Property"
                description="Add a new property so that collections can be created for it."
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PropertyCreateWizard />
                </Grid>
            </Grid>
        </LayoutMaxWidthContent>
    )
}

