import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { red } from "@mui/material/colors";
import { ValidationError } from "../../@types/generic"

export function FormValidationErrorsDisplay({ validationErrors }: { validationErrors: ValidationError[] }) {
    if (!validationErrors?.length) return null;

    return (
        <Box
            sx={{
                backgroundColor: red[500],
                color: '#FFF',
                borderRadius: 1,
                padding: 3,    
            }}
        >
            <Typography variant="body1" sx={{
                fontWeight: 'light',
            }}>Just a few small things to fix up before submitting</Typography>
            {validationErrors.map(x => {
                return <ValidationErrorItem validationError={x} />
            })}
        </Box>
    )
}

export function ValidationErrorItem({ validationError }: { validationError: ValidationError }) {
    return (
        <Box>
            <Typography variant="body2" sx={{
                fontWeight: 'lighter',
            }}><span style={{
                textTransform: 'capitalize',
            }}>&#x2022; {validationError.path}</span>: {validationError.errors?.[0]}</Typography>
        </Box>
    );
}