import { useState } from "react";
import { useSelector } from "react-redux";
import { EntityType } from "../../@types/generic";
import { RootState } from "../../store/root";
import { useFetchMissingAppUsersByIdIfNeededHook } from "../app_users/hooks";
import { IUseFetchCollectionsPageHookProps, useFetchCollectionsPageHook } from "../collections/hooks";
import { selectCurrentUser } from "../global/globalsSlice";
import PropertyCollectionsDisplay from "./PropertyCollectionsDisplay";

export interface IPropertyCollectionsProps {
    propertyId: number;
}

export default function PropertyCollections({
    propertyId: id,
}: IPropertyCollectionsProps) {
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const [currentSearchValues] = useState<IUseFetchCollectionsPageHookProps>({
        page_number: 1,
        page_size: 50,
        min_page_number_to_fetch: 1,
        filter: {
            entity_type: EntityType.Property,
            entity_ids: [id],
        },
    });

    const { fetching: fetchingCollections, allResultsSet: collections } = useFetchCollectionsPageHook({
        ...currentSearchValues
    });

    const { loading: loadingAppUsers } = useFetchMissingAppUsersByIdIfNeededHook(collections.map(x => x.created_by));

    return (
        <PropertyCollectionsDisplay
            collections={collections}
            fetchingCollections={fetchingCollections}
            propertyId={id}
            currentUser={currentUser}
            loading={loadingAppUsers || fetchingCollections}
        />
    )
}
