import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Collection } from "../../@types/collection";
import { Membership } from "../../@types/membership";
import { useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/root";
import { ShowError, ToMoney } from "../../utils/generic";
import FormDialog from "../form/FormDialog";
import { listMembershipsAsync, selectCurrentUserMembership } from "../memberships/membershipsSlice";
import { createPurchasedCollectionAsync } from "../purchased_collections/purchasedCollectionsSlice";
import { PremiumMembershipUnlockedDownloadFeeCents } from "./collectionConstants";
import { selectCollectionById } from "./collectionsSlice";


export interface ICollectionUnlockDialogProps {
    collectionId: number;
    open: boolean;
    onClose: () => void;
    onSave: (success: boolean) => void;
}

export default function CollectionUnlockDialog(props: ICollectionUnlockDialogProps) {
    return (
        <FormDialog
            title="Download this collection"
            onClose={props.onClose}
            open={props.open}
        >
            <CollectionUnlockForm {...props} />
        </FormDialog>
    )
}


export interface ICollectionUnlockFormProps {
    collectionId: number;
    onSave: (success: boolean) => void;
    onClose: () => void;
}

export function CollectionUnlockForm({
    collectionId,
    onSave,
    onClose,
}: ICollectionUnlockFormProps) {
    const dispatch = useAppDispatch();
    const [submitting, setSubmitting] = useState(false);
    const collection = useSelector((store: RootState) => selectCollectionById(store, collectionId));
    const currentUserMembership = useSelector(selectCurrentUserMembership);
    const [paymentAmount, setPaymentAmount] = useState(0);

    useEffect(() => {
        if(!currentUserMembership || !collection) return;

        // If the collection is unlocked and they have an active premium membership.
        if (collection?.unlocked_by_user_id > 0 &&
            currentUserMembership &&
            new Date(currentUserMembership?.expires_at) > new Date()
        ) {
            setPaymentAmount(PremiumMembershipUnlockedDownloadFeeCents);
            return;
        }

        setPaymentAmount(collection.unlock_fee);
    }, [collection, currentUserMembership])

    const submit = async () => {
        setSubmitting(true);

        if(!currentUserMembership?.app_user_id) {
            ShowError("No membership found.");
            console.error("Current user's membership could not be found.");
            return;
        }

        try {
            var resp = (await dispatch(createPurchasedCollectionAsync({
                purchased_collection: {
                    collection_id: collection.id,
                    app_user_id: currentUserMembership.app_user_id,
                    amount: paymentAmount,
                },
            }))) as any;
            if (resp?.payload) {
                // Refetch balance.
                await dispatch(listMembershipsAsync({
                    filter: {
                        membership_ids: [currentUserMembership.id],
                        app_user_ids: [currentUserMembership.app_user_id],
                    }
                }));

                onSave(true);
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div>
            <GetUnlockOutcomesText
                collection={collection}
                membership={currentUserMembership}
            />

            <Box
                textAlign="right"
                marginTop={2}
            >
                <Button
                    className="mr-3"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                >
                    {submitting ? "Unlocking..." : `Unlock for ${ToMoney(collection.unlock_fee)}`}
                </Button>
            </Box>
        </div>
    );
}


function GetUnlockOutcomesText({
    collection,
    membership,
}: {
    collection: Collection,
    membership: Membership | undefined | null,
}) {
    const defaultVariant = "body2";

    if(!collection || !membership) return null;

    // This is a collection that has been previously unlocked.
    if (collection.unlocked_by_user_id > 0) {
        const premiumMemberUnlockFee = collection.unlock_fee < PremiumMembershipUnlockedDownloadFeeCents ? collection.unlock_fee : PremiumMembershipUnlockedDownloadFeeCents;

        // Active premium membership.
        if (new Date(membership?.expires_at) > (new Date())) {
            return (
                <Typography variant={defaultVariant}>
                    Your premium membership lets you download this unlocked collection for {ToMoney(PremiumMembershipUnlockedDownloadFeeCents)}
                    <i>(normally {ToMoney(premiumMemberUnlockFee)})</i>.
                </Typography>
            )
        }

        return (
            <Typography variant={defaultVariant}>
                Download this collection for {ToMoney(collection.unlock_fee)}. Premium members can download this collection
                for {ToMoney(premiumMemberUnlockFee)}.
            </Typography>
        )
    }

    // This is a collection that has never been unlocked.
    return (
        <Typography variant={defaultVariant}>
            Unlock this collection for {ToMoney(collection.unlock_fee)}? You'll be the first person to unlock it
            which will make it available to premium members at a discounted rate. As a thankyou, you will receive a commission
            if it is purchased again in the future.
        </Typography>
    )
}

