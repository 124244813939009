import { useEffect, useState } from "react";
import { IListMemberships, Membership } from "../../@types/membership";
import { useAppDispatch } from "../../store/hooks";
import { getMembershipsByAppUserIdIfNeededAsync, listMembershipsAsync } from "./membershipsSlice";

export interface IUseFetchMembershipsPageHookProps extends IListMemberships {
    min_page_number_to_fetch: number;
}

export const useFetchMembershipsPageHook = ({ page_number, min_page_number_to_fetch, page_size, filter }: IUseFetchMembershipsPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<Membership[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<Membership[]>([]);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(listMembershipsAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var memberships = resp.payload as Membership[];
                if (memberships?.length) {
                    setMorePages(memberships.length >= Number(page_size))
                    setLastResultSet(memberships);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.id === x.id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}

// useFetchMembershipsByAppUserIdIfNeeded fetches memberships by app user id if they are not already in the store.
export const useFetchMembershipsByAppUserIdIfNeeded = (appUserIds: number[]) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (loading || !appUserIds?.length) {
            return;
        }

        const getMissingMemberships = async () => {
            setLoading(true);
            try {
                await dispatch(getMembershipsByAppUserIdIfNeededAsync(appUserIds));
            } finally {
                setLoading(false);
            }
        };

        getMissingMemberships();
    }, [appUserIds, loading, dispatch]);

    return { loading };
}