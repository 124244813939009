import { Box, styled, Container, Grid, TextField, Switch, alpha, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { AppUser } from "../../../@types/app_user";
import { Property } from "../../../@types/property";
import { ValidationError } from "../../../@types/generic";
import { FieldHelperTextAndValidationErrorState } from "../../form/FormUtils";
import StepHeader from "./PropertyCreateStepHeader";
import PropertyLocationPicker from "../PropertyLocationPicker/PropertyLocationPicker";
import PropertyAddressPicker, { PropertyAddressPickerAddress } from "../PropertyAddressPicker/PropertyAddressPicker";
import { useState } from "react";

export interface IStepProps {
    property: Property;
    setFormData: (property: Property) => void;
    submitting: boolean;
    currentUser?: AppUser;
    submit: () => void;
    activeStep: number,
    validationErrors: ValidationError[],
}

export function StepLocate({ property, setFormData, validationErrors }: IStepProps) {
    const [showManualForm, setShowManualForm] = useState(false);

    return (
        <Box>
            <Container>
                <StepHeader
                    title="Set the address"
                    description="Describe the property's location for other users."
                />

                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <PropertyAddressPicker updateCallback={(address: PropertyAddressPickerAddress) => {
                            setFormData({
                                ...property,
                                ...address,
                            })
                        }} />

                        <Typography variant="caption">Enter manually</Typography>
                        <GreenSwitch onClick={() => setShowManualForm(!showManualForm)} />
                    </Grid>

                    {showManualForm && (
                        <ManualAddressForm
                            property={property}
                            setFormData={setFormData}
                            validationErrors={validationErrors}
                        />
                    )}

                </Grid>
            </Container>
        </Box>
    )
}


interface IManualAddressFormProps {
    property: Property;
    setFormData: (property: Property) => void;
    validationErrors: ValidationError[],
}

function ManualAddressForm({
    property,
    setFormData,
    validationErrors,
}: IManualAddressFormProps) {
    return (
        <>
            <Grid item xs={12}>
                <TextField
                    id="txt-subpremise"
                    label="Subpremise"
                    variant="outlined"
                    placeholder="e.g. Property Inspection Report July 2022"
                    defaultValue={property.subpremise}
                    onChange={(e: any) => setFormData({ ...property, subpremise: e?.target?.value })}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...FieldHelperTextAndValidationErrorState(
                        validationErrors,
                        "Unit, flat, apartment, lot, room, space, etc.",
                        "subpremise"
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="txt-street-number"
                    label="Street Number"
                    variant="outlined"
                    placeholder="21"
                    defaultValue={property.street_number}
                    onChange={(e: any) => setFormData({ ...property, street_number: e?.target?.value })}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...FieldHelperTextAndValidationErrorState(
                        validationErrors,
                        "The street number e.g. 21.",
                        "street_number"
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="txt-street"
                    label="Street"
                    variant="outlined"
                    defaultValue={property.street}
                    placeholder="Bucknall court"
                    onChange={(e: any) => setFormData({ ...property, street: e?.target?.value })}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...FieldHelperTextAndValidationErrorState(
                        validationErrors,
                        "Detailed information that may be relevant to a potential purchaser.",
                        "street"
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="txt-suburb"
                    label="Suburb"
                    variant="outlined"
                    defaultValue={property.suburb}
                    placeholder="Regency Downs"
                    onChange={(e: any) => setFormData({ ...property, suburb: e?.target?.value })}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...FieldHelperTextAndValidationErrorState(
                        validationErrors,
                        "The property's suburb.",
                        "suburb"
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="txt-state"
                    label="State"
                    variant="outlined"
                    defaultValue={property.state}
                    placeholder="QLD"
                    onChange={(e: any) => setFormData({ ...property, state: e?.target?.value })}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...FieldHelperTextAndValidationErrorState(
                        validationErrors,
                        "The property's state.",
                        "state"
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="txt-postcode"
                    label="Postcode"
                    variant="outlined"
                    defaultValue={property.postcode}
                    placeholder="4341"
                    onChange={(e: any) => setFormData({ ...property, postcode: e?.target?.value })}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...FieldHelperTextAndValidationErrorState(
                        validationErrors,
                        "The property's postcode.",
                        "postcode"
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="txt-country"
                    label="Country"
                    variant="outlined"
                    defaultValue={property.country}
                    placeholder="AU"
                    onChange={(e: any) => setFormData({ ...property, country: e?.target?.value })}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...FieldHelperTextAndValidationErrorState(
                        validationErrors,
                        "The property's country.",
                        "country"
                    )}
                />
            </Grid>
        </>
    )
}


const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));

export function StepMapPlacement({ property, setFormData, }: IStepProps) {
    return (
        <Box>
            <Container>
                <StepHeader
                    title="Set the properties map location"
                    description="Locate the property on the map by clicking on the correct location."
                />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <PropertyLocationPicker
                            lat={Number(property.lat)}
                            lng={Number(property.lng)}
                            zoom={17}
                            onClick={(lat, lng, zoom) => setFormData({ ...property, lat: String(lat), lng: String(lng) })}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
