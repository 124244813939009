import { IStepProps, StepDescribe, StepType, StepPrice, StepFreeOrPaid } from "./CollectionCreateWizardSteps";

export const WIZARD_STEP_DESCRIBE_TAB_NUMBER = 0;
export const WIZARD_STEP_FREE_OR_PAID_TAB_NUMBER = 1;
export const WIZARD_STEP_TYPE_TAB_NUMBER = 2;
export const WIZARD_STEP_PRICE_TAB_NUMBER = 3;

export default function CollectionCreateWizardContentWrapper(props: IStepProps) {
    switch (props.activeStep) {
        case WIZARD_STEP_DESCRIBE_TAB_NUMBER:
            return <StepDescribe {...props} />
        case WIZARD_STEP_FREE_OR_PAID_TAB_NUMBER:
            return <StepFreeOrPaid {...props} />
        case WIZARD_STEP_TYPE_TAB_NUMBER:
            return <StepType {...props} />
        case WIZARD_STEP_PRICE_TAB_NUMBER:
            return <StepPrice {...props} />
        default:
            return null;
    }
}