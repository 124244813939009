import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListCollections, Collection } from '../../@types/collection';
import { EntityType } from '../../@types/generic';
import { RootState } from '../../store/root';
import CollectionsApi from './collectionsApi';

export interface CollectionsState {
    byId: {
        [id: string]: Collection;
    },
    fetchedById: {
        [id: string]: null,
    }
}

const initialState: CollectionsState = {
    byId: {},
    fetchedById: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const listCollectionsAsync = createAsyncThunk(
    'collectionsGet',
    async (request: IListCollections) => {
        return await CollectionsApi.List(request);
    }
);

export const updateCollectionAsync = createAsyncThunk(
    'collectionUpdate',
    async (request: Collection) => {
        return await CollectionsApi.Update(request);
    }
);

export const createCollectionAsync = createAsyncThunk(
    'collectionCreate',
    async (request: Collection) => {
        return await CollectionsApi.Create(request);
    }
);

export const getCollectionsByIdIfNeededAsync = createAsyncThunk(
    'getCollectionsByIdIfNeededAsync',
    async (collectionIds: number[], { getState, dispatch }) => {
        const state = getState() as RootState;

        // Ensure that we don't have any duplicates.
        let uniqueCollectionIds = [...new Set(collectionIds)];

        // Ensure that we won't try to double fetch.
        dispatch(collectionsSlice.actions.fetched(uniqueCollectionIds));

        // Ensure they aren't already there.
        let missingCollectionIds = uniqueCollectionIds.filter(collectionId => !state.collections.byId[collectionId] && collectionId);

        // Ensure we haven't already tried to fetch them.
        missingCollectionIds = missingCollectionIds.filter(collectionId => {
            return !Object.hasOwn(state.collections.fetchedById, collectionId)
        });

        if (!missingCollectionIds.length) return;
        
        return await CollectionsApi.List({
            filter: {
                collection_ids: missingCollectionIds,
            },
        });
    }
);

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        fetched: (state, action: PayloadAction<Array<number>>) => {
            state.fetchedById = action.payload.reduce((acc, collectionId) => {
                acc[collectionId] = null;
                return acc;
            }, state.fetchedById);
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(listCollectionsAsync.fulfilled, (state: CollectionsState, action) => {
                state.byId = {
                    ...state.byId,
                    ...action?.payload?.reduce((acc: {
                        [index: string]: Collection,
                    }, item: Collection) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(listCollectionsAsync.rejected, (state) => {
                throw new Error("Need to handle this get collection rejected")
            })
            .addCase(updateCollectionAsync.fulfilled, (state: CollectionsState, action) => {
                if (action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(updateCollectionAsync.rejected, (state) => {
                throw new Error("error updating collection")
            })
            .addCase(createCollectionAsync.fulfilled, (state: CollectionsState, action) => {
                if (action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(createCollectionAsync.rejected, (state) => {
                throw new Error("error creating question")
            })
            .addCase(getCollectionsByIdIfNeededAsync.fulfilled, (state: CollectionsState, action) => {
                state.byId = {
                    ...state.byId,
                    ...action?.payload?.reduce((acc: {
                        [index: string]: Collection,
                    }, item: Collection) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectCollectionById = (state: RootState, collectionId: number) => {
    return (Object.values(state.collections.byId) as Array<Collection>)
        .filter((item: Collection) => item.id === collectionId)?.[0];
}

export const selectCollectionsByPropertyId = (state: RootState, propertyId: number) => {
    return (Object.values(state.collections.byId) as Array<Collection>)
        .filter((item: Collection) => item.id === propertyId && item.entity_type === EntityType.Property);
}

export const selectCollectionsCountByPropertyId = (state: RootState, propertyId: number) => {
    return selectCollectionsByPropertyId(state, propertyId).length;
}

export const selectCollectionsUnlockedCountByPropertyId = (state: RootState, propertyId: number) => {
    return selectCollectionsByPropertyId(state, propertyId).filter(x => x.unlocked_by_user_id > 0).length;
}

export const selectCollectionsByPostcode = (state: RootState, postcode: string) => {
    var collectionPostcodes = Object.values(state.collectionPostcodes.byId)
        .filter(x => x.postcode === postcode);

    if(!collectionPostcodes.length) return new Array<Collection>();
    
    return Object.values(state.collections.byId)
        .filter(x => collectionPostcodes
            .find(y => y.collection_id === x.id)
    )
}


// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = collectionsSlice;

// Extract and export each action creator by name
// export const { getCollectionsAsync }, = actions;

export default reducer;