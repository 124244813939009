import { EntityType, PaginationProps } from "./generic";

export type Attachment = {
    id: number;
    external_reference: string,
    size: number,
    content_type: string,
    name: string,
    caption: string,
    status: AttachmentStatus,
    entity_type: EntityType,
    entity_id: number,
    url: string,
    upload_url: string,
    type: string,
    created_by: number,
    created_at: Date,
    updated_at: Date,
    deleted_at?: Date,
}

export interface IListAttachmentsFilter {
    attachment_ids?: Array<number>,
    status?: AttachmentStatus,
    entity_type?: string,
    entity_ids?: Array<number>,
}

export interface IListAttachments extends PaginationProps {
    filter: IListAttachmentsFilter,
}

export enum AttachmentStatus {
    UNSPECIFIED = 'unspecified',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    PENDING_UPLOAD = 'pending_upload',
}

export enum AttachmentType {
    ATTACHMENT_TYPE_UNSPECIFIED = "unspecified",
    ATTACHMENT_TYPE_HEADER_IMAGE = "header_image",
    ATTACHMENT_TYPE_LIST_IMAGE = "list_image",
}