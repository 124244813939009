import { Public, Streetview, Map, HolidayVillage, Numbers } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { IListPropertiesFilter } from "../../@types/property";

const chipStyles = {
    color: "white",
    borderColor: "white",
    marginRight: 1,
};

export default function PropertySearchFormChips(
    {
        searchValues,
        setSearchValues,
    }:
        {
            searchValues: IListPropertiesFilter,
            setSearchValues: (searchValues: IListPropertiesFilter) => void,
        }) {
    return (
        <Box
            sx={{
                minHeight: "30px",
            }}
        >
            {searchValues.state && <CustomChip label={searchValues.state} icon={<Map />} onClick={() => setSearchValues({ ...searchValues, state: '' })} />}
            {searchValues.suburb && <CustomChip label={searchValues.suburb} icon={<HolidayVillage />} onClick={() => setSearchValues({ ...searchValues, suburb: '' })} />}
            {searchValues.postcode && <CustomChip label={searchValues.postcode} icon={<Numbers />} onClick={() => setSearchValues({ ...searchValues, postcode: '' })} />}
            {searchValues.street && <CustomChip label={searchValues.street} icon={<Streetview />} onClick={() => setSearchValues({ ...searchValues, street: '' })} />}
            {searchValues.country && <CustomChip label={searchValues.country} icon={<Public />} onClick={() => setSearchValues({ ...searchValues, country: '' })} />}
        </Box>
    )
}

const CustomChip = ({ label, icon, onClick }: {
    label: string,
    icon: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined,
    onClick: () => void,
}) => {
    return (
        <Chip
            label={label}
            color="primary"
            sx={chipStyles}
            variant="outlined"
            icon={icon}
            onClick={onClick}
        />
    )
}

