import { Grow } from "@mui/material";
import { ReactElement } from "react";

interface IAnimationWrapperProps {
    children: ReactElement<any, any>;
 }

function AnimationWrapper(props: IAnimationWrapperProps) {
    if (!props.children) return null;

    return (
        <Grow in={true} mountOnEnter unmountOnExit>
            {props.children}
        </Grow>
    )
}

export default AnimationWrapper;