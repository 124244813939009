import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppUser } from "../../../@types/app_user";
import { Attachment } from "../../../@types/attachment";
import { CollectionAttachment } from "../../../@types/collection_attachment";
import { EntityType } from "../../../@types/generic";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/root";
import AttachmentCreateForm from "../../attachments/AttachmentCreateForm";
import CollectionAttachmentsDisplay from "../../collection_attachments/CollectionAttachmentsDisplay";
import { createCollectionAttachmentAsync, selectCollectionAttachmentsByCollectionId } from "../../collection_attachments/collectionAttachmentsSlice";
import { selectCollectionById } from "../collectionsSlice";


interface ICollectionManagementAttachmentsTabProps {
    collectionId: number;
    currentUser: AppUser;
}


export default function CollectionManagementAttachmentTab({
    collectionId,
    currentUser,
}: ICollectionManagementAttachmentsTabProps) {
    const dispatch = useAppDispatch();
    const collection = useSelector((store: RootState) => selectCollectionById(store, collectionId));
    const collectionAttachments = useSelector((store: RootState) => selectCollectionAttachmentsByCollectionId(store, collectionId));
    const createCollectionAttachment = async (attachment: Attachment) => {
        await dispatch(createCollectionAttachmentAsync({
            collection_id: collectionId,
            attachment_id: attachment.id,
        } as CollectionAttachment));
    }

    const [showAddAttachments, setShowAddAttachments] = useState(false);

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            mb: 3,
                        }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 'lighter',
                                fontSize: 24,
                            }}
                        >
                            Collection Attachments
                        </Typography>
                        <Typography variant="body2">This shows the attachments that you have added to this collection.</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{
                    textAlign: 'right',
                    paddingBottom: 3,
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAddAttachments(true)}
                    >Upload</Button>
                </Grid>
            </Grid>
            <CollectionAttachmentsDisplay
                collectionAttachments={collectionAttachments}
                showDownloadLink={true}
            />
            <AttachmentCreateForm
                open={showAddAttachments}
                postAttachmentCreatedFunc={createCollectionAttachment}
                entityId={collection.id}
                entityType={EntityType.Collection}
                onSave={() => setShowAddAttachments(false)}
                onCancel={() => setShowAddAttachments(false)}
            />
        </Box>
    )
}