import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListProperties, Property } from '../../@types/property';
import { RootState } from '../../store/root';
import PropertiesApi from './propertiesApi';

export interface PropertiesState {
    byId: {
        [id: string]: Property;
    },
    fetchedById: {
        [id: number]: null,
    }
}

const initialState: PropertiesState = {
    byId: {},
    fetchedById: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const listPropertiesAsync = createAsyncThunk(
    'propertiesGet',
    async (request: IListProperties) => {
        return await PropertiesApi.List(request);
    }
);

export const updatePropertyAsync = createAsyncThunk(
    'propertyUpdate',
    async (request: Property) => {
        return await PropertiesApi.Update(request);
    }
);

export const createPropertyAsync = createAsyncThunk(
    'propertyCreate',
    async (request: Property) => {
        return await PropertiesApi.Create(request);
    }
);

export const getPropertiesByIdIfNeededAsync = createAsyncThunk(
    'getPropertiesByIdIfNeeded',
    async (propertyIds: number[], { getState, dispatch }) => {
        const state = getState() as RootState;

        // Ensure that we won't try to double fetch.
        dispatch(propertiesSlice.actions.fetched(propertyIds));

        // Ensure they aren't already there.
        let missingPropertyIds = propertyIds.filter(id => !state.properties.byId[id] && id);

        // Ensure we haven't already tried to fetch them.
        missingPropertyIds = missingPropertyIds.filter(id => {
            return !Object.hasOwn(state.properties.fetchedById, id)
        });

        if (!missingPropertyIds.length) return;

        return await PropertiesApi.List({
            filter: {
                property_ids: missingPropertyIds,
            },
        });
    }
);

export const propertiesSlice = createSlice({
    name: 'properties',
    initialState,
    reducers: {
        fetched: (state, action: PayloadAction<Array<number>>) => {
            state.fetchedById = action.payload.reduce((acc, id) => {
                acc[id] = null;
                return acc;
            }, state.fetchedById);
        },
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(listPropertiesAsync.fulfilled, (state: PropertiesState, action) => {
                state.byId = {
                    ...state.byId,
                    ...action.payload.reduce((acc: {
                        [index: string]: Property,
                    }, item: Property) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(listPropertiesAsync.rejected, (state) => {
                throw new Error("Need to handle this get property rejected")
            })
            .addCase(updatePropertyAsync.fulfilled, (state: PropertiesState, action) => {
                if (action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(updatePropertyAsync.rejected, (state) => {
                throw new Error("error updating property")
            })
            .addCase(createPropertyAsync.fulfilled, (state: PropertiesState, action) => {
                if (action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(createPropertyAsync.rejected, (state) => {
                throw new Error("error creating property")
            })
            .addCase(getPropertiesByIdIfNeededAsync.fulfilled, (state: PropertiesState, action) => {
                if(!action?.payload) return;

                state.byId = {
                    ...state.byId,
                    ...action.payload.reduce((acc: {
                        [index: string]: Property,
                    }, item: Property) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
        }
    },
);



// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectPropertyById = (state: RootState, propertyId: number) => {
    return (Object.values(state.properties.byId) as Array<Property>)
        .filter((item: Property) => item.id === propertyId)?.[0];
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = propertiesSlice;

// Extract and export each action creator by name
// export const { getPropertiesAsync }, = actions;

export default reducer;