// PropertyOf allows us to get a type's property as a string with

import { Message, MessageType } from "../@types/message";
import { GetExposedDispatch } from "../store/injector";

// compile-time validation: https://stackoverflow.com/a/42516869/522859
export const PropertyOf = <TObj>(name: keyof TObj) => name;
export const proxiedPropertiesOf = <TObj>(obj?: TObj) =>
    new Proxy({}, {
        get: (_, prop) => prop,
        set: () => {
            throw Error('Set not supported');
        },
    }) as {
        [P in keyof TObj]?: P;
    };


/* SetLocalStorageItem sets a value for a value for (or creates) a local storage item. */
export const SetLocalStorageItem = (key: string, value: any) => {
    var objectToStore = {
        storedAt: new Date(),
        data: value
    };

    localStorage.setItem(key, JSON.stringify(objectToStore));
}

/* GetLocalStorageItem retrieves an existing local storage item value. If it doesn't exist null is returned. */
export const GetLocalStorageItem = (key: string) => {
    var data = localStorage.getItem(key);
    if (data) {
        var retrievedObject = JSON.parse(data);
        return retrievedObject ? retrievedObject.data : retrievedObject;
    }

    return null;
}

/* NOTE: This isn't perfect and there's still a chance for collisions. Should only be used for front end e.g. messages
- https://stackoverflow.com/a/68141099/522859 */
export function Uuidv4() {
    // @ts-ignore
    return '00-0-4-1-000'.replace(/[^-]/g, s => ((Math.random() + ~~s) * 0x10000 >> s).toString(16).padStart(4, '0')
    );
}

export function RandomNumber() {
    return Math.floor(Math.random() * 1000000);
}

/* Slugify makes a string into a valid URL slug. */
export function Slugify(text: string, maxLength: number) {
    if (!text) return "-";

    return text.toLowerCase()
        .trim()
        .replace(/[^\w- ]+/g, '')
        .replace(/ /g, '-')
        .replace(/[-]+/g, '-')
        .slice(0, maxLength);
}

/* SentenceCaseEnum makes an enum into a valid sentence case string: https://stackoverflow.com/a/68946609/522859 */
export function SentenceCaseEnum(enumName: string) {
    return enumName.replace(/([A-Z])/g, ' $1').trim().charAt(0).toUpperCase() + enumName.replace(/([A-Z])/g, ' $1').trim().slice(1);
}

/* TimeDifferenceAsString takes a datetime and returns a string saying how far in the
past or future it occurred. https://stackoverflow.com/a/69122877/522859 */
export function TimeDifferenceAsString(input: Date, sourceDate = Date.now()) {
    const date = (input instanceof Date) ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat('en');
    const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1
    } as { [key: string]: number };
    const secondsElapsed = (date.getTime() - sourceDate) / 1000;
    for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
            const delta = secondsElapsed / ranges[key];
            return formatter.format(Math.round(delta), key as any);
        }
    }
}

export function ShowError(text: string) {
    var message: Message = {
        id: RandomNumber(),
        type: MessageType.ERROR,
        message: text,
    };

    ShowMessage(message);
}

export function MessageDelete(id: number) {
    GetExposedDispatch()({
        type: "messages/destroy",
        payload: id,
    });
}


export function ShowMessage(message: Message) {
    GetExposedDispatch()({
        type: "messages/receive",
        payload: [message],
    });
}

export function DateIsSet(date: Date) {
    return date?.toString().indexOf('0001-01-01') === -1;
}

export function ToMoney(amount: number) {
    if (!amount) return '$0.00';

    return `$${(amount / 100).toFixed(2)}`;
}

export function Truncate(str: string, n: number) {
    if (!str) return "";

    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
}

export const Ellipsis = Truncate;