
import { Box, Typography, Button, IconButton, Hidden } from "@mui/material"
import TextTransition, { presets } from "react-text-transition"
import SecondaryHeaderColor from '@mui/material/colors/blue';
import HeaderIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import ChevronDown from '@mui/icons-material/KeyboardArrowDown';
import { useState, useEffect } from "react";
import {
    Link as RouterLink,
} from 'react-router-dom';
import ParticlesBackground from "../layouts/ParticlesBackground";

// Order these by length to prevent jarring text transitions.
const HEADER_TEXTS = [
    "titles",
    "photos",
    "rate notices",
    "flood reports",
    "council notes",
    "tenant reports",
    "pest inspections",
    "inspection reports",
    "maintenance requests",
];

export default function HomeHeader() {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() =>
            setIndex(index => index + 1),
            3000,
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <Box sx={{
            display: 'grid',
            position: 'relative',
            height: '100vh',
            width: '100vw',
            pt: 10,
            overflow: 'hidden',
        }}>
            <ParticlesBackground />
            <Box sx={{
                zIndex: 1,
                textAlign: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100%',
                overflow: 'hidden'
            }}>
                <HeaderIcon style={{
                    color: '#FFF',
                    fontSize: 96,
                }} />
                <Typography
                    variant="h1"
                    fontWeight="fontWeightBold"
                    fontSize="h3.fontSize"
                    color={SecondaryHeaderColor[300]}
                    textOverflow="hidden"
                    noWrap={true}
                >
                    <span style={{ color: "#FFF" }}>Property </span>
                    <Hidden smUp>
                        <TextTransition
                            springConfig={presets.wobbly}
                            direction="down"
                            inline={false}
                        >
                            {HEADER_TEXTS[index % HEADER_TEXTS.length]}
                        </TextTransition>
                    </Hidden>
                    <Hidden smDown>
                        <TextTransition
                            springConfig={presets.wobbly}
                            direction="down"
                            inline={true}
                        >
                            {HEADER_TEXTS[index % HEADER_TEXTS.length]}
                        </TextTransition>
                    </Hidden>
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="rgba(255,255,255,0.8)"
                    maxWidth="sm"
                    fontSize="body2"
                    mt={2}
                >
                    Crowd sourced property documents uploaded by inspectors, conveyancers and tenants.
                </Typography>

                <Button
                    component={RouterLink}
                    to="/properties"
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        borderRadius: '25px',
                        paddingX: 10,
                        paddingY: 2,
                        marginTop: 4,
                    }}
                >
                    Get Started
                </Button>

                <IconButton
                    component={RouterLink}
                    to="/properties"
                    size="large"
                    sx={{
                        color: '#FFF',
                        position: 'absolute',
                        bottom: 75,
                    }}
                >
                    <ChevronDown />
                </IconButton>

            </Box>
        </Box>
    )
}

