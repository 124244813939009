import { CalendarMonth } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { DateTime } from "luxon";
import { Collection } from "../../@types/collection";

interface ICollectionUnlockFeeChipProps {
    collection: Collection;
}

export default function CollectionCreatedChip({ collection }: ICollectionUnlockFeeChipProps) {
    return (
        <Chip
            icon={<CalendarMonth />}
            label={DateTime.fromISO(collection.created_at.toString()).toRelative()}
            color="primary"
            variant="outlined"
            sx={{
                borderColor: blueGrey[500],
                color: blueGrey[500],
            }}
        />
    )
}