import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../components/global/globalsSlice";
import PricingWidget from "./PricingWidget";

// INFO: using this as a rough guideline: https://medium.com/design-code-repository/re-pricing-table-83565fad5f85
export default function AddCreditCustom() {
    const currentUser = useSelector(selectCurrentUser);

    return (
        <PricingWidget
            price="Custom"
            abovePriceText='&nbsp;'
            mainTextHeader='Pick an amount'
            mainText='Unlock collections and documents. An active premium membership will also let you download previously unlocked documents at a significant discount.'
            buttonLink={`https://buy.stripe.com/test_3cs8wNb5o7xv17adQR?client_reference_id=${currentUser?.id}`}
            buttonText='Purchase Credit'
            footerText='You can purchase credit for any whole dollar amount over $5.00.'
            hideDollar={true}
        />
    )
}