import { EntityType, PaginationProps } from "./generic";

export type Collection = {
    id: number,
    name: string,
    description: string,
    status: CollectionStatus,
    entity_type: EntityType,
    entity_id: number,
    free: boolean,
    unlock_fee: number,
    unlocked_at: Date,
    unlocked_by_user_id: number,
    collection_attachments_count: number,
    created_by: number,
    created_at: Date,
    updated_at: Date,
    deleted_at?: Date,
}

export interface IListCollectionsFilter {
    created_by?: number,
    collection_ids?: Array<number>,
    status?: CollectionStatus,
    entity_type?: string,
    entity_ids?: Array<number>,
}

export interface IListCollections extends PaginationProps {
    filter: IListCollectionsFilter,
}

export enum CollectionStatus {
    UNSPECIFIED = 'unspecified',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}