import { Grid, Box } from "@mui/material";
import { AppUser } from "../../@types/app_user";
import { Collection } from "../../@types/collection";
import NoSearchResultsFound from "../../views/layouts/placeholders/NoSearchResultsFound";
import AnimationWrapper from "../animations/AnimationWrapper";
import { CollectionListItem } from "../collections/CollectionListItem";
import LoaderSkeletonGrid from "../loaders/LoaderSkeletonGrid";

export default function PropertyCollectionsDisplay({
    collections,
    fetchingCollections,
}: {
    collections: Collection[],
    fetchingCollections: boolean,
    propertyId: number,
    currentUser?: AppUser,
    loading: boolean,
}) {
    return (
        <div>
            <>
                {fetchingCollections ? (
                    <Grid container spacing={3}>
                        <LoaderSkeletonGrid
                            numberOfElements={6}
                            gridSizes={{
                                xs: 12,
                                sm: 6,
                                md: 4,
                            }}
                            height={250}
                        />
                    </Grid>
                ) : (
                    collections.length > 0 ?
                        <div>
                            <Grid container spacing={3}>
                                {collections.map((collection) => (
                                    <Grid item xs={12} sm={6} md={4} key={'col' + collection.id} sx={{
                                        minHeight: '100%',
                                    }}>
                                        <AnimationWrapper>
                                            <div>
                                                <CollectionListItem collection={collection} />
                                            </div>
                                        </AnimationWrapper>
                                    </Grid>
                                ))}
                            </Grid>
                        </div> :
                        <Box
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            <NoSearchResultsFound
                                title="No collections found"
                                description="No collections have been added for this property yet."
                            />
                        </Box>
                )}
            </>
        </div>
    )
}