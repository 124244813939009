import { IListPointsTransactions, PointsTransaction } from "../../@types/points_transaction";
import { GetApiUrl, GetIdToken, HandleApiResponse } from "../../utils/api_utils";


export async function List(request: IListPointsTransactions): Promise<PointsTransaction[]> {
    try {
        const resp = await fetch(`${GetApiUrl()}/points_transactions`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Update(request: PointsTransaction): Promise<PointsTransaction> {
    try {
        const resp = await fetch(`${GetApiUrl()}/points_transactions`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ points_transaction: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Create(request: PointsTransaction): Promise<PointsTransaction> {
    try {
        const resp = await fetch(`${GetApiUrl()}/points_transactions`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ points_transaction: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const exports = {
    List,
    Update,
    Create,
}

export default exports;