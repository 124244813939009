import { CheckCircle } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useState } from "react"
import { Link } from "react-router-dom"
import PrimaryButton from "../form/PrimaryButton"
import { Contact } from "./contactApi";

export default function ContactForm() {
    const [sent, setSent] = useState(false);

    return (
        <div className="grid w-full justify-items-center pt-9">
            {sent ? <ContactFormSent /> : (
                <ContactFormNotSent
                    onSent={() => setSent(true)}
                />
            )}
        </div>
    )
}

function ContactFormSent() {
    return (
        <Box
            sx={{
                textAlign: 'center',
                backgroundColor: "darkAugment.main",
                padding: 3,
                borderRadius: 3,
                maxWidth: 300,
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <CheckCircle
                sx={{
                    color: green[500],
                    marginBottom: "-5px",
                }}
            /> <Typography
                variant="body1"
                sx={{
                    display: 'inline-block',
                    color: '#FFFFFF',
                }}
            >
                Message Sent
            </Typography>
        </Box>
    );
}

function ContactFormNotSent({
    onSent,
}: {
    onSent: () => void;
}) {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [submitting, setSubmitting] = useState(false);

    // Sent the contact request to the api.
    const sendContactRequest = async (e: any) => {
        e.preventDefault();

        setSubmitting(true);
        try {
            if (await Contact({
                email,
                message,
                subscribe: false,
            })) {
                onSent();
            } else {
                // An error occured
                console.error("An error occurred submitting the contact request.")
            }
        } catch (e) {
            console.error(e);
            setSubmitting(false);
        }
    }

    return (
        <Box>
            <TextField
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                v-model="email"
                placeholder="Enter your email"
                fullWidth={true}
                variant="outlined"
                size="small"
                sx={{
                    marginBottom: 1,
                    input: {
                    },
                }}
            />
            <TextField
                type="email"
                onChange={(e: any) => setMessage(e.target.value)}
                v-model="email"
                placeholder="Let us know how we can help."
                fullWidth={true}
                variant="outlined"
                size="small"
                multiline={true}
                rows={5}
                sx={{
                    marginBottom: 1,
                    input: {
                        textAlign: 'center',
                        fontSize: '0.8rem',
                    },
                }}
            />
            <Box
                sx={{
                    textAlign: 'right',
                    paddingTop: 1,
                }}
            >
                <Link to="/" style={{
                    marginRight: 8,
                    color: 'rgba(0,0,0,0.4)',
                    textDecoration: 'none',
                }}>
                    Cancel
                </Link>
                <PrimaryButton
                    loading={submitting}
                    label="Send"
                    onClick={sendContactRequest}
                    additionalClasses="rounded-full w-full"
                />
            </Box>
        </Box>
    )
}