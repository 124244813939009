import { EntityType, PaginationProps } from "./generic";

export type PointsTransaction = {
    id: number;
    group_id: string,
    account_id: string,
    debit: number,
    credit: number,
    transaction_type: TransactionType,
    entity_type: EntityType,
    entity_id: number,
    created_by: number,
    created_at: Date,
    updated_at: Date,
    deleted_at?: Date,
}

export interface IListPointsTransactionsFilter {
    points_transaction_ids?: Array<number>,
    app_user_ids?: Array<number>,
}

export interface IListPointsTransactions extends PaginationProps {
    filter: IListPointsTransactionsFilter,
}


export enum TransactionType {
    DEPOSIT = 1,
    WITHDRAWAL,
    COLLECTION_PURCHASE,
    COLLECTION_UNLOCK,
    COMMISSION,
}
