export default function getAddressObject(address_components) {
  var shouldBeComponent = {
    subpremise: ["unit", "apartment", "flat", "lot", "room", "space"],
    street_number: ["street_number"],
    street: ["street_address", "route"],
    state: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    suburb: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    postcode: [
      "postal_code",
      "zip_code",
    ],
    country: ["country"],
  };

  var address = {
    subpremise: "",
    street_number: "",
    street: "",
    suburb: "",
    state: "",
    postcode: "",
    country: "",
  };

  address_components.forEach(component => {
    for (var shouldBe in shouldBeComponent) {
      if (shouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        switch(shouldBe) {
          case "state":
          case "country":
            address[shouldBe] = component.short_name;
            break;
          default:
            address[shouldBe] = component.long_name;
        }
      }
    }
  });

  return address;
} 