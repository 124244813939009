import LayoutDefault from '../layouts/LayoutDefault';
import { ContentRowBuyDocuments, ContentRowSellDocuments } from './content_rows/ContentRowsDocuments';
import ContentRowPurpose from './content_rows/ContentRowPurpose';

import HomeHeader from './HomeHeader';


export default function ViewHome() {


    return (
        <LayoutDefault loading={false}>
            <HomeHeader />

            <ContentRowPurpose />
            <ContentRowSellDocuments />
            <ContentRowBuyDocuments />

            {/* Base another one on the exclusive features section here: https://www.minicreo.com/window-collage/# */}

            
        </LayoutDefault >
    )
}
