import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../components/global/globalsSlice";
import PricingWidget from "./PricingWidget";

// INFO: using this as a rough guideline: https://medium.com/design-code-repository/re-pricing-table-83565fad5f85
export default function StandardMembership() {
    const currentUser = useSelector(selectCurrentUser);

    return (
        <PricingWidget
            price='10'
            abovePriceText='per month'
            mainTextHeader='Standard Membership'
            mainText='Download up to 50 unlocked documents per month for $1.00 each using your download credit.'
            buttonLink={`https://buy.stripe.com/test_aEU14lc9scRP5nq147?client_reference_id=${currentUser?.id}`}
            buttonText='Start Membership'
            footerText='An active membership provides a significant discount on unlocked documents. You will still need download credit (either earned or purchased) to download documents.'
        />
    )
}