import { useState } from 'react';
import { useDocTitle } from '../../routes/Hooks';
import { useParams } from 'react-router-dom';
import LayoutMaxWidthContent from '../layouts/LayoutMaxWidthContent';
import { IUseFetchCollectionsPageHookProps, useFetchCollectionsPageHook } from '../../components/collections/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/root';
import { selectCurrentUser } from '../../components/global/globalsSlice';
import { IUseFetchCollectionAttachmentsPageHookProps, useFetchCollectionAttachmentsPageHook } from '../../components/collection_attachments/hooks';
import CollectionDisplay from '../../components/collections/CollectionDisplay';
import { selectCollectionAttachmentsByCollectionId } from '../../components/collection_attachments/collectionAttachmentsSlice';
import { selectCollectionById } from '../../components/collections/collectionsSlice';
import { Container, Grid } from '@mui/material';
import PropertyDisplayHeader from '../../components/properties/PropertyDisplayHeader';
import { selectPropertyById } from '../../components/properties/propertiesSlice';
import { useFetchPropertyByIdIfNeededHook } from '../../components/properties/hooks';
import BreadcrumbsWrappers from '../../components/breadcrumbs/BreadcrumbsWrapper';
import { GetBreadcrumbsFromProperty } from '../../components/breadcrumbs/BreadcrumbUtils';
import { useFetchPurchasedCollectionsPageHook } from '../../components/purchased_collections/hooks';
import { selectPurchasedCollectionByCollectionId } from "../../components/purchased_collections/purchasedCollectionsSlice";
import LoaderSkeletonGrid from '../../components/loaders/LoaderSkeletonGrid';
import NoSearchResultsFound from '../layouts/placeholders/NoSearchResultsFound';
import { FolderOutlined } from '@mui/icons-material';
import { useEffect } from 'react';

export default function RoutedViewCollection() {
    const { id } = useParams();

    return <ViewCollection id={Number(id)} />
}

export function ViewCollection({
    id
}: {
    id: number,
}) {
    useDocTitle("Collection");

    const [currentSearchValues] = useState<IUseFetchCollectionsPageHookProps>({
        page_number: 1,
        page_size: 1,
        min_page_number_to_fetch: 1,
        filter: {
            collection_ids: [id],
        },
    });
    const [collectionAttachmentSearchValues] = useState<IUseFetchCollectionAttachmentsPageHookProps>({
        page_number: 1,
        page_size: 50,
        min_page_number_to_fetch: 1,
        filter: {
            collection_ids: [id],
        },
        include_attachments: true,
    });
    const [purchasedCollectionSearchValues, setPurchasedCollectionSearchValues] = useState<IUseFetchCollectionsPageHookProps>({
        page_number: 0,
        page_size: 1,
        min_page_number_to_fetch: 1,
        filter: {
            collection_ids: [id],
        },
    });

    var { fetching } = useFetchCollectionsPageHook(currentSearchValues);
    useFetchCollectionAttachmentsPageHook(collectionAttachmentSearchValues);
    useFetchPurchasedCollectionsPageHook(purchasedCollectionSearchValues);

    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const collectionAttachments = useSelector((store: RootState) => selectCollectionAttachmentsByCollectionId(store, id));
    const collection = useSelector((store: RootState) => selectCollectionById(store, id));
    const property = useSelector((store: RootState) => selectPropertyById(store, collection?.entity_id || 0));
    const isCreator = collection && (collection?.created_by === currentUser?.id);
    const purchasedDownload = useSelector((store: RootState) => selectPurchasedCollectionByCollectionId(store, collection?.id));

    useFetchPropertyByIdIfNeededHook(collection?.entity_id || 0);

    // We only want to check for purchased collections if the user is actually logged in.
    useEffect(() => {
        if (currentUser && !purchasedCollectionSearchValues.page_number) {
            setPurchasedCollectionSearchValues({ ...purchasedCollectionSearchValues, page_number: 1 });
        }
    }, [currentUser, purchasedCollectionSearchValues]);

    return (
        <LayoutMaxWidthContent loading={false}>
            <PropertyDisplayHeader propertyId={collection?.entity_id} />
            <Container
                maxWidth="lg"
                sx={{
                    marginTop: 3,
                }}
            >
                <BreadcrumbsWrappers breadcrumbs={GetBreadcrumbsFromProperty(property)} />
                {collection ? <CollectionDisplay
                    collection={collection}
                    currentUser={currentUser}
                    collectionAttachments={collectionAttachments || []}
                    property={property}
                    showDownloadLink={isCreator || !!purchasedDownload || collection.free}
                /> :
                    <CollectionLoader fetching={fetching} />
                }
            </Container>
        </LayoutMaxWidthContent>
    )
}


// CollectionLoader handles what should be displayed if a collection is loading, can't be found
// etc.
function CollectionLoader({
    fetching
}: {
    fetching: boolean,
}) {
    return (
        (
            fetching ? (
                <Grid container spacing={3}>
                    <LoaderSkeletonGrid gridSizes={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12
                    }} numberOfElements={1} height={200}
                    />
                    <LoaderSkeletonGrid gridSizes={{
                        xs: 12,
                        sm: 6,
                        md: 4,
                        lg: 4,
                        xl: 4,
                    }} numberOfElements={6} height={200}
                    />
                </Grid>
            ) : (
                <NoSearchResultsFound
                    title="Collection not found."
                    description="This collection no longer appears to be available."
                    icon={FolderOutlined}
                />
            )
        )
    )
}