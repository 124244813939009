import { FolderCopy } from "@mui/icons-material";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/root";
import NoSearchResultsFound from "../../views/layouts/placeholders/NoSearchResultsFound";
import AnimationWrapper from "../animations/AnimationWrapper";
import { selectCurrentUser } from "../global/globalsSlice";
import LoaderSkeletonGrid from "../loaders/LoaderSkeletonGrid";
import { IUseFetchPurchasedCollectionsPageHookProps, useFetchPurchasedCollectionsPageHook } from "./hooks";
import { PurchasedCollectionListItem } from "./PurchasedCollectionListItem";


export default function PurchasedCollectionsDisplay() {
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const [currentSearchValues, setCurrentSearchValues] = useState<IUseFetchPurchasedCollectionsPageHookProps>({
        page_number: 1,
        page_size: 10,
        min_page_number_to_fetch: 1,
        filter: {
            app_user_ids: [currentUser?.id || 0],
        },
    });

    const {
        fetching: fetchingPurchasedDownloads,
        allResultsSet: purchasedCollections,
        morePages,
    } = useFetchPurchasedCollectionsPageHook({
        ...currentSearchValues
    });

    const getNextPage = () => {
        setCurrentSearchValues({ ...currentSearchValues, page_number: (currentSearchValues.page_number || 0) + 1 })
    }


    const loading = fetchingPurchasedDownloads;

    return (
        <Container
            maxWidth="lg"
            sx={{
                marginTop: 0,
                width: '100%',
            }}
        >
            <Box
                sx={{
                    mb: 3,
                }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: 'lighter',
                        fontSize: 24,
                    }}
                >
                    Purchased Collections
                </Typography>
                <Typography variant="body2">Purchased or unlocked documents and collections.</Typography>
            </Box>

            {purchasedCollections.length === 0 && !fetchingPurchasedDownloads && (
                <NoSearchResultsFound
                    title="No collections purchased yet."
                    description="When you purchase a collection it will appear here."
                    icon={FolderCopy}
                />
            )}

            <Grid container spacing={3}>
                {purchasedCollections.map(x => {
                    return (
                        <Grid item xs={12} md={6} lg={4}>
                            <AnimationWrapper>
                                <div>
                                    <PurchasedCollectionListItem
                                        purchasedCollection={x}
                                    />
                                </div>
                            </AnimationWrapper>
                        </Grid>
                    )
                })}

                {(loading) && (
                    <LoaderSkeletonGrid gridSizes={{
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 4,
                        xl: 4,
                    }} numberOfElements={6} height={200}
                    />
                )}

                {
                    morePages && !loading ? (
                        
                        <Grid item xs={12}
                            sx={{
                                textAlign: 'center',
                                marginTop: 3,
                            }}
                        >
                            <Button
                                onClick={getNextPage}
                                variant="contained"
                                fullWidth
                                sx={{
                                    maxWidth: 'sm',
                                }}
                            >More</Button>
                        </Grid>
                    ) : null
                }
            </Grid>

        </Container>
    )
}