import Typography from '@mui/material/Typography';
import { Box, Grid, keyframes } from "@mui/material";
import ContainedBodySection from '../../layouts/sections/SectionContainedBody';

const fade = keyframes`
  0% {
    opacity: 1;
  }
  75% {
    filter: blur(0px) hue-rotate(0deg);
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`;

export default function ContentRowPurpose() {
    return (
        <ContainedBodySection>
            <Grid container spacing={3} paddingY={10} paddingX={2} textAlign="center" flexDirection="column">
                <Grid item xs={12}>
                    <Typography variant="overline">Purpose</Typography>
                    <Typography variant="h2" fontWeight="fontWeightMedium" fontSize="h4.fontSize">
                        Property insight from the people that know
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        fontWeight="fontWeightLight"
                        sx={{
                            paddingX: 3,
                            maxWidth: {
                                xs: '100%',
                                xl: '750px',
                            },
                        }}
                    >
                        Reviews and information from people that have seen, inspected or even lived in the property. Our members
                        upload everything from pest inspections to tenant photos. They'll show you what the real estate ad doesn't.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            borderRadius: 8,
                            maxWidth: {
                                xs: '100%',
                                xl: '750px',
                            },
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            component="img"
                            src="/img/content/damaged-room.png"
                            alt="Damaged room"
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: '51%',
                                backgroundColor: 'rgba(255,255,255,0.5)',
                                animation: `${fade} 5s ease-out infinite`,
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>

        </ContainedBodySection>
    )
}