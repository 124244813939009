import { Grid, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import { PointsTransaction } from "../../@types/points_transaction";
import { ToMoney } from "../../utils/generic";
import { GetTransactionTypeBackgroundColor, GetTransactionTypeIcon, GetTransactionTypeText } from "./points_transaction_constants";

export interface IPointsTransactionListItemProps {
    pointsTransaction: PointsTransaction;
}

// Using the following styles as a reference: https://dribbble.com/shots/15301605-Banking-Dashboard
// TODO: We need to add links to relevant collections etc: {pointsTransaction.entity_id} {pointsTransaction.entity_type}
export default function PointsTransactionListItem({
    pointsTransaction,
}: IPointsTransactionListItemProps) {
    return (
        <Grid container>
            <Grid
                item
                xs={3}
                sm={2}
            >
                <TransactionIcon
                    pointsTransaction={pointsTransaction}
                />
            </Grid>
            <Grid
                item
                xs={6}
                sm={7}
            >
                <Typography
                    variant="body1"
                >
                    {GetTransactionTypeText(pointsTransaction.transaction_type)}
                </Typography>
                <Typography
                    variant="body2"
                    color="dimgrey"
                >
                    {new Date(String(pointsTransaction.created_at))?.toDateString()}
                </Typography>
            </Grid>
            <Grid
                item
                xs={3}
            >
                <TransactionMoney pointsTransaction={pointsTransaction} />
            </Grid>
        </Grid>
    );
}


function TransactionIcon({ pointsTransaction }: { pointsTransaction: PointsTransaction }) {

    const TransactionTypeIcon = GetTransactionTypeIcon(pointsTransaction.transaction_type);

    console.log(JSON.stringify(TransactionTypeIcon));

    return (
        <Box
            sx={{
                borderRadius: 3,
                width: 50,
                height: 50,
                backgroundColor: GetTransactionTypeBackgroundColor(pointsTransaction.transaction_type),
                textAlign: 'center',
            }}
        >
            <TransactionTypeIcon
                sx={{
                    marginTop: '14px',
                    color: 'rgba(0,0,0,0.6)',
                }}
            />
            {/* <Icon>{GetTransactionTypeIcon(pointsTransaction.transaction_type)}</Icon> */}
        </Box>
    );
}


function TransactionMoney({ pointsTransaction }: { pointsTransaction: PointsTransaction }) {
    var color;
    var sign;

    if (pointsTransaction.debit) {
        color = green[500];
        sign = "+";
    } else if (pointsTransaction.credit) {
        color = "inherit";
        sign = "-";
    } else {
        return null;
    }


    return (
        <Typography
            variant="body1"
            sx={{
                color,
                textAlign: 'right',
            }}
        >
            {sign}{ToMoney(pointsTransaction.debit || pointsTransaction.credit)}
        </Typography>
    )
}