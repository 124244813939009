import { Box, keyframes } from "@mui/material";

const fade = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.2;
  }
`;

export default function LoaderSkeletonCustom() {
    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.15)',
            borderRadius: 2,
            background: "#AAA",
            animation: `${fade} 2s ease-in-out infinite`,
        }}>
            &nbsp;
        </Box>
    );
}
