import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListAttachments, Attachment } from '../../@types/attachment';
import { RootState } from '../../store/root';
import AttachmentsApi from './attachmentsApi';

export interface AttachmentsState {
    byId: {
        [id: string]: Attachment;
    },
    fetchedById: {
        [id: string]: null,
    },
}

const initialState: AttachmentsState = {
    byId: {},
    fetchedById: {},
};

export const getAttachmentsByIdIfNeededAsync = createAsyncThunk(
    'getAttachmentsByIdIfNeededAsync',
    async (attachmentIds: number[], { getState, dispatch }) => {
        const state = getState() as RootState;

        // Ensure that we won't try to double fetch.
        dispatch(attachmentsSlice.actions.fetched(attachmentIds));

        // Ensure they aren't already there.
        let missingAttachmentIds = attachmentIds.filter(attachmentId => !state.attachments.byId[attachmentId] && attachmentId);

        // Ensure we haven't already tried to fetch them.
        missingAttachmentIds = missingAttachmentIds.filter(attachmentId => {
            return !Object.hasOwn(state.attachments.fetchedById, attachmentId)
        });

        if (!missingAttachmentIds.length) return;

        return await AttachmentsApi.List({
            filter: {
                attachment_ids: missingAttachmentIds,
            },
        });
    }
);

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const listAttachmentsAsync = createAsyncThunk(
    'attachmentsGet',
    async (request: IListAttachments) => {
        return await AttachmentsApi.List(request);
    }
);

export const updateAttachmentAsync = createAsyncThunk(
    'attachmentUpdate',
    async (request: Attachment) => {
        return await AttachmentsApi.Update(request);
    }
);

export const createAttachmentAsync = createAsyncThunk(
    'attachmentCreate',
    async (request: Attachment) => {
        return await AttachmentsApi.Create(request);
    }
);

export const getAttachmentDownloadUrlAsync = createAsyncThunk(
    'attachmentGetDownloadUrl',
    async (id: number) => {
        return await AttachmentsApi.GetDownloadUrl(id);
    }
);

export const attachmentsSlice = createSlice({
    name: 'attachments',
    initialState,
    reducers: {
        fetched: (state, action: PayloadAction<Array<number>>) => {
            state.fetchedById = action.payload.reduce((acc, attachmentId) => {
                acc[attachmentId] = null;
                return acc;
            }, state.fetchedById);
        },
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(listAttachmentsAsync.fulfilled, (state: AttachmentsState, action) => {
                state.byId = {
                    ...state.byId,
                    ...action.payload.reduce((acc: {
                        [index: string]: Attachment,
                    }, item: Attachment) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            })
            .addCase(listAttachmentsAsync.rejected, (state) => {
                throw new Error("Need to handle this get attachment rejected")
            })
            .addCase(updateAttachmentAsync.fulfilled, (state: AttachmentsState, action) => {
                if (action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(updateAttachmentAsync.rejected, (state) => {
                throw new Error("error updating attachment")
            })
            .addCase(createAttachmentAsync.fulfilled, (state: AttachmentsState, action) => {
                if (action.payload?.id) {
                    state.byId = {
                        ...state.byId,
                        [action.payload.id]: action.payload,
                    };
                }
            })
            .addCase(createAttachmentAsync.rejected, (state) => {
                throw new Error("error creating attachment")
            })
            .addCase(getAttachmentDownloadUrlAsync.fulfilled, (state: AttachmentsState, action) => {
                // Nothing required for this scenario.
            })
            .addCase(getAttachmentDownloadUrlAsync.rejected, (state) => {
                throw new Error("error retrieving download url")
            })
            .addCase(getAttachmentsByIdIfNeededAsync.fulfilled, (state: AttachmentsState, action) => {
                if(!action?.payload) return;
                
                state.byId = {
                    ...state.byId,
                    ...action?.payload?.reduce((acc: {
                        [index: string]: Attachment,
                    }, item: Attachment) => {
                        acc[item.id] = item;
                        return acc;
                    }, {})
                };
            });
    },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;
export const selectAttachmentById = (state: RootState, attachmentId: number) => {
    return (Object.values(state.attachments.byId) as Array<Attachment>)
        .filter((item: Attachment) => item.id === attachmentId)?.[0];
}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//     dispatch,
//     getState
// ) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//         dispatch(incrementByAmount(amount));
//     }
// };

// Extract the action creators object and the reducer
const { reducer } = attachmentsSlice;

// Extract and export each action creator by name
// export const { getAttachmentsAsync }, = actions;

export default reducer;