import { AppUser, IGetAppUsers } from "../../@types/app_user";
import { GetApiUrl, GetIdToken, HandleApiResponse } from "../../utils/api_utils";


export async function GetAppUsersApi(request: IGetAppUsers): Promise<Array<AppUser>> {
  try {
    const resp = await fetch(`${GetApiUrl()}/app_users`, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${await GetIdToken()}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });

    return await HandleApiResponse(resp);
  } catch (e) {
    console.error(e);
    throw e;
  }
}