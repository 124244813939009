import { Box, Stepper, Step, StepLabel, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Collection, CollectionStatus } from "../../../@types/collection";
import { EntityType, ValidationError } from "../../../@types/generic";
import { GetCollectionPageLink } from "../../../routes/RouteLinkHelpers";
import { useAppDispatch } from "../../../store/hooks";
import SectionContainedBody from "../../../views/layouts/sections/SectionContainedBody";
import { selectCurrentUser } from "../../global/globalsSlice";
import { createCollectionAsync } from "../collectionsSlice";
import CollectionCreateWizardContent, { WIZARD_STEP_DESCRIBE_TAB_NUMBER, WIZARD_STEP_PRICE_TAB_NUMBER } from "./CollectionCreateWizardContentWrapper";
import { green } from "@mui/material/colors";
import * as Yup from 'yup';

const FREE_STEPS = ['Describe', 'Commission'];
const PAID_STEPS = ['Describe', 'Commission', 'Type', 'Price'];

interface ICollectionCreateWizardProps {
    propertyId: number,
}

const collectionCreateSchema = Yup.object().shape({
    free: Yup.boolean(),
    name: Yup.string()
        .min(3, 'minimum of 3 characters')
        .max(50, 'maximum of 50 characters')
        .required('required'),
    description: Yup.string()
        .min(10, 'minimum of 10 characters')
        .max(500, 'maximum of 500 characters')
        .required('required'),
    unlock_fee: Yup.number()
        .when(
            "free", {
                is: false,
                then: Yup
                    .number()
                    .required()
                    .min(1, 'amount minimum of $1.00')
                    .max(500, 'amount maximum of $500.00')
            },
        )
})

export default function CollectionCreateWizard({
    propertyId,
}: ICollectionCreateWizardProps) {
    const [steps, setSteps] = useState(PAID_STEPS);
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<Collection>({
        entity_type: EntityType.Property,
        entity_id: propertyId,
        status: CollectionStatus.INACTIVE,
        unlock_fee: 0,
        free: false,
    } as Collection);
    const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [submitting, setSubmitting] = useState(false);
    const currentUser = useSelector(selectCurrentUser);
    let navigate = useNavigate();

    const submit = async () => {
        setSubmitting(true);

        try {
            // Ensure that the form values are valid before trying to submit.
            if (await validateForm(formData)) {
                var resp = (await dispatch(createCollectionAsync({ ...formData, unlock_fee: formData.unlock_fee * 100 }))) as any;
                if (resp?.payload) {
                    navigate(GetCollectionPageLink(resp?.payload));
                }
            }
        } finally {
            setSubmitting(false);
        }
    }

    const validateForm = async (data: Collection) => {
        try {
            await collectionCreateSchema.validate(data, { abortEarly: false });
        } catch (e: any) {
            if (e.name === "ValidationError") {
                // If we have any validation errors we abort early.
                if (e.inner?.length) {
                    setValidationErrors(e.inner);
                    return false;
                }
            }
        }

        // We've made it this far, should be fine to reset any validation
        // errors.s
        setValidationErrors([]);

        return true;
    }


    const formValueChanged = async (data: Collection) => {
        setFormData(data);
        await validateForm(data);

        // There are a different number of steps depending on whether
        // the user picks a free or paid collection.
        setSteps(data.free ? FREE_STEPS : PAID_STEPS);
    }


    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            return prevActiveStep + 1;
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) =>  {
            return   prevActiveStep - 1
        });
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{
                maxWidth: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginY: 3,
            }}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <SectionContainedBody>
                <Box sx={{
                    padding: 1,
                    position: 'relative',
                }}>
                    <CollectionCreateWizardContent
                        activeStep={activeStep}
                        setFormData={formValueChanged}
                        collection={formData}
                        submitting={submitting}
                        submit={submit}
                        currentUser={currentUser}
                        validationErrors={validationErrors}
                    />
                    <BackAndNextButtonsFragment
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        submitting={submitting}
                        submit={submit}
                        submitDisabled={!!validationErrors?.length || submitting}
                        steps={steps}
                    />
                </Box>
            </SectionContainedBody>
        </Box>
    );
}

interface IBackAndNextButtonProps {
    activeStep: number,
    handleBack: () => void,
    handleNext: () => void,
    submit: () => void,
    submitting: boolean,
    submitDisabled: boolean,
    steps: string[],
}

function BackAndNextButtonsFragment({
    activeStep,
    handleBack,
    handleNext,
    submitting,
    submit,
    submitDisabled,
    steps,
}: IBackAndNextButtonProps) {
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginTop: 3 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant="contained"
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {
                    (activeStep === steps.length - 1) ? (
                        <Button onClick={submit} variant="contained" disabled={submitDisabled}>
                            Create
                        </Button>
                    ) : (
                        <Button onClick={handleNext} variant="contained">
                            Next
                        </Button>
                    )
                }
                {submitting && (
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                            position: 'absolute',
                            borderRadius: 1,
                        }}>
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    </Box>
                )}
            </Box>
        </React.Fragment>
    )
}
