import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography';
import {
    Link as RouterLink,
} from 'react-router-dom';
import { Avatar, Button, Grid } from "@mui/material";
import { green } from "@mui/material/colors";
import ContainedBodySection from '../../layouts/sections/SectionContainedBody';

const listItemNumberStyles = {
    bgcolor: green[500],
    height: 25,
    width: 25,
    fontSize: 14,
}

export function ContentRowBuyDocuments() {
    return (
        <ContainedBodySection>
            <Grid container spacing={8} paddingY={10}>
                <Grid item xs={12} md={6}>
                    <Box
                        textAlign="center"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                    >
                        <Typography
                            variant="h2"
                            fontWeight="fontWeightLight"
                            fontSize="h4.fontSize"
                        >
                            How documents are accessed.
                        </Typography>
                        <Typography
                            variant="body2"
                        >
                            Collections can be purchased in order to unlock them and grant download access.
                        </Typography>

                        {/* https://app.hubspot.com/content/22490475/edit/81602642502/content */}

                        <Grid container textAlign="left" spacing={1} marginTop={5} paddingX={2}>

                            <ListItemIconGridIcon characterToShow='1' />
                            <ListItemText text="Most collections are initially locked. They can be unlocked using purchased credit." />

                            <ListItemIconGridIcon characterToShow='2' />
                            <ListItemText text="When unlocked, all files inside the collection can be downloaded by the purchaser." />

                            <ListItemIconGridIcon characterToShow='3' />
                            <ListItemText text="The uploader gets paid and the original unlocker may even earn a commission on future purchases!" />

                            <Grid item xs={12} textAlign="right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    sx={{
                                        borderRadius: '40px',
                                        paddingX: 6,
                                        paddingY: 2,
                                        marginTop: 4,
                                    }}
                                    fullWidth={true}
                                    component={RouterLink}
                                    to="/properties"
                                >
                                    Get Started
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{
                        position: 'relative',
                        height: '100%',
                        backgroundImage: 'url("/img/content/house-plans.jpg")',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                    }}>
                    </div>
                </Grid>
            </Grid>

        </ContainedBodySection>
    )
}

export function ContentRowSellDocuments() {
    return (
        <ContainedBodySection style={{ backgroundColor: '#F2F2F2' }}>
            <Grid container spacing={8} paddingY={10}>
                <Grid item xs={12} md={6}>
                    <Box
                        textAlign="center"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                    >
                        <Typography
                            variant="h2"
                            fontWeight="fontWeightLight"
                            fontSize="h4.fontSize"
                        >
                            Where the information comes from.
                        </Typography>
                        <Typography
                            variant="body2"
                        >
                            Members get paid a commission as an incentive to upload useful information and files about properties.
                        </Typography>

                        {/* https://app.hubspot.com/content/22490475/edit/81602642502/content */}

                        <Grid container textAlign="left" marginTop={5} paddingLeft={2} paddingRight={2}>

                            <ListItemIconGridIcon characterToShow='1' />
                            <ListItemText text="They create or purchase a document that someone else might find useful." />

                            <ListItemIconGridIcon characterToShow='2' />
                            <ListItemText text="In order to share it with others, they upload it as a collection on the relevant property." />

                            <ListItemIconGridIcon characterToShow='3' />
                            <ListItemText text="When another user first purchases the collection, the uploader gets paid." />

                            <Grid item xs={12} textAlign="right">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    sx={{
                                        borderRadius: '40px',
                                        paddingX: 6,
                                        paddingY: 2,
                                        marginTop: 4,
                                    }}
                                    component={RouterLink}
                                    to="/properties"
                                    fullWidth={true}
                                >
                                    Get Started
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{
                        position: 'relative',
                        height: '100%',
                        backgroundImage: 'url("https://p0.piqsels.com/preview/885/930/516/house-real-estate-agent-sold.jpg")',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                    }}>
                    </div>
                </Grid>
            </Grid>

        </ContainedBodySection>
    )
}

function ListItemIconGridIcon({ characterToShow }: { characterToShow: string }) {
    return (
        <Grid item xs={3} sm={2}>
            <ListItemIcon characterToShow={characterToShow} />
        </Grid>
    )
}

function ListItemIcon({ characterToShow }: { characterToShow: string }) {
    return (
        <Avatar
            sizes="small"
            alt={`Step: ${characterToShow}`}
            sx={listItemNumberStyles}
        >
            {characterToShow}
        </Avatar>
    )
}

function ListItemText({ text }: { text: string }) {
    return (
        <Grid item xs={9} sm={10} sx={{ paddingBottom: 2 }}>
            <Typography
                variant="body1"
            >
                {text}
            </Typography>
        </Grid>
    )
}