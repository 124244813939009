import { Attachment, AttachmentType } from "../../@types/attachment";
import { EntityType } from "../../@types/generic";
import FormDialog from "../form/FormDialog";
import AttachmentCreateForm from "./AttachmentCreateForm";


export interface IAttachmentCreateCoverImageForEntityFormDialogProps {
    open: boolean;
    entityId: number,
    entityType: EntityType,
    onSave: (success: boolean) => void;
    onCancel: () => void;
    postAttachmentCreatedFunc?: (attachment: Attachment) => Promise<void>;
}

export default function AttachmentCreateCoverImageForEntityFormDialog(props: IAttachmentCreateCoverImageForEntityFormDialogProps) {
    return (
        <FormDialog
            title="Add a new cover image"
            onClose={props.onCancel}
            open={props.open}
        >
            <AttachmentCreateForm
                entityType={props.entityType}
                entityId={props.entityId}
                attachmentType={AttachmentType.ATTACHMENT_TYPE_HEADER_IMAGE}
                onSave={props.onSave}
                onCancel={props.onCancel}
                postAttachmentCreatedFunc={props.postAttachmentCreatedFunc}
                open={props.open}
                />
        </FormDialog>
    )
}
