import { MapOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CollectionPostcode } from "../../../@types/collection_postcode";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/root";
import NoSearchResultsFound from "../../../views/layouts/placeholders/NoSearchResultsFound";
import AnimationWrapper from "../../animations/AnimationWrapper";
import { selectCollectionPostcodesByCollectionId, deleteCollectionPostcodeAsync, createCollectionPostcodeAsync } from "../../collection_postcodes/collectionPostcodesSlice";
import { IUseFetchCollectionPostcodesPageHookProps, useFetchCollectionPostcodesPageHook } from "../../collection_postcodes/hooks";
import LoaderGeneric from "../../loaders/LoaderGeneric";
import LoaderSkeletonGrid from "../../loaders/LoaderSkeletonGrid";

interface ICollectionManagementPostcodesTabProps {
    collectionId: number;
}

export default function CollectionManagementPostcodesTab({
    collectionId,
}: ICollectionManagementPostcodesTabProps) {
    const [currentSearchValues] = useState<IUseFetchCollectionPostcodesPageHookProps>({
        page_number: 1,
        page_size: 50,
        min_page_number_to_fetch: 1,
        filter: {
            collection_id: collectionId,
        },
    });

    const { fetching: fetchingCollectionPostcodes } = useFetchCollectionPostcodesPageHook({
        ...currentSearchValues
    });

    const collectionPostcodes = useSelector((store: RootState) => selectCollectionPostcodesByCollectionId(store, collectionId))

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        mb: 3,
                    }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 'lighter',
                            fontSize: 24,
                        }}
                    >
                        Collection Postcodes
                    </Typography>
                    <Typography variant="body2">Add postcodes if the collection should appear on all properties in the area.</Typography>
                </Box>
            </Grid>

            {
                fetchingCollectionPostcodes && (
                    <LoaderSkeletonGrid
                        gridSizes={{
                            xs: 2,
                            sm: 2,
                            md: 1,
                            lg: 1,
                            xl: 1,
                        }}
                        numberOfElements={6}
                        height={50}
                    />
                )
            }

            {!fetchingCollectionPostcodes && collectionPostcodes?.length && (
                <Grid item xs={12}>
                    <Box sx={{
                        position: 'relative',
                    }}>
                        {
                            collectionPostcodes.map(x => {
                                return (
                                    <AnimationWrapper>
                                        <div style={{
                                            display: 'inline-block',
                                        }}>
                                            <RenderPostcode collectionPostcode={x} />
                                        </div>
                                    </AnimationWrapper>
                                )
                            })
                        }
                    </Box>
                </Grid>
            )}

            {
                !fetchingCollectionPostcodes && !collectionPostcodes?.length && (
                    <Grid item xs={12}>
                        <NoSearchResultsFound
                            icon={MapOutlined}
                            title="Collection postcodes"
                            description="You haven't added this collection to any postcodes."
                        />
                    </Grid>
                )
            }

            {
                !fetchingCollectionPostcodes && (
                    <Grid item xs={12}>
                        <AddPostcodes collectionId={collectionId} />
                    </Grid>
                )
            }
        </Grid>
    )
}


function RenderPostcode({ collectionPostcode }: { collectionPostcode: CollectionPostcode }) {
    const [deleting, setDeleting] = useState(false);
    const dipatch = useAppDispatch();

    const onDeletePostcode = async () => {
        setDeleting(true);

        try {
            await dipatch(deleteCollectionPostcodeAsync({
                collection_postcode_id: collectionPostcode.id,
            }));
        } finally {
            setDeleting(false);
        }
    }

    return (
        <>
            <Chip
                variant="filled"
                color="default"
                label={collectionPostcode.postcode}
                onDelete={onDeletePostcode}
                sx={{
                    marginLeft: 1,
                    marginBottom: 1,
                }}
            />
            <LoaderGeneric loading={deleting} />
        </>

    )
}

function AddPostcodes({ collectionId }: { collectionId: number }) {
    const dispatch = useAppDispatch();
    const [saving, setSaving] = useState(false);
    const [currentPostcode, setCurrentPostcode] = useState("");

    const onSubmit = async () => {
        setSaving(true);

        try {
            await dispatch(createCollectionPostcodeAsync({
                collection_id: collectionId,
                postcodes: [currentPostcode],
            }));
        } finally {
            setSaving(false);
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
        }}>
            <TextField
                label="New Postcode"
                size="small"
                onChange={(e) => setCurrentPostcode(e.target.value)}
            />&nbsp;
            <Button
                onClick={onSubmit}
                variant="contained"
                size="small"
            >Save</Button>
            <LoaderGeneric loading={saving} />
        </Box>
    )
}