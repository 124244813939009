import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { blue, blueGrey, green, red } from '@mui/material/colors';
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Attachment, AttachmentStatus, AttachmentType } from '../../@types/attachment'
import { useAppDispatch } from '../../store/hooks';
import { ShowError, Uuidv4 } from '../../utils/generic';
import { createAttachmentAsync } from '../attachments/attachmentsSlice';
import PendingIcon from '@mui/icons-material/HourglassEmptyTwoTone';
import ErrorIcon from '@mui/icons-material/WarningAmberTwoTone';
import CompleteIcon from '@mui/icons-material/CheckCircle';
import FileIcon from '@mui/icons-material/FilePresentOutlined';
import { EntityType } from '../../@types/generic';
import { CloudUploadRounded } from '@mui/icons-material';

enum FileStatus {
    Default = "default",
    CreatingAttachment = "creating",
    UploadingToS3 = "uploading",
    Complete = "complete",
    Error = "error",
}

interface ITrackedFile {
    id: string;
    status: FileStatus;
    file: File;
}

interface ITrackedFiles {
    [key: string]: ITrackedFile;
}

export interface IFormFileDropperProps {
    postAttachmentCreatedFunc?: (attachment: Attachment) => Promise<void>;
    entityType: EntityType;
    entityId: number;
    attachmentType?: AttachmentType,
}

export default function FormFileDropper({
    postAttachmentCreatedFunc,
    entityType,
    entityId,
    attachmentType,
}: IFormFileDropperProps) {
    const dispatch = useAppDispatch();
    const [trackedFiles, setTrackedFiles] = useState<ITrackedFiles>();
    const [hasUploaded, setHasUploaded] = useState(false);
    const theme = useTheme();

    const onDrop = async (acceptedFiles: any) => {
        if (acceptedFiles?.length) {
            setTrackedFiles(acceptedFiles.reduce((
                acc: ITrackedFiles,
                file: File,
            ) => {
                let id = Uuidv4();
                acc[id] = {
                    id,
                    status: FileStatus.Default,
                    file,
                };

                return acc;
            }, {}));
        }
    }

    const setTrackedFileStatus = async (filesToUpload: ITrackedFiles, trackedFile: ITrackedFile, status: FileStatus) => {
        filesToUpload[trackedFile.id].status = status;
        setTrackedFiles({ ...filesToUpload });
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    useEffect(() => {
        // Ensure that we don't keep trying to re-upload.
        if (hasUploaded || trackedFiles === undefined || !Object.values(trackedFiles).length) {
            return;
        }

        setHasUploaded(true);

        let filesToUpload = { ...trackedFiles };

        // Start the uploads.
        (async () => {
            // Loop through each file.
            for (var currentUpload of Object.values(trackedFiles)) {
                setTrackedFileStatus(filesToUpload, currentUpload, FileStatus.CreatingAttachment);

                // Create an attachment.
                let resp = (await dispatch(
                    createAttachmentAsync({
                        name: currentUpload.file.name,
                        content_type: currentUpload.file.type,
                        size: currentUpload.file.size,
                        status: AttachmentStatus.PENDING_UPLOAD,
                        entity_type: entityType,
                        entity_id: entityId,
                        type: attachmentType || AttachmentType.ATTACHMENT_TYPE_UNSPECIFIED,
                    } as Attachment)));

                let attachment = resp?.payload as Attachment;
                if (!attachment) {
                    setTrackedFileStatus(filesToUpload, currentUpload, FileStatus.Error);
                    continue;
                }

                setTrackedFileStatus(filesToUpload, currentUpload, FileStatus.UploadingToS3);

                try {
                    // Upload the file to s3.
                    await fetch(attachment.upload_url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': currentUpload.file.type,
                        },
                        body: currentUpload.file,
                    });

                    // Call a post attachment created function if provided.
                    if (postAttachmentCreatedFunc) {
                        await postAttachmentCreatedFunc(attachment);
                    }

                    setTrackedFileStatus(filesToUpload, currentUpload, FileStatus.Complete);
                } catch (e) {
                    ShowError(`Error uploading file: ${e}`);
                    setTrackedFileStatus(filesToUpload, currentUpload, FileStatus.Error);
                }
            }
        })();
    }, [hasUploaded, trackedFiles, dispatch, entityId, entityType, postAttachmentCreatedFunc, attachmentType]);

    return (
        <div {...getRootProps()}>
            {
                trackedFiles ? (
                    <RenderTrackedFiles trackedFiles={Object.values(trackedFiles)} />
                ) : (
                    <Box sx={{
                        borderRadius: '5px',
                        padding: '10px',
                        textAlign: 'center',
                        margin: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: isDragActive ? theme.palette.primary.light : 'rgba(71, 145, 219, 0.05)',
                        transition: 'background-color 0.2s ease',
                        cursor: 'pointer',
                        border: '1px solid rgba(71, 145, 219, 0.1)',
                        '&:hover': {
                            backgroundColor: 'inherit',
                        }
                    }}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <div>
                                    <CloudUploadRounded sx={{ color: 'white' }} fontSize="large" />
                                    <Typography variant="h6" color="white">Drop here</Typography>
                                    <Typography variant="caption" color="white">
                                        to start uploading
                                    </Typography>
                                </div> :
                                <div>
                                    <CloudUploadRounded sx={{ color: theme.palette.primary.light }} fontSize="large" />
                                    <Typography variant="h6" color="primary">
                                        Drag and drop files here
                                    </Typography>
                                    <Typography variant="caption" color="primary.light" sx={{
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        }
                                    }}>
                                        or click to browse
                                    </Typography>
                                </div>
                        }
                    </Box>
                )
            }
        </div >
    )
}


function RenderTrackedFiles({ trackedFiles }: { trackedFiles: Array<ITrackedFile> }) {
    if (!trackedFiles?.length) {
        return null;
    }

    return (
        <div>
            {
                trackedFiles.map((file: any, i: any) => (
                    <TrackedFile
                        trackedFile={file}
                        key={i}
                    />
                ))
            }
        </div>
    )
}


function TrackedFile({ trackedFile }: { trackedFile: ITrackedFile }) {
    let backgroundColor = "";
    let statusIcon;

    switch (trackedFile.status) {
        case FileStatus.Complete:
            backgroundColor = green[500];
            statusIcon = <CompleteIcon />;
            break;
        case FileStatus.Error:
            backgroundColor = red[500];
            statusIcon = <ErrorIcon />;
            break;
        case FileStatus.CreatingAttachment:
            backgroundColor = blue[500];
            statusIcon = <CircularProgress size={14} />;
            break;
        case FileStatus.UploadingToS3:
            backgroundColor = blue[500];
            statusIcon = <CircularProgress size={14} />;
            break;
        case FileStatus.Default:
        default:
            backgroundColor = blueGrey[500];
            statusIcon = <PendingIcon />;
    }



    return (
        <div style={{ border: '1px solid rgba(0,0,0,0.2)', padding: 10, marginBottom: -1 }}>
            <Grid container spacing={2}>
                <Grid item xs={3} style={{ color: backgroundColor }}>
                    <FileIcon />
                </Grid>
                <Grid item xs={6}>
                    <Typography textOverflow="ellipsis" noWrap={true}>{trackedFile.file.name}</Typography>
                </Grid>
                <Grid item xs={3} style={{ color: backgroundColor }}>
                    {statusIcon}
                </Grid>
            </Grid>
        </div>
    )
}
