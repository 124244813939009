import { Property } from "../../@types/property";
import { GetAccountPageLink, GetPropertiesPageLink, GetPropertyPageLink } from "../../routes/RouteLinkHelpers";
import { Truncate } from "../../utils/generic";
import { GetPropertyAddress } from "../properties/propertyUtils";
import { Breadcrumb } from "./BreadcrumbsWrapper";

export function GetAccountBreadcrumb() {
    return {
        label: 'Account',
        link: GetAccountPageLink(),
    }
}

export function GetPropertiesBreadcrumb() {
    return {
        label: 'Properties',
        link: GetPropertiesPageLink(),
    }
}

export function GetBreadcrumbFromProperty(property?: Property) {
    if (!property) return null;

    return {
        label: Truncate(GetPropertyAddress(property), 10),
        link: GetPropertyPageLink(property),
    };
}

export function GetBreadcrumbsFromProperty(property?: Property) : (Breadcrumb | null)[] {
    if (!property) return [];

    return [
        GetPropertiesBreadcrumb(),
        GetBreadcrumbFromProperty(property),
    ];
}