import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/root";
import { useFetchMissingCollectionsByIdIfNeededHook } from "../collections/hooks";
import { IUseFetchCollectionPostcodesPageHookProps, useFetchCollectionPostcodesPageHook } from "../collection_postcodes/hooks";
import { selectCurrentUser } from "../global/globalsSlice";
import { selectCollectionsByPostcode } from "../collections/collectionsSlice";
import { AppUser } from "../../@types/app_user";
import { Collection } from "../../@types/collection";
import LoaderSkeletonGrid from "../loaders/LoaderSkeletonGrid";
import { Grid, Box } from "@mui/material";
import NoSearchResultsFound from "../../views/layouts/placeholders/NoSearchResultsFound";
import AnimationWrapper from "../animations/AnimationWrapper";
import { CollectionListItem } from "../collections/CollectionListItem";
import { CollectionPostcode } from "../../@types/collection_postcode";

export interface IPropertyPostcodeCollectionsProps {
    postcode: string;
}

export default function PropertyPostcodeCollections({
    postcode,
}: IPropertyPostcodeCollectionsProps) {
    const currentUser = useSelector((store: RootState) => selectCurrentUser(store));
    const [currentSearchValues] = useState<IUseFetchCollectionPostcodesPageHookProps>({
        page_number: 1,
        page_size: 50,
        min_page_number_to_fetch: 1,
        filter: {
            postcodes: [postcode],
        },
    });

    const { fetching: fetchingCollectionPostcodes, allResultsSet: collectionPostcodes } = useFetchCollectionPostcodesPageHook({
        ...currentSearchValues
    });

    const [postcodeCollectionIds, setPostcodeCollectionIds] = useState<number[]>([]);

    const { loading: loadingCollections } = useFetchMissingCollectionsByIdIfNeededHook(postcodeCollectionIds);
    const collections = useSelector((store: RootState) => selectCollectionsByPostcode(store, postcode));

    // We need to handle these with state to prevent a call on each render.
    const postcodeCollectionIdsJSON = JSON.stringify(collectionPostcodes.map((x: CollectionPostcode) => x.collection_id));
    useEffect(() => {
        setPostcodeCollectionIds(JSON.parse(postcodeCollectionIdsJSON));
    }, [postcodeCollectionIdsJSON]);

    return (
        <PropertyPostcodeCollectionsDisplay
            postcode={postcode}
            collections={collections}
            fetchingCollections={fetchingCollectionPostcodes || loadingCollections}
            currentUser={currentUser}
            loading={loadingCollections || fetchingCollectionPostcodes}
        />
    )
}


function PropertyPostcodeCollectionsDisplay({
    collections,
    fetchingCollections,
    postcode,
}: {
    collections: Collection[],
    fetchingCollections: boolean,
    postcode: string,
    currentUser?: AppUser,
    loading: boolean,
}) {
    return (
        <Grid container spacing={3}>
            <>
                {fetchingCollections ? (
                    <LoaderSkeletonGrid
                        numberOfElements={3}
                        gridSizes={{
                            xs: 12,
                            sm: 6,
                            md: 4,
                        }}
                        height={250}
                    />
                ) : (
                    collections.length > 0 ?
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {collections.map((collection) => (
                                    <Grid item xs={12} sm={6} md={4} key={'col' + collection.id} sx={{
                                        minHeight: '100%',
                                    }}>
                                        <AnimationWrapper>
                                            <div>
                                                <CollectionListItem collection={collection} />
                                            </div>
                                        </AnimationWrapper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid> :
                        <Box
                            sx={{
                                textAlign: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <NoSearchResultsFound
                                title="No collections found"
                                description={`No collections have been added for the postcode ${postcode} yet.`}
                            />
                        </Box>
                )}
            </>
        </Grid>
    )
}