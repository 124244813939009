import { Box, Typography } from "@mui/material";
import ParticlesBackground from "../ParticlesBackground";

export interface IParticlesHeaderProps {
    title: String,
    description: String,
}

export default function ParticlesHeader({ title, description }: IParticlesHeaderProps) {
    return (
        <Box sx={{
            color: 'white',
            display: 'grid',
            position: 'relative',
            height: {
                xs: 250,
            },
            width: '100vw',
            justifyContent: 'center',
        }}>

            <ParticlesBackground id="pricing-particles" />

            <Box
                sx={{
                    zIndex: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: 3,
                    maxWidth: 'sm',
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontSize="35px"
                    lineHeight="35px"
                    fontWeight="lighter"
                    gutterBottom={false}
                >
                    {title}
                </Typography>
                <Typography
                    variant="caption"
                    fontWeight="lighter"
                    marginTop={1}
                >
                    {description}
                </Typography>
            </Box>
        </Box>
    )
}

