import { useEffect, useState } from "react";
import { IListPurchasedCollections, PurchasedCollection } from "../../@types/purchased_collection";
import { useAppDispatch } from "../../store/hooks";
import { listPurchasedCollectionsAsync } from "./purchasedCollectionsSlice";

export interface IUseFetchPurchasedCollectionsPageHookProps extends IListPurchasedCollections {
    min_page_number_to_fetch: number;
}

export const useFetchPurchasedCollectionsPageHook = ({ page_number, min_page_number_to_fetch, page_size, filter, }: IUseFetchPurchasedCollectionsPageHookProps) => {
    const dispatch = useAppDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<PurchasedCollection[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<PurchasedCollection[]>([]);

    useEffect(() => {
        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (Number(page_number) < min_page_number_to_fetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {
                var resp = await dispatch(listPurchasedCollectionsAsync({
                    page_size,
                    page_number,
                    filter,
                }));

                var purchasedCollections = resp.payload as PurchasedCollection[];
                if (purchasedCollections?.length) {
                    setMorePages(purchasedCollections.length >= Number(page_size))
                    setLastResultSet(purchasedCollections);
                }
                else {
                    setMorePages(false);
                    setLastResultSet([]);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [min_page_number_to_fetch, dispatch, page_number, page_size, filter]);

    // Merge any new result sets with existing
    useEffect(() => {
        if (lastResultSet.some(x => !allResultsSet.some(y => y.id === x.id))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}