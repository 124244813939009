import { IBillingPortalRequest, IListMemberships, Membership } from "../../@types/membership";
import { GetApiUrl, GetIdToken, HandleApiResponse } from "../../utils/api_utils";

export async function BillingPortal(request: IBillingPortalRequest): Promise<Membership[]> {
    try {
        const resp = await fetch(`${GetApiUrl()}/memberships?return_url=${encodeURIComponent(request.return_url)}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function List(request: IListMemberships): Promise<Membership[]> {
    try {
        const resp = await fetch(`${GetApiUrl()}/memberships`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Update(request: Membership): Promise<Membership> {
    try {
        const resp = await fetch(`${GetApiUrl()}/memberships`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ membership: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export async function Create(request: Membership): Promise<Membership> {
    try {
        const resp = await fetch(`${GetApiUrl()}/memberships`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${await GetIdToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ membership: request }),
        });

        return await HandleApiResponse(resp);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const exports = {
    List,
    Update,
    Create,
    BillingPortal,
}

export default exports;